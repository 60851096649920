@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/layout';
@use '../../../scss/tools/portfolio-ranger';
@use '../../../scss/tools/scrollbar';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrapper {
	max-width: units.px2rem(815px);
	margin: 0 auto;
}

.title {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 14);
	text-align: center;
}

.subtitle {
	margin-bottom: spacing.generate(12);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	text-align: center;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.contentWrap {
	padding: spacing.generate(2);
	border-radius: units.px2rem(20px);
	box-shadow: 0 0 units.px2rem(20px) units.px2rem(10px) #dadada;
}

.contentTitle {
	padding: spacing.generate(4);
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 9);
	text-align: center;
	border-bottom: borders.$width solid colors.$gray-light;
}

.buttonWrap {
	display: flex;
	gap: spacing.generate(2);
	justify-content: center;
	padding-block: spacing.generate(7) spacing.generate(8);

	@include breakpoint.up(md) {
		gap: spacing.generate(4);
	}
}

.sliderWrap {
	position: relative;
	width: 100%;
}

.sliderArrows {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.sliderArrow {
	z-index: 100;
	width: units.px2rem(45px);
	height: units.px2rem(45px);
	padding: spacing.generate(1);
	margin: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	@include breakpoint.up(sm) {
		width: units.px2rem(90px);
		height: units.px2rem(90px);
	}
}

.sliderArrow > svg {
	width: units.px2rem(11px);
	height: units.px2rem(22px);

	@include breakpoint.up(sm) {
		width: units.px2rem(22px);
		height: units.px2rem(45px);
	}
}

.sliderArrow:first-child {
	transform: rotate(180deg);
}

.sliderItem {
	position: relative;
	width: auto;
	padding: spacing.generate(2) 0;
	margin: 0 auto;
	text-align: center;

	@include breakpoint.up(sm) {
		width: units.px2rem(395px);
	}
}

.sliderItemImage {
	display: block;
	width: units.px2rem(170px);
	margin: spacing.generate(4) auto;
}

.sliderItemTitle {
	margin-block: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$orange;
}

.sliderItemDescription {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);
	white-space: pre-wrap;
}

.sliderItemLink {
	display: inline-block;
	margin-top: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: 1.5;
	font-family: inherit;
	text-decoration: underline;
	text-decoration-color: colors.$red;
	color: colors.$red;
	border: 0;
	background: none;

	&:hover,
	&:focus {
		text-decoration: underline;
		color: colors.$blue;
	}
}

.ranger {
	display: none;

	@include breakpoint.up(md) {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: units.px2rem(600px);
	}

	@include breakpoint.up(xl) {
		min-width: units.px2rem(470px);
	}

	@include breakpoint.up(xxl) {
		min-width: units.px2rem(600px);
	}
}

.rangerTitle {
	padding: spacing.generate(3);

	&:first-child {
		text-align: right;
	}

	&:last-child {
		text-align: left;
	}
}

.rangerWrap {
	position: relative;
	width: 100%;
	max-width: units.px2rem(400px);
}

.rangerInput {
	position: relative;
	z-index: 1;
	appearance: none;
	width: 100%;
	height: units.px2rem(10px);
	border-radius: units.px2rem(10px);
	background: transparent;
	outline: none;
}

.rangerInput::-webkit-slider-thumb {
	appearance: none;
	width: units.px2rem(28px);
	height: units.px2rem(28px);
	border: 0;
	border-radius: units.px2rem(28px);
	background-color: colors.$red;
	box-shadow: 0 units.px2rem(3px) units.px2rem(7px) 0 rgb(233 69 73 / 47%);
	cursor: move;
}

.rangerInput::-moz-range-thumb {
	width: units.px2rem(28px);
	height: units.px2rem(28px);
	border: 0;
	border-radius: units.px2rem(28px);
	background-color: colors.$red;
	box-shadow: 0 units.px2rem(3px) units.px2rem(7px) 0 rgb(233 69 73 / 47%);
	cursor: move;
}

.rangerSeparators {
	position: absolute;
	inset: 0 spacing.generate(2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	pointer-events: none;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: units.px2rem(-10px);
		left: units.px2rem(-10px);
		height: units.px2rem(10px);
		border-radius: units.px2rem(10px);
		background: colors.$gray-lighter;
		transform: translateY(-50%);
	}
}

.rangerRecommend {
	position: absolute;
	inset: 0 spacing.generate(-2);
	overflow: hidden;
	border-radius: units.px2rem(10px);
}

.rangerRecommendBar {
	position: absolute;
	top: 50%;
	height: units.px2rem(10px);
	border-radius: units.px2rem(10px);
	background:
		linear-gradient(
			to right,
			colors.$gray-lighter 0%,
			colors.$green-light 11%,
			colors.$green 51%,
			colors.$green-light 88%,
			colors.$gray-lighter 100%
		);
	transform: translateY(-50%);

	@include portfolio-ranger.ranger-bar($profile-count: 7, $end-size: units.px2rem(12.5px));
}

.rangerRecommendInfo {
	margin-top: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 2);
	text-align: center;
}

.rangerRecommendInfo > strong {
	color: colors.$green;
}

.rangerSeparators > span {
	position: relative;
	display: block;
	width: units.px2rem(5px);
	height: units.px2rem(10px);
	background: colors.$white;

	&:first-of-type,
	&:last-of-type {
		visibility: hidden;
		opacity: 0;
	}
}

.dropdown {
	position: relative;
	display: block;

	@include breakpoint.up(md) {
		display: none;
	}
}

.dropdownTrigger {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: spacing.generate(3) spacing.generate(2);
	font-weight: bold;
	font-size: 120%;
	text-align: left;
	border: units.px2rem(1px) solid colors.$gray-lighter;
	border-radius: units.px2rem(10px);
	background: colors.$gray-extra-lighter;
}

.dropdown > svg {
	position: absolute;
	top: 50%;
	right: units.px2rem(10px);
	z-index: 2;
	transform: translateY(-50%) rotate(90deg);
}

.dropdownList {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	padding: spacing.generate(1) 0;
	background: colors.$white;
	box-shadow: 0 units.px2rem(3px) units.px2rem(6px) colors.$gray-extra-light;
	visibility: hidden;
	opacity: 0;
}

.isDropdownOpen > .dropdownList {
	visibility: visible;
	opacity: 1;
}

.dropdownListItem {
	display: block;
	width: 100%;
	padding: units.px2rem(8px) units.px2rem(8px) units.px2rem(8px) spacing.generate(2);
	font-size: 120%;
	text-align: left;
	border: 0;
	border-right: units.px2rem(2px) solid colors.$gray-lighter;
	background-color: colors.$white;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: colors.$gray-lightest;
	}
}

.dropdownListItem.isItemActive {
	font-weight: bold;
	background: colors.$gray-extra-lighter;
}

.isItemRecommended {
	border-color: colors.$green;
}

.infoContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: units.px2rem(1100px);
	padding-inline: spacing.generate(3);
	margin: spacing.generate(12) auto 0 auto;

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(8);
	}

	@include breakpoint.up(xl) {
		padding-inline: 0;
	}
}

.infoContainerChart {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	min-height: units.px2rem(370px);

	@include breakpoint.up(lg) {
		width: calc(100% / 12 * 5 - #{units.px2rem(15px)});
	}

	@include breakpoint.up(xl) {
		width: calc(100% / 12 * 4 - #{units.px2rem(15px)});
	}
}

.infoContainerDetail {
	width: 100%;
	min-height: units.px2rem(370px);

	@include breakpoint.up(lg) {
		width: calc(100% / 12 * 7 - #{units.px2rem(15px)});
	}

	@include breakpoint.up(xl) {
		width: calc(100% / 12 * 8 - #{units.px2rem(15px)});
	}
}

.infoContainerDetail,
.infoContainerDetail > p {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);
}

.infoContainerDetail > p:not(:last-of-type) {
	margin-bottom: spacing.generate(6);
}

.infoDisclaimer {
	padding: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	border-radius: units.px2rem(10px);
	background-color: colors.$gray-extra-lighter;
}

.tableWrap {
	@include scrollbar.generate();

	width: 100%;
	min-height: 0.01%;
	padding-bottom: units.px2rem(28px);
	overflow: auto hidden;
	border: 0;

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}

	@include breakpoint.up(md) {
		overflow: visible;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	text-align: left;
}

.table td {
	padding: units.px2rem(8px);
	line-height: map.get(typography.$line-height-values, 1);
	vertical-align: top;
	border-top: units.px2rem(1px) solid colors.$gray-light;
}

.right {
	text-align: right;
}

.legend {
	display: flex;
	justify-content: space-between;
	max-width: units.px2rem(650px);
	margin: spacing.generate(10) spacing.generate(3) spacing.generate(3);

	@include breakpoint.up(md) {
		margin-right: 0;
		margin-left: 0;
	}
}

.legendColumn {
	width: calc(50% - #{units.px2rem(15px)});
}

.legendTable td {
	padding: spacing.generate(1) 0 spacing.generate(1) spacing.generate(2);
	vertical-align: middle;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		width: units.px2rem(70px);
		text-align: right;
	}
}

.legendCircle {
	display: block;
	width: units.px2rem(20px);
	height: units.px2rem(20px);
	border-radius: 50%;
}

.legendActive {
	font-weight: bold;
}

.legendInactive {
	opacity: 0.5;
}

.toggleWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(4);
	align-items: center;
	padding: spacing.generate(4) spacing.generate(4) spacing.generate(7);
	margin-bottom: spacing.generate(1);
	text-align: center;
	border-bottom: borders.$width solid colors.$gray-light;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(8);
	}

	@include breakpoint.up(xl) {
		flex-direction: row;
		gap: spacing.generate(4);
		justify-content: space-evenly;
	}
}

.toggleColumn:last-child {
	margin-top: spacing.generate(4);

	@include breakpoint.up(md) {
		margin-top: 0;
	}
}

.toggleTitle {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);
	text-align: center;
}
