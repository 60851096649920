@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(600px);
	margin-top: spacing.generate(10);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.section {
	margin-bottom: spacing.generate(6);
}

.section.isHidden {
	display: none;
}

.title {
	margin-top: 0;
	margin-bottom: spacing.generate(2);
	font-size: units.px2rem(24px);
	text-align: left;
}

.text {
	margin-bottom: spacing.generate(2);
	font-size: units.px2rem(16px);
	text-align: left;

	&:last-of-type {
		margin-bottom: spacing.generate(4);
	}
}

.confirm {
	margin-top: spacing.generate(10);
	margin-bottom: spacing.generate(6);
}

.warning {
	margin-bottom: spacing.generate(1);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.3;
	text-align: left;
}

.warning > svg {
	position: relative;
	top: units.px2rem(1px);
	height: 1em;
	margin-right: spacing.generate(2);
	line-height: 1;
	color: colors.$orange;
}

.textRed {
	color: colors.$red;
}

.textLeft {
	text-align: left;
}

.flexWrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.infoBox {
	position: relative;
	display: inline-flex;
	gap: spacing.generate(8);
	min-width: units.px2rem(300px);
	padding: spacing.generate(2) spacing.generate(2) spacing.generate(2) spacing.generate(7);
	margin-bottom: spacing.generate(6);
	border-radius: units.px2rem(6px);
	background-color: colors.$gray-extra-light;
}

.infoIcon {
	position: absolute;
	top: spacing.generate(2);
	left: spacing.generate(2);
}

.infoList {
	display: inline-flex;
}

.infoListTitle,
.infoListItem {
	font-size: map.get(typography.$font-size-values, 1);
}

.infoListTitle {
	margin-top: units.px2rem(3px);
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.infoListItem {
	padding-block: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, medium);
}

.modalText {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;
}

.modalControlItem {
	margin-bottom: spacing.generate(2);
}
