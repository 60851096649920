@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(300px);
	margin-top: spacing.generate(6);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.text {
	margin-bottom: spacing.generate(4);
}

.wrap {
	margin-bottom: spacing.generate(6);
}
