@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';
@use '../mixins';

.root {
	@include mixins.section();
}

.isRootCondensed {
	padding-top: spacing.generate(8);
	padding-bottom: spacing.generate(8);
	margin-top: 0;

	@include breakpoint.up(md) {
		padding-top: spacing.generate(12);
		padding-bottom: spacing.generate(12);
	}
}

.isRootBordered {
	border-bottom: units.px2rem(1px) solid colors.$gray-lighter;
}

.isRootWineDark {
	background-color: colors.$wine-dark;
}

.isRootOrangeLighter {
	background-color: colors.$orange-lighter;
}

.isRootBlueDark {
	background-color: colors.$blue-dark;
}

.isRootGrayExtraLight {
	background-color: colors.$gray-extra-light;
}
