@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography' as typography-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.title {
	@include typography.generate(title);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(4);
	}
}

.description {
	@include typography.generate(description);

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(12);
	}

	@include breakpoint.up(xl) {
		margin-bottom: spacing.generate(22);
	}
}

.subtitle {
	margin-top: spacing.generate(3);
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 4);
	line-height: map.get(typography-settings.$line-height-values, 0);
	text-transform: uppercase;
	color: colors.$wine;

	@include breakpoint.up(sm) {
		font-size: map.get(typography-settings.$font-size-values, 6);
	}

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(6);
		margin-bottom: spacing.generate(6);
		font-size: map.get(typography-settings.$font-size-values, 8);
	}
}

.text {
	@include typography.generate(text);

	@include breakpoint.up(lg) {
		padding-bottom: spacing.generate(6);
	}
}

.icon {
	margin-top: spacing.generate(8);

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.icon > svg {
	width: auto;
	height: units.px2rem(190px);
}
