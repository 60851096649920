@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	display: inline-flex;
	padding: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	border-radius: spacing.generate(2);
	background: colors.$white;
	box-shadow: 0 0 spacing.generate(2) #{rgba(colors.$black, 0.1)};
}

.image {
	width: units.px2rem(52px);
	height: units.px2rem(52px);

	@include breakpoint.up(md) {
		width: units.px2rem(72px);
		height: units.px2rem(72px);
	}
}

.text {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	padding-left: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.name {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	color: colors.$orange;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.icon {
	margin-left: spacing.generate(2);
}

.info {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -2);
}
