@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-block: 0 spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);
	color: colors.$orange;

	@include breakpoint.up(sm) {
		margin-block: spacing.generate(2) spacing.generate(2);
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.titleOffset {
	margin-bottom: spacing.generate(10);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(6);
	}
}

.text {
	max-width: units.px2rem(600px);
	margin-inline: auto;
	margin-bottom: spacing.generate(9);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 9);
	}
}
