@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 700;
	font-size: units.px2rem(36px);
	line-height: 1.4;

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(20);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(6);
		font-size: units.px2rem(60px);
	}
}
