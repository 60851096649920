@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/scrollbar';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(35px);
	line-height: 1.4;
}

.profile {
	max-width: units.px2rem(640px);
	font-size: units.px2rem(18px);
	line-height: 1.5;
}

.tableWrap {
	@include scrollbar.generate();

	min-height: 0.01%;
	padding-bottom: units.px2rem(28px);
	overflow-x: auto;
	border: 0;

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: spacing.generate(4);
}

.table th {
	padding: units.px2rem(8px);
	font-weight: 600;
	font-size: units.px2rem(16px);
	line-height: 1.4;
	vertical-align: bottom;
	border-bottom: units.px2rem(2px) solid colors.$wine;
}

.table td {
	padding: units.px2rem(8px) spacing.generate(2);
	font-weight: 600;
	font-size: units.px2rem(18px);
	line-height: 2;
	border-top: units.px2rem(1px) solid colors.$gray-light;
}

.divider td {
	border-top: units.px2rem(2px) solid colors.$wine;
}

.table tfoot th {
	padding: units.px2rem(8px) spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(26px);
	line-height: units.px2rem(36px);
	border-top: units.px2rem(2px) solid colors.$wine;
	border-bottom: 0;
}

.table tfoot svg {
	margin-left: spacing.generate(1);
}

.name {
	width: 40%;
	max-width: units.px2rem(100px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	@include breakpoint.up(sm) {
		max-width: units.px2rem(200px);
	}

	@include breakpoint.up(md) {
		max-width: units.px2rem(675px);
	}
}

.right {
	text-align: right;
}

.rightPart {
	width: 15%;
	text-align: right;
}

.rightPartSmall {
	width: 10%;
	text-align: right;
}
