@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding: spacing.generate(4);
	border-top: units.px2rem(1px) solid colors.$gray-extra-light;
}

.table {
	width: 100%;
	margin-bottom: spacing.generate(4);
}

.table td {
	vertical-align: middle;
}

.head {
	display: flex;
	justify-content: space-between;
	margin-bottom: spacing.generate(2);
}

.row {
	display: flex;
	align-items: center;
	margin-bottom: spacing.generate(1);
}

.columnStatus {
	width: units.px2rem(40px);
	padding-top: spacing.generate(1);
	padding-bottom: spacing.generate(1);
}

.accountStatus {
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(30px);
	height: units.px2rem(30px);
	margin-right: spacing.generate(1);
	border-radius: 50%;
	background: colors.$white;
	box-shadow: 0 0 units.px2rem(10px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(sm) {
		margin-right: spacing.generate(2);
	}
}

.accountNumber {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(sm) {
		font-weight: map.get(typography.$font-weight-values, regular);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.accountControl {
	display: flex;
	justify-content: flex-end;
	padding-top: spacing.generate(1);
	padding-bottom: spacing.generate(1);
	margin-left: auto;
}

.title {
	display: flex;
	align-items: center;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);
	white-space: nowrap;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.titleIcon {
	position: relative;
	margin-left: spacing.generate(1);
	font-size: 0;
}

.modalText {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;
}

.modalControlItem {
	margin-bottom: spacing.generate(2);
}

.modalAccount {
	max-width: units.px2rem(420px);
	margin: spacing.generate(6) auto;
}

.buttonWrap {
	display: flex;
	align-items: center;
	margin-top: spacing.generate(4);
}
