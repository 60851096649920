@use '../../../scss/settings/colors';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(3);
	text-align: left;
}

.labelWrap {
	display: flex;
	color: colors.$wine;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 700;
	text-align: left;
	white-space: nowrap;
}

.inputWrap {
	position: relative;
}

.input {
	display: block;
	width: 100%;
	height: units.px2rem(34px);
	border: units.px2rem(1px) solid colors.$gray-light;
	border-right: 0;
	border-radius: units.px2rem(4px);
}

/* stylelint-disable selector-class-pattern */
.input :global .react-date-picker__wrapper {
	border: 0;
}

.input :global .react-date-picker__inputGroup {
	padding: units.px2rem(6px) units.px2rem(12px);
	font-size: units.px2rem(14px);
	line-height: units.px2rem(22px);
}

.input :global .react-date-picker__calendar {
	z-index: 3;
}

.input :global .react-date-picker__calendar-button {
	height: units.px2rem(33px);
	color: colors.$orange;
	border-top-right-radius: units.px2rem(4px);
	border-bottom-right-radius: units.px2rem(4px);
	background-color: colors.$gray-lighter;
}

.input :global .react-calendar__tile--now {
	background: colors.$error-lighter;

	&:not(:disabled):hover,
	&:not(:disabled):focus {
		background: colors.$error-light;
	}
}

.input :global .react-calendar__tile--active {
	background: colors.$orange;

	&:hover,
	&:focus {
		background: colors.$orange;
	}
}
/* stylelint-enable */
