@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(600px);
	margin: 0 auto;
}

.title {
	margin-bottom: spacing.generate(3);
	font-weight: 700;
	font-size: units.px2rem(22px);
	line-height: 1.1;

	@include breakpoint.up(md) {
		font-size: units.px2rem(35px);
	}

	@include breakpoint.up(lg) {
		font-size: units.px2rem(45px);
	}
}

.description,
.paragraph {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(14px);
	line-height: 1.4;

	@include breakpoint.up(md) {
		font-size: units.px2rem(20px);
	}
}

.vocabulary {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	margin-top: spacing.generate(4);
}

.itemTitle {
	margin-bottom: spacing.generate(2);
}

.collapse {
	@include transitions.generate(max-height);

	max-height: 0;
	overflow-y: hidden;
}

.collapse.isCollapseActive {
	transition-timing-function: ease-in;
}

.collapseTrigger {
	@include transitions.generate(text-decoration-color);

	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	font-weight: 700;
	font-size: units.px2rem(16px);
	font-family: inherit;
	text-align: left;
	text-decoration: underline;
	text-decoration-color: color.adjust(colors.$red, $alpha: -0.5);
	color: colors.$red;
	border: 0;
	background-color: transparent;

	&:hover,
	&:focus {
		text-decoration-color: colors.$red;
	}

	@include breakpoint.up(md) {
		font-size: units.px2rem(20px);
	}
}

.icon {
	@include transitions.generate(transform);

	width: units.px2rem(14px);
	height: units.px2rem(14px);
	margin-left: spacing.generate(3);
	color: colors.$orange;
	transform: rotate(0);
}

.iconOpen {
	transform: rotate(180deg);
}
