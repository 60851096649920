@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/chart';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-top: spacing.generate(10);
	text-align: center;

	@include breakpoint.up(lg) {
		text-align: left;
	}
}

.valueTitle {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 11);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 13);
	}
}

.value {
	margin-bottom: spacing.generate(10);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 14);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 15);
	}

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 18);
	}
}

.netGrowthWrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: spacing.generate(4);

	@include breakpoint.up(lg) {
		display: block;
		margin-top: 0;
	}
}

.netGrowthTitle {
	max-width: units.px2rem(220px);
	font-size: map.get(typography.$font-size-values, 4);
}

.netGrowthTitleIcon {
	position: relative;
	top: units.px2rem(2px);
	margin-left: units.px2rem(6px);
	font-size: 0;
}

.netGrowthValue {
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);
}

.netGrowth {
	display: flex;
	align-items: center;
}

.netGrowth > svg {
	margin-right: spacing.generate(2);
	transform: rotate(-45deg);
}

.netGrowthUp {
	color: colors.$green-dark;
}

.netGrowthUp > svg {
	transform: rotate(-45deg);
}

.netGrowthDown {
	color: colors.$red-light;
}

.netGrowthDown > svg {
	transform: rotate(45deg);
}

.chartWrap {
	@include chart.wrap();

	margin-top: spacing.generate(10);
	margin-right: auto;
	margin-left: auto;

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.chart {
	@include chart.generate();
}

.chart svg {
	overflow: visible;
}
