@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/transitions';
@use '../mixins';

.root {
	@include mixins.post-box();

	text-align: center;

	@include breakpoint.up(md) {
		display: flex;
		grid-gap: spacing.generate(9);
		align-items: center;
		justify-content: center;
		text-align: left;
	}
}

.header {
	@include breakpoint.up(md) {
		flex-shrink: 0;
	}
}

.title {
	@include mixins.post-box-title();
}

.description {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);

	@include breakpoint.up(md) {
		margin-bottom: 0;
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.list {
	display: flex;
	grid-gap: spacing.generate(4);
	align-items: center;
	justify-content: center;

	@include breakpoint.up(md) {
		grid-gap: spacing.generate(7);
	}
}

.icon {
	@include transitions.generate('transform');
}

.link {
	display: flex;
	flex-direction: column;
	grid-gap: spacing.generate(2);
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: 0;
	text-decoration: none;

	@include breakpoint.up(md) {
		flex-direction: row;
	}

	&:hover > .icon,
	&:focus > .icon {
		transform: scale(1.05);
	}
}

.label {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 1);
	color: colors.$wine;
}
