@use '../mixins';

.root {
	@include mixins.grid-wrap();
}

.center {
	justify-content: center;
}

.middle {
	align-items: center;
}

.start {
	justify-content: flex-start;
}
