@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	text-align: center;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(6);
		text-align: left;
	}
}

.wrap {
	display: flex;
	flex-direction: column;

	@include breakpoint.up(md) {
		flex-flow: row wrap;
		margin-bottom: spacing.generate(4);
	}
}

.item {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(md) {
		flex-flow: column wrap;
		align-items: center;
		width: 50%;
		padding-inline: spacing.generate(4);
		text-align: center;
	}

	@include breakpoint.up(xl) {
		width: 25%;
	}
}

.title {
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 8);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(3);
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 2);
	}

	@include breakpoint.up(md) {
		max-width: units.px2rem(270px);
	}
}

.icon {
	display: flex;
	width: units.px2rem(60px);
	height: 100%;
	margin-right: spacing.generate(2);

	@include breakpoint.up(md) {
		width: units.px2rem(140px);
		height: units.px2rem(140px);
		margin-bottom: spacing.generate(3);
	}
}

.icon > svg {
	display: block;
	width: auto;
	height: 100%;
}

.buttonWrap {
	width: 100%;
	text-align: center;
}
