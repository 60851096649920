@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: spacing.generate(8);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, 0);
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(44px);
	min-width: units.px2rem(44px);
	height: units.px2rem(44px);
	min-height: units.px2rem(44px);
	margin-right: spacing.generate(3);
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: 50%;
	background-color: colors.$brown-lightest;
}
