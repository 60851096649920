@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(8);
		font-size: map.get(typography.$font-size-values, 14);
		line-height: map.get(typography.$line-height-values, 1);
	}
}

.wrap {
	width: 100%;
	height: units.px2rem(500px);
	margin-bottom: spacing.generate(8);

	@include breakpoint.up(lg) {
		margin-bottom: 0;
	}
}

.subtitle {
	margin-bottom: spacing.generate(4);
	font-weight: 500;
	font-size: units.px2rem(30px);
	line-height: 1.1;
	text-align: center;
	color: colors.$orange;

	@include breakpoint.up(lg) {
		text-align: left;
	}
}

.paragraph {
	text-align: center;

	@include breakpoint.up(lg) {
		text-align: left;
	}
}

.paragraph:not(:last-child) {
	margin-bottom: spacing.generate(4);
}

.tooltip {
	padding: spacing.generate(2);
	font-size: units.px2rem(14px);
	border-radius: units.px2rem(5px);
	background-color: colors.$white;
}

.tooltipRed {
	border: borders.$width solid colors.$red;
}

.tooltipOrange {
	border: borders.$width solid colors.$orange;
}

.link {
	font-weight: map.get(typography.$font-weight-values, bold);
}
