@use '../../scss/settings/colors';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: normal;
	font-size: units.px2rem(30px);
	text-transform: uppercase;

	@include breakpoint.up(md) {
		font-size: units.px2rem(35px);
	}

	@include breakpoint.up(lg) {
		font-size: units.px2rem(45px);
	}
}

.text,
.textSmaller {
	max-width: units.px2rem(700px);
	margin-bottom: spacing.generate(8);
	white-space: pre-line;
}

.text {
	font-size: units.px2rem(22px);
}

.textSmaller {
	font-size: units.px2rem(17px);
}
