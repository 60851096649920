@use 'sass:map';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/units';
@use '../../scss/tools/spacing';

.root {
	max-width: units.px2rem(800px);
	margin-inline: auto;
}

.description {
	padding-inline: spacing.generate(3);
	margin-bottom: spacing.generate(8);

	@include breakpoint.up(md) {
		padding-left: spacing.generate(19);
		margin-bottom: spacing.generate(12);
	}
}

.text {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}
