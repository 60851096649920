@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-top: 0;
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	line-height: map.get(typography.$line-height-values, -1);
	color: colors.$orange;

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(6);
		margin-bottom: spacing.generate(2);
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.text {
	max-width: units.px2rem(670px);
	margin-right: auto;
	margin-left: auto;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 9);
	}
}
