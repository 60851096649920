@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.product {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	width: 100%;

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(6);
		justify-content: space-between;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: units.px2rem(620px);

	@include breakpoint.up(md) {
		align-items: flex-start;
	}
}

.title {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	color: colors.$orange;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(5);
		font-size: map.get(typography.$font-size-values, 16);
		line-height: 1.2;
	}
}

.text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(4);
	}
}

.buttonWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(4);
	align-items: center;

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

.illustration {
	width: 100%;
	max-width: units.px2rem(370px);
	height: units.px2rem(165px);
	padding-inline: spacing.generate(7);
	margin-block: spacing.generate(4) spacing.generate(10);
	border-radius: units.px2rem(20px);
	background-color: #{rgba(29, 137, 172, 0.1)};

	@include breakpoint.up(md) {
		max-width: units.px2rem(640px);
		height: units.px2rem(190px);
		margin-block: spacing.generate(8) 0;
	}

	@include breakpoint.up(lg) {
		height: units.px2rem(300px);
		padding-inline: spacing.generate(10);
	}

	@include breakpoint.up(xl) {
		height: units.px2rem(350px);
	}
}

.illustration > svg {
	position: relative;
	top: units.px2rem(-20px);
	display: block;
	width: auto;
	height: calc(100% + #{units.px2rem(40px)});
	margin-inline: auto;

	@include breakpoint.up(md) {
		top: units.px2rem(-40px);
		height: calc(100% + #{units.px2rem(80px)});
	}
}
