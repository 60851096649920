@use '../../../scss/settings/colors';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(600px);
	margin-top: spacing.generate(10);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.section {
	margin-bottom: spacing.generate(6);
}

.section.isHidden {
	display: none;
}

.title {
	margin-top: 0;
	margin-bottom: spacing.generate(2);
	font-size: units.px2rem(24px);
	text-align: left;
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.text {
	margin-bottom: spacing.generate(2);
	font-size: units.px2rem(16px);
	text-align: left;

	&:last-of-type {
		margin-bottom: spacing.generate(4);
	}
}

.confirm {
	margin-bottom: spacing.generate(6);
}

.warning {
	margin-bottom: spacing.generate(1);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.3;
	text-align: left;
}

.warning > svg {
	position: relative;
	top: units.px2rem(1px);
	height: 1em;
	margin-right: spacing.generate(2);
	line-height: 1;
	color: colors.$orange;
}

.textRed {
	color: colors.$red;
}

.textLeft {
	text-align: left;
}
