@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';

.buttonWrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.codeWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(2);

	@include breakpoint.up(md) {
		flex-direction: row;
		align-items: center;
	}
}

.codeButtonWrap {
	display: flex;
	gap: spacing.generate(3);
	margin-bottom: spacing.generate(7);

	@include breakpoint.up(md) {
		margin-bottom: 0;
	}
}
