@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(lg) {
		padding-right: spacing.generate(8);
		padding-left: spacing.generate(8);
	}
}
