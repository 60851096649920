@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.root {
	padding: spacing.generate(4) spacing.generate(3);
	margin-inline: spacing.generate(-3);
	border-top: borders.$width solid colors.$gray-extra-light;

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(4);
		margin-inline: spacing.generate(-4);
	}
}

.title {
	margin-bottom: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);
}

.titleIcon {
	position: relative;
	top: units.px2rem(2px);
	margin-left: units.px2rem(6px);
	font-size: 0;
}

.modalText {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;

	@include breakpoint.up(sm) {
		display: flex;
		justify-content: flex-end;
		text-align: right;
	}
}

.modalControlItem {
	margin-bottom: spacing.generate(2);

	@include breakpoint.up(sm) {
		margin-bottom: 0;
		margin-left: spacing.generate(2);
	}
}
