@use '../../../scss/tools/units';

@mixin shimmer-animation() {
	background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%, #f0f0f0 100%);
	background-size: units.px2rem(1440px) 100%;
	animation: shimmer-keyframes 2.5s infinite;
}

@mixin base() {
	width: 100%;
}

@keyframes shimmer-keyframes {
	0% {
		background-position: units.px2rem(-1440px) 0;
	}

	100% {
		background-position: units.px2rem(1440px) 0;
	}
}
