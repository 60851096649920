@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(5);
	justify-content: center;
	margin-bottom: spacing.generate(6);
}

.product {
	display: block;
	padding: 0;
	overflow: hidden;
	border: 0;
	border-radius: spacing.generate(2);
	background-color: colors.$white;
	opacity: 0.4;
	cursor: pointer;

	@include breakpoint.up(md) {
		width: calc(50% - #{spacing.generate(5)});
	}

	@include breakpoint.up(lg) {
		width: initial;
	}
}

.product.selected {
	border-color: colors.$orange;
	opacity: 1;
}

.icon {
	display: block;
	width: units.px2rem(70px);
	height: auto;
	margin-inline: auto;

	@include breakpoint.up(sm) {
		width: units.px2rem(100px);
	}

	@include breakpoint.up(md) {
		width: units.px2rem(170px);
	}
}

.icon > svg {
	max-width: 100%;
	height: units.px2rem(70px);

	@include breakpoint.up(sm) {
		height: units.px2rem(100px);
	}

	@include breakpoint.up(md) {
		height: auto;
	}
}

.title {
	width: 100%;
	margin-block: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 0);
}
