@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	@include breakpoint.up(md) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.titleWrap {
	display: flex;
	flex-direction: column;
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(md) {
		flex-direction: row;
		align-items: center;
		margin-bottom: spacing.generate(6);
	}
}

.logo {
	width: units.px2rem(35px);
	height: units.px2rem(37px);
	margin-bottom: spacing.generate(2);

	@include breakpoint.up(md) {
		width: units.px2rem(60px);
		height: units.px2rem(63px);
		margin-right: spacing.generate(3);
		margin-bottom: 0;
	}
}

.title,
.list {
	color: colors.$white;
}

.title {
	margin-bottom: 0;
	font-weight: 700;
	font-size: units.px2rem(22px);
	line-height: 1.1;

	@include breakpoint.up(md) {
		font-size: units.px2rem(35px);
	}

	@include breakpoint.up(lg) {
		font-size: units.px2rem(45px);
	}
}

.list {
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(md) {
		max-width: units.px2rem(358px);
		margin-bottom: spacing.generate(8);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(10);
	}
}

.listItem {
	position: relative;
	padding-left: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(14px);
	line-height: 1.4;

	&:not(:last-child) {
		margin-bottom: spacing.generate(4);
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
	}

	&::before {
		top: 0;
		left: 0;
		width: units.px2rem(18px);
		height: units.px2rem(18px);
		padding: units.px2rem(4px);
		border-radius: 50%;
		background-color: colors.$green;
	}

	&::after {
		top: units.px2rem(3px);
		left: units.px2rem(6px);
		width: units.px2rem(6px);
		height: units.px2rem(10px);
		border-right: units.px2rem(3px) solid colors.$wine-dark;
		border-bottom: units.px2rem(3px) solid colors.$wine-dark;
		border-radius: units.px2rem(2px);
		transform: rotate(45deg);
	}

	@include breakpoint.up(md) {
		font-size: units.px2rem(20px);

		&::before {
			top: units.px2rem(5px);
		}

		&::after {
			top: units.px2rem(8px);
		}
	}
}

.linkGroup {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.link {
	@include transitions.generate(text-decoration-color);

	margin-top: spacing.generate(4);
	font-weight: 700;
	font-size: units.px2rem(16px);
	text-decoration-color: color.adjust(colors.$white, $alpha: -0.5);
	color: colors.$white;

	&:hover,
	&:focus {
		text-decoration-color: colors.$white;
		color: colors.$white;
	}

	@include breakpoint.up(md) {
		font-size: units.px2rem(18px);
	}
}
