@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/ranger';
@use '../../../scss/tools/units';

.root {
	@include ranger.root();
}

.labels {
	@include ranger.labels();
}

.labelsSmall {
	justify-content: space-between;
	font-size: units.px2rem(14px);

	@include breakpoint.up(md) {
		justify-content: center;
	}
}

.label {
	@include ranger.label();
}

.labelTooltip {
	@include ranger.label-tooltip();
}

.ranger {
	@include ranger.generate();

	border-radius: units.px2rem(25px);
	background: linear-gradient(to right, colors.$red-dark 0%, colors.$gray-lighter 0%);
}

.ranger::-webkit-slider-runnable-track {
	@include ranger.track();
}

.ranger::-webkit-slider-thumb {
	@include ranger.thumb();

	appearance: none;
	margin-top: units.px2rem(-7px);
}

.ranger::-moz-range-track {
	@include ranger.track();
}

.ranger::-moz-range-thumb {
	@include ranger.thumb();
}

.control {
	@include ranger.control();
}

.button {
	@include ranger.button();
}

.labelsSmall > .label {
	@include ranger.label-small();
}

.input {
	@include ranger.input();

	width: units.px2rem(40px);
}
