@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

@mixin post-box() {
	padding: spacing.generate(5) spacing.generate(3);
	margin-top: spacing.generate(4);
	border-radius: units.px2rem(10px);
	background-color: colors.$gray-extra-light;

	@include breakpoint.up(md) {
		padding: spacing.generate(9) spacing.generate(24);
	}
}

@mixin post-box-title() {
	margin-bottom: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: map.get(typography.$line-height-values, -1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

@mixin unordered-list() {
	li > ul {
		margin-top: spacing.generate(4);
		margin-bottom: spacing.generate(4);
	}

	li,
	li > ul > li {
		position: relative;
		margin-bottom: spacing.generate(3);
		margin-left: spacing.generate(4);
		font-weight: map.get(typography.$font-weight-values, medium);
		font-size: map.get(typography.$font-size-values, 4);
		line-height: map.get(typography.$line-height-values, 1);

		@include breakpoint.up(lg) {
			font-size: map.get(typography.$font-size-values, 5);
		}

		&::before {
			content: '';
			position: absolute;
			top: units.px2rem(8px);
			left: units.px2rem(-20px);
			width: units.px2rem(12px);
			height: units.px2rem(12px);
			border-radius: 50%;
			background-color: colors.$red;

			@include breakpoint.up(lg) {
				top: units.px2rem(10px);
			}
		}
	}

	li > ul > li::before {
		border: units.px2rem(2px) solid colors.$red;
		background-color: transparent;
	}
}

@mixin centered() {
	max-width: units.px2rem(720px);
	margin-inline: auto;
}
