@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(14);
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.icon {
	width: auto;
	height: units.px2rem(63px);
	margin-bottom: spacing.generate(6);
}

.itemTitle {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	text-transform: uppercase;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(8);
	}
}

.description {
	max-width: units.px2rem(340px);
	margin-inline: auto;
	margin-bottom: spacing.generate(8);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		margin-bottom: 0;
		font-weight: map.get(typography.$font-weight-values, medium);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.description > a {
	font-weight: map.get(typography.$font-weight-values, bold);
}
