@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.formWrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.infoBox {
	position: relative;
	display: inline-flex;
	gap: spacing.generate(8);
	min-width: units.px2rem(300px);
	padding: spacing.generate(2) spacing.generate(2) spacing.generate(2) spacing.generate(7);
	margin-bottom: spacing.generate(6);
	border-radius: units.px2rem(6px);
	background-color: colors.$gray-extra-light;
}

.infoIcon {
	position: absolute;
	top: spacing.generate(2);
	left: spacing.generate(2);
}

.infoList {
	display: inline-flex;
}

.infoListTitle,
.infoListItem {
	font-size: map.get(typography.$font-size-values, 1);
}

.infoListTitle {
	margin-top: units.px2rem(3px);
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.infoListItem {
	padding-block: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, medium);
}
