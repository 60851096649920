@use 'sass:map';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../mixins';

.root {
	@include mixins.post-box();
}

.info {
	border: map.get(borders.$width-values, large) solid colors.$orange;
}

.icon {
	margin-bottom: spacing.generate(3);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(9);
	}
}

.root h2 {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 6);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.root p {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.root p:last-child:not(:only-child) {
	margin-top: spacing.generate(6);
}

.info > .icon {
	color: colors.$orange;
}

.root > ul {
	@include mixins.unordered-list();
}
