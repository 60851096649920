@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	@include breakpoint.up(md) {
		padding-inline: spacing.generate(6);
	}

	@include breakpoint.up(xl) {
		padding-inline: spacing.generate(14);
	}
}

.head {
	padding: spacing.generate(14) 0 spacing.generate(8);
	text-align: center;

	@include breakpoint.up(md) {
		text-align: left;
	}
}

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.sum.sum {
	margin-bottom: spacing.generate(6);
	font-weight: bold;
	font-size: map.get(typography.$font-size-values, 14);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(2);
		font-size: map.get(typography.$font-size-values, 18);
	}
}

.growthLabel {
	font-size: map.get(typography.$font-size-values, 4);
}

.growthValue {
	display: flex;
	justify-content: center;

	@include breakpoint.up(md) {
		justify-content: flex-start;
	}
}

.donut {
	display: flex;
	justify-content: center;
	margin-top: spacing.generate(10);

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.info {
	margin-top: spacing.generate(4);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-top: 0;
		text-align: left;
	}
}

.infoProfile {
	@include breakpoint.up(md) {
		display: flex;
		align-items: center;
	}
}

.infoProfile > img {
	margin-bottom: spacing.generate(2);

	@include breakpoint.up(md) {
		margin-right: spacing.generate(4);
		margin-bottom: 0;
	}
}

.infoLabel {
	margin-top: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, -1);
}

.infoValue {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 8);
	line-height: map.get(typography.$line-height-values, 0);
}

.note {
	margin-top: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	text-align: left;
}

.toggleWrap {
	position: relative;
	bottom: spacing.generate(-5);
	text-align: center;

	@include breakpoint.up(md) {
		bottom: spacing.generate(-10);
		text-align: right;
	}
}
