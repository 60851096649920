@use 'sass:color';
@use 'sass:map';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(8);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 11);
	text-align: center;
}

.wrap {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(4);
	justify-content: center;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding: spacing.generate(4);
	border-radius: units.px2rem(20px);
	background-color: colors.$gray-extra-lighter;

	@include breakpoint.up(sm) {
		width: calc(50% - #{units.px2rem(20px)});
	}

	@include breakpoint.up(xl) {
		width: calc(25% - #{units.px2rem(20px)});
	}
}

.icon {
	width: units.px2rem(64px);
	height: units.px2rem(64px);
	margin-bottom: spacing.generate(4);
	border-radius: 50%;
}

.subTitle {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);
}

.text {
	flex-grow: 1;
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
}
