@use '../../../scss/settings/colors';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	justify-content: space-between;
	max-width: units.px2rem(420px);
	padding-right: spacing.generate(3);
	padding-left: spacing.generate(3);
	margin-right: auto;
	margin-bottom: spacing.generate(6);
	margin-left: auto;
}

.link {
	display: flex;
	flex-direction: column;
	margin-right: spacing.generate(2);
	margin-left: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.2;
	text-decoration: none;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: inherit;
	}
}

.icon {
	margin-bottom: spacing.generate(2);
}

.link > span {
	pointer-events: none;
}
