@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.root {
	margin-block: spacing.generate(4);

	@include breakpoint.up(md) {
		margin-block: spacing.generate(5);
	}
}

.image {
	display: block;
	border-radius: units.px2rem(10px);
}

.responsive {
	width: 100%;
	height: auto;
}

.caption {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 2);
	color: colors.$purple;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}
