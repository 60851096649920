@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography' as typography-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.title {
	@include typography.generate(title);
}

.description {
	@include typography.generate(description);
}

.icon {
	margin-top: spacing.generate(8);

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.icon > svg {
	width: auto;
	height: units.px2rem(190px);
}

.subtitle {
	@include typography.generate(subtitle);
}

.text {
	@include typography.generate(text);
}

.textSemibold {
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
}

.description:not(:last-child),
.text:not(:last-child) {
	margin-bottom: spacing.generate(6);
}

.wrap {
	max-width: units.px2rem(1120px);
	margin-top: spacing.generate(9);
	margin-bottom: spacing.generate(9);
	border-radius: units.px2rem(10px);
	box-shadow: 0 units.px2rem(2px) units.px2rem(35px) #{rgba(colors.$black, 0.1)};

	@include breakpoint.up(lg) {
		margin: spacing.generate(18) auto;
	}
}

.titleWrap {
	padding-top: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-size: map.get(typography-settings.$font-size-values, 9);
	line-height: map.get(typography-settings.$line-height-values, 1);
	color: colors.$wine;

	@include breakpoint.up(lg) {
		padding-top: spacing.generate(6);
		margin-bottom: spacing.generate(8);
		font-size: map.get(typography-settings.$font-size-values, 11);
	}
}

.loaderWrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: units.px2rem(150px);
}

.colWrap {
	@include breakpoint.up(lg) {
		padding-right: spacing.generate(5);
		padding-left: spacing.generate(5);
	}
}

.divider {
	margin-bottom: spacing.generate(4);
	border-style: solid;
	border-color: colors.$gray-light;
	border-top-width: 0;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(8);
	}
}
