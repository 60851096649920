@use 'sass:map';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';

.root {
	padding-inline: spacing.generate(3);
	margin-inline: spacing.generate(-3);
	border-top: borders.$width solid colors.$gray-extra-light;

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(4);
		margin-inline: spacing.generate(-4);
	}
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
}

.smallButton {
	padding: 0;
	text-decoration: underline;
	color: colors.$red;
	border: none;
	border-width: 0;
	background: transparent;
	outline: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: colors.$red-dark;
	}
}
