@use '../../scss/settings/colors';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.root {
	position: relative;
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	font-weight: inherit;
	font-size: units.px2rem(35px);
	line-height: 1.4;
	text-align: center;
}

.sectionTitle {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-weight: 500;
	font-size: units.px2rem(24px);
	line-height: 1.4;
	text-transform: uppercase;
}

.sectionText {
	font-size: units.px2rem(16px);
	line-height: 1;
}
