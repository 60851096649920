@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography' as typography-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.button {
	@include transitions.generate('opacity, color, background-color, transform, z-index');

	position: fixed;
	right: units.px2rem(37px);
	bottom: units.px2rem(100px);
	z-index: -1;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(2) spacing.generate(2);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: 0;
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$white;
	border: 0;
	border-radius: units.px2rem(25px);
	background-color: colors.$orange;
	box-shadow: units.px2rem(1px) units.px2rem(3px) units.px2rem(7px) 0 color.adjust(colors.$black, $alpha: -0.7);
	opacity: 0;

	&:active {
		background-color: colors.$orange-light;
		transform: scale(0.95);
	}

	@include breakpoint.up(lg) {
		right: units.px2rem(20px);
		padding: spacing.generate(2) spacing.generate(6);
		font-size: map.get(typography-settings.$font-size-values, 2);
	}
}

.button > svg {
	position: relative;
	top: units.px2rem(-1px);
	width: units.px2rem(9px);
	height: units.px2rem(9px);
	color: colors.$white;

	@include breakpoint.up(lg) {
		margin-right: spacing.generate(3);
	}
}

.active {
	z-index: 1;
	opacity: 1;
	cursor: pointer;

	&:hover,
	&:focus-visible {
		opacity: 0.9;
	}
}
