@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(10);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 12);
	line-height: map.get(typography.$line-height-values, 2);
}

.subtitle {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
}

.item {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);
}

.list {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(12);
}
