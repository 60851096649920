@use 'sass:map';
@use 'sass:math';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/arrow';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

$_chart-size: units.px2rem(200px);
$_chart-size-sm: units.px2rem(225px);
$_chart-size-lg: units.px2rem(250px);

.root {
	display: block;
}

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 10);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.descriptionWrap {
	max-width: units.px2rem(828px);
	margin: 0 auto spacing.generate(10);

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(22);
	}
}

.description {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 1);
}

.description:not(:last-of-type) {
	margin-bottom: spacing.generate(6);
}

.date {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -2);
	text-transform: uppercase;
	color: colors.$purple;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.value {
	font-size: map.get(typography.$font-size-values, 7);
	color: colors.$wine;
}

.wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: spacing.generate(2);
	align-items: flex-start;
	justify-content: space-between;

	@include breakpoint.up(lg) {
		left: 0;
		flex-direction: row;
		align-items: center;
	}
}

.pieChartWrap {
	display: flex;
	align-items: center;
	width: 100%;

	@include breakpoint.up(lg) {
		flex-direction: column-reverse;
		width: auto;
	}
}

.pieChart {
	position: relative;
	width: $_chart-size;
	height: $_chart-size;
	aspect-ratio: 1;
	margin-right: spacing.generate(3);
	border: units.px2rem(10px) solid colors.$white;
	border-radius: 50%;
	background: conic-gradient(colors.$purple 0% 50%, colors.$orange 50% 100%);
	box-shadow: 0 units.px2rem(12px) units.px2rem(60px) units.px2rem(-10px) #{rgba(colors.$black, 0.1)};

	@include breakpoint.up(sm) {
		width: $_chart-size-sm;
		height: $_chart-size-sm;
		margin-right: spacing.generate(6);
	}

	@include breakpoint.up(lg) {
		width: $_chart-size-lg;
		height: $_chart-size-lg;
		margin-right: 0;
	}
}

.pieChartWrap:nth-child(3) > .pieChart {
	background: conic-gradient(colors.$purple 0% 55%, colors.$orange 55% 100%);
}

.part {
	position: absolute;
	top: 50%;
	width: 50%;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	text-align: center;
	color: colors.$white;

	&:first-child {
		right: 25%;
		transform: translate(-50%, -50%);
	}

	&:last-child {
		left: 25%;
		transform: translate(50%, -50%);
	}
}

.text {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(1);
	align-items: center;
	padding: spacing.generate(2) spacing.generate(2) spacing.generate(1);
	white-space: nowrap;
	border-radius: units.px2rem(6px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(12px) units.px2rem(60px) units.px2rem(-10px) #{rgba(colors.$black, 0.3)};

	@include breakpoint.up(lg) {
		position: absolute;
		bottom: 100%;
		margin-bottom: spacing.generate(2);
	}
}

.arrowWrap {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: units.px2rem(200px);
	padding-top: spacing.generate(12);
	padding-bottom: spacing.generate(12);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		z-index: -1;
		width: units.px2rem(2px);
		height: 100%;
		background-color: colors.$gray-lighter;
		transform: translateX(-50%);
	}

	@include breakpoint.up(sm) {
		width: units.px2rem(225px);
	}

	@include breakpoint.up(lg) {
		width: auto;
		transform: none;

		&::before {
			inset: auto 0 50%;
			width: 100%;
			height: units.px2rem(2px);
			transform: translateY(50%);
		}
	}
}

.arrowWrap > p {
	position: absolute;
	right: units.px2rem(20px);
	display: flex;
	gap: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, -1);
	text-align: center;

	@include breakpoint.up(sm) {
		white-space: nowrap;
	}

	@include breakpoint.up(lg) {
		top: units.px2rem(35px);
		right: auto;
		left: auto;
		transform: none;
	}
}

.arrow {
	position: absolute;
	bottom: 0;
	transform: rotate(0);

	&::after {
		content: '';
		display: block;
		width: units.px2rem(12px);
		height: units.px2rem(12px);
		border-bottom: units.px2rem(2px) solid colors.$gray-lighter;
		border-left: units.px2rem(2px) solid colors.$gray-lighter;
		transform: translateY(units.px2rem(-2px)) rotate(-45deg);
	}

	@include breakpoint.up(lg) {
		right: 0;
		bottom: unset;
		transform: rotate(-90deg);
	}
}

.percent {
	display: inline-block;
	margin-bottom: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 8);
}
