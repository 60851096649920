@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	@include breakpoint.up(sm) {
		margin-right: spacing.generate(11);
		margin-left: spacing.generate(6);
	}
}

.title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}
