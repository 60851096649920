@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.title {
	@include typography.generate(title);

	margin-bottom: 0;

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(4);
		margin-bottom: spacing.generate(22);
	}
}

.subtitle {
	@include typography.generate(subtitle);
}

.text {
	@include typography.generate(text);

	padding-bottom: spacing.generate(0);

	@include breakpoint.up(lg) {
		padding-bottom: spacing.generate(10);
	}
}

.icon {
	margin-top: spacing.generate(8);

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.icon > svg {
	width: auto;
	height: units.px2rem(109px);

	@include breakpoint.up(sm) {
		height: units.px2rem(130px);
	}

	@include breakpoint.up(md) {
		height: units.px2rem(155px);
	}

	@include breakpoint.up(lg) {
		height: units.px2rem(175px);
	}
}
