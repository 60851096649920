@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(8);
	align-items: center;
	justify-content: space-between;

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

.content {
	display: flex;
	flex-direction: column;
	max-width: units.px2rem(680px);
	text-align: center;

	@include breakpoint.up(md) {
		text-align: left;
	}
}

.heroImage {
	width: units.px2rem(285px);
	height: auto;

	@include breakpoint.up(md) {
		width: auto;
	}
}

.heroImage img {
	display: block;
	width: 100%;
	height: auto;
}

.futureTitle,
.futureSubtitle,
.futureText {
	text-shadow: 0 units.px2rem(2px) units.px2rem(12px) #{rgba(colors.$black, 0.85)};
}

.title,
.subtitle,
.futureTitle,
.futureSubtitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	line-height: map.get(typography.$line-height-values, -2);
	text-transform: uppercase;
}

.futureTitle,
.futureSubtitle {
	line-height: map.get(typography.$line-height-values, -1);
}

.title,
.futureTitle {
	margin: 0;
	font-size: map.get(typography.$font-size-values, 14);
	line-height: map.get(typography.$line-height-values, -1);
	text-transform: none;
	color: colors.$wine;

	@include breakpoint.up(md) {
		text-transform: uppercase;
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 16);
	}

	@include breakpoint.up(xl) {
		margin-top: spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 17);
	}
}

.futureTitle {
	color: colors.$white;
}

.title:lang(pl) {
	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.subtitle,
.futureSubtitle {
	margin-bottom: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	text-transform: none;
	color: colors.$orange;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.futureSubtitle {
	color: colors.$white;
}

.highlight {
	color: colors.$orange;
}

.text,
.futureText {
	max-width: units.px2rem(680px);
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 2);
	color: colors.$wine;
}

.futureText {
	margin: 0 0 spacing.generate(6);
	color: colors.$white;
}

.buttonWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(2);
	align-items: center;
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(4);
		margin-bottom: 0;
	}
}

.bubbles {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.bubble {
	position: absolute;
	display: block;
	border-radius: 50%;
	opacity: 0.9;
}

.bubble1 {
	bottom: units.px2rem(80px);
	left: 50%;
	width: units.px2rem(325px);
	height: units.px2rem(325px);
	background-color: colors.$orange;
	transform: translateX(-50%);

	@include breakpoint.up(md) {
		top: -15vw;
		right: -10vw;
		bottom: unset;
		left: unset;
		width: 44vw;
		height: 44vw;
		transform: none;
	}

	@include breakpoint.up(xxl) {
		right: -5vw;
	}
}

.bubble2 {
	bottom: units.px2rem(-30px);
	left: units.px2rem(-16px);
	width: units.px2rem(133px);
	height: units.px2rem(133px);
	background-color: colors.$purple;

	@include breakpoint.up(md) {
		bottom: units.px2rem(-40px);
		left: units.px2rem(-40px);
		width: 18vw;
		height: 18vw;
	}

	@include breakpoint.up(xxl) {
		right: 23vw;
	}
}

.futureImage {
	position: absolute;
	inset: 0;
	z-index: -1;
}

.futureImage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.futureWrap {
	max-width: units.px2rem(620px);
}

.futureWide {
	max-width: units.px2rem(1070px);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(14);
	}
}
