@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(sm) {
		flex-direction: row;
		margin-bottom: 0;
	}
}

.inputWrap {
	width: 100%;

	@include breakpoint.up(sm) {
		margin-right: spacing.generate(4);
	}
}
