@use 'sass:map';
@use 'sass:color';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';
@use '../../../scss/tools/transitions';

.modalControl {
	display: flex;
	flex-direction: column;
	margin-top: spacing.generate(6);
	text-align: center;

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(3);
		margin-top: spacing.generate(10);
	}
}

.modalControlItem {
	flex: 1 1;
	margin-bottom: spacing.generate(2);
}

.title {
	margin-block: spacing.generate(10) spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 10);
	text-align: center;

	@include breakpoint.up(md) {
		margin-block: spacing.generate(14) spacing.generate(1);
		font-size: map.get(typography.$font-size-values, 13);
	}
}

.text {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);
	text-align: center;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.btn {
	@include transitions.generate('opacity, color, background-color');

	position: relative;
	display: flex;
	flex-direction: row;
	gap: spacing.generate(2);
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: units.px2rem(230px);
	height: 100%;
	padding: spacing.generate(3) spacing.generate(2) spacing.generate(3) spacing.generate(10);
	margin-inline: auto;
	text-decoration: none;
	color: colors.$wine;
	border: map.get(borders.$width-values, default) solid colors.$gray-light;
	border-radius: spacing.generate(5);
	background-color: colors.$white;
	opacity: 1;
	user-select: none;
	cursor: pointer;

	@include breakpoint.up(md) {
		max-width: initial;
	}

	&:hover,
	&:focus {
		color: colors.$orange;
		background-color: colors.$gray-extra-light;
		opacity: 0.9;
	}
}

.btn > svg {
	position: absolute;
	left: spacing.generate(2);
	display: block;
	width: units.px2rem(32px);
	height: auto;
	margin-inline: auto;
}

.btnText {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
}
