@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: center;
	color: colors.$orange;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(12);
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.row {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: units.px2rem(8px);

	@include breakpoint.up(sm) {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		grid-template-columns: repeat(5, 1fr);
	}

	@include breakpoint.up(xl) {
		grid-template-columns: repeat(6, 1fr);
	}
}

.item {
	@include transitions.generate('background-color, box-shadow');

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: units.px2rem(100px);
	border-radius: units.px2rem(20px);
	background-color: colors.$gray-extra-lighter;
	box-shadow: 0;

	&:hover {
		background-color: colors.$white;
		box-shadow: 0 units.px2rem(12px) units.px2rem(60px) units.px2rem(-10px) color.adjust(colors.$black, $alpha: -0.9);
	}

	@include breakpoint.up(md) {
		height: units.px2rem(120px);
	}
}

.itemIllustration {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.itemIllustration > img {
	display: block;
	max-width: 75%;
	max-height: 40%;

	@include breakpoint.up(sm) {
		max-width: 65%;
	}
}

.itemContent {
	text-align: center;
}

.itemText {
	margin: 0 spacing.generate(1) spacing.generate(2);
	font-size: units.px2rem(14px);

	@include breakpoint.up(sm) {
		font-size: units.px2rem(16px);
	}
}

.itemLink {
	display: inline-block;
	text-transform: uppercase;
}
