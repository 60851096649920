@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	padding-bottom: spacing.generate(16);
	text-align: center;

	@include breakpoint.up(lg) {
		padding-block: spacing.generate(5) spacing.generate(16);
	}
}

.noOffset {
	padding: 0;
}

.headerButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$white;
	border: 0;
	border-radius: units.px2rem(6px);
	background-color: colors.$orange;
	cursor: pointer;
}

.formWrap {
	max-width: units.px2rem(600px);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	white-space: normal;
}

.title {
	padding-top: spacing.generate(4);
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: left;
	border-top: borders.$width solid colors.$gray-lighter;
}

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(4);
	white-space: normal;

	&:last-of-type {
		margin-bottom: spacing.generate(8);
	}
}

.modalLinks {
	margin-top: spacing.generate(6);
}

.modalFooter {
	margin-top: spacing.generate(9);
}

.tooltip {
	margin-left: spacing.generate(1);
}

.button {
	@include transitions.generate('color, background-color, transform');

	display: inline-flex;
	grid-gap: spacing.generate(1);
	align-items: center;
	justify-content: space-around;
	min-width: units.px2rem(220px);
	min-height: units.px2rem(48px);
	padding: spacing.generate(2) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	vertical-align: middle;
	color: colors.$wine;
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: units.px2rem(36px);
	background-image: none;
	background-color: transparent;
	transform: scale(1);
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:disabled {
		@include button.disabled();
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active:not(:disabled) {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}

.button > svg {
	color: colors.$orange;
}

.toggleWrap {
	margin-bottom: spacing.generate(4);
}

.toggleLabel {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 0);
}

.wrap {
	text-align: center;
}

.options {
	margin-top: spacing.generate(6);
}

.warning {
	margin-top: spacing.generate(12);
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
}
