@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}

.text {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 1);
}

.text > svg {
	position: relative;
	top: units.px2rem(1px);
	height: units.px2rem(18px);
	margin-right: spacing.generate(2);
	color: colors.$orange;
}
