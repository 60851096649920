@use 'sass:color';
@use 'sass:map';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/layout';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/scrollbar';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.wrap {
	margin: spacing.generate(4) (-1 * layout.$layout-offset);

	@include breakpoint.up(md) {
		margin-block: spacing.generate(6);
	}
}

.root {
	@include scrollbar.generate();

	width: 100%;
	padding-inline: layout.$layout-offset;
	overflow: auto hidden;
}

.root > table {
	width: 100%;
	margin-bottom: spacing.generate(1);
	overflow: hidden;
	text-align: left;
	border: (map.get(borders.$width-values, large) solid colors.$wine);
	border-radius: units.px2rem(10px);
	border-collapse: separate;
}

/* stylelint-disable selector-max-compound-selectors */
.root > table > thead > tr > th,
.root > table > tbody > tr > td {
	padding: spacing.generate(3) 0 spacing.generate(3) spacing.generate(7);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 2);
	white-space: nowrap;

	@include breakpoint.up(md) {
		white-space: normal;
	}
}

.root > table > thead > tr > th {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.root > table > thead > tr > th:last-child,
.root > table > tbody > tr > td:last-child {
	padding-right: spacing.generate(7);
}

.root > table > tbody > tr:nth-child(odd) {
	background: colors.$gray-extra-light;
}
/* stylelint-enable */

.root > caption {
	display: inline-block;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 2);
	text-align: left;
	color: colors.$purple;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}
