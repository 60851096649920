@use '../tools/spacing';
@use '../tools/units';

$container-padding: spacing.generate(3);
$container-width-md: units.px2rem(750px);
$container-width-lg: units.px2rem(970px);
$container-width-xl: units.px2rem(1170px);
$container-width-xxl: units.px2rem(1440px);

$layout-grid-columns: 12;
$layout-offset: spacing.generate(3);

$section-bottom-padding: spacing.generate(16);
