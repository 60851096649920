@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/layout';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.header {
	padding: spacing.generate(4) 0;
	color: colors.$white;
	background-color: colors.$orange;
}

.container {
	@include layout.container();

	display: flex;
}

.icon {
	display: none;

	@include breakpoint.up(md) {
		display: block;
		margin-right: spacing.generate(4);
	}
}

.content {
	flex-grow: 1;
	font-size: units.px2rem(18px);
	line-height: 1.5;
}

.close {
	display: flex;
	align-items: center;
	width: units.px2rem(20px);
	height: units.px2rem(35px);
	padding: 0;
	font-size: units.px2rem(34px);
	line-height: 1;
	font-family: inherit;
	text-shadow: 0 units.px2rem(1px) 0 colors.$white;
	color: colors.$white;
	border: 0;
	background: transparent;
	opacity: 1;

	&:hover,
	&:focus {
		opacity: 0.5;
	}
}

.modalBody {
	padding: spacing.generate(2) spacing.generate(6) spacing.generate(10);
}

.content ol,
.content ul,
.modalBody ol,
.modalBody ul {
	list-style: revert;
}
