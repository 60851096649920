@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding: 0 spacing.generate(4);
	text-align: center;

	@include breakpoint.up(sm) {
		padding: spacing.generate(6) spacing.generate(12);
		text-align: left;
	}
}

.title {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(35px);
	line-height: 1.4;
}

.boxTitle {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(30px);
	line-height: 1.4;
}

.text {
	margin-bottom: spacing.generate(4);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.3;
}

.link {
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.3;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: spacing.generate(4);
	border-collapse: collapse;
	border-spacing: 0;
}

.tableOverview {
	max-width: units.px2rem(500px);
	font-weight: 500;
	font-size: units.px2rem(20px);
	text-align: left;
}

.table td {
	padding: units.px2rem(8px);
	line-height: 1.4;
	vertical-align: top;
	border-top: units.px2rem(1px) solid colors.$gray-light;
}

.tableOverview td {
	vertical-align: top;
}

.table th {
	padding: units.px2rem(8px);
	line-height: 1.4;
	vertical-align: bottom;
	border-bottom: units.px2rem(2px) solid colors.$gray-light;
}

.tableOverview th {
	font-weight: 700;
	font-size: units.px2rem(18px);
	vertical-align: top;
	border-top: units.px2rem(2px) solid colors.$wine;
}

.tableOverview tfoot th {
	font-size: units.px2rem(20px);
	border-bottom: 0;
}

.textRight {
	text-align: right;
}

.productTitle {
	@include breakpoint.up(sm) {
		max-width: units.px2rem(350px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.highlight {
	margin-right: units.px2rem(2px);
	font-weight: bold;
	color: colors.$orange;
}

.bonus {
	display: block;
	margin-left: units.px2rem(2px);
	font-size: units.px2rem(12px);
	white-space: nowrap;

	@include breakpoint.up(sm) {
		display: inline;
	}
}

.note,
.infoText {
	max-width: units.px2rem(370px);
	padding-right: spacing.generate(3);
	padding-left: spacing.generate(3);
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(6);
	font-size: units.px2rem(18px);
	line-height: 1.5;
}

.note {
	font-weight: 500;
}

.infoText {
	font-weight: 600;
}
