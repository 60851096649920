@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/transitions' as transitions-settings;
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	color: colors.$white;
	background-color: colors.$blue-dark;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	@include breakpoint.up(sm) {
		flex-direction: row;
	}

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(4);
	}
}

.logo {
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(sm) {
		margin-bottom: 0;
	}
}

.content {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: 100%;
}

.navigation {
	order: 2;

	@include breakpoint.up(sm) {
		order: initial;
	}
}

.products {
	order: 3;

	@include breakpoint.up(sm) {
		order: initial;
	}
}

.legal {
	order: 4;

	@include breakpoint.up(lg) {
		order: initial;
		max-width: units.px2rem(280px);
	}

	@include breakpoint.up(xl) {
		max-width: units.px2rem(425px);
	}
}

.title {
	margin-block: spacing.generate(8) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);
	text-align: left;
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 2);

	&:not(:last-child) {
		margin-bottom: spacing.generate(6);
	}
}

.list {
	min-width: units.px2rem(145px);
	padding-left: 0;
	text-align: left;
	list-style: none;
}

.list > li {
	margin-bottom: spacing.generate(3);
}

.link {
	@include transitions.generate(color, map.get(transitions-settings.$durations, slow));

	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 2);
	text-decoration: none;
	color: colors.$white;

	&:hover,
	&:focus {
		color: colors.$orange;
	}
}

.appWrap {
	order: 1;
	width: 100%;
	text-align: center;

	@include breakpoint.up(sm) {
		order: initial;
		width: auto;
		text-align: left;
	}
}

.appTitle {
	margin-block: spacing.generate(8) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);
	text-align: center;

	@include breakpoint.up(sm) {
		text-align: left;
	}
}

.appLink {
	display: inline-block;
}

.appLink:not(:last-child) {
	margin-right: spacing.generate(2);
	margin-bottom: spacing.generate(2);
}

.storeImage {
	width: auto;
	height: units.px2rem(40px);
}

.socials {
	display: flex;
	flex-flow: row nowrap;
	gap: spacing.generate(5);
	justify-content: space-evenly;

	@include breakpoint.up(md) {
		gap: spacing.generate(6);
	}
}

.socialsLink,
.socialsLink:hover,
.socialsLink:focus {
	display: flex;
	text-decoration: none;
	color: colors.$wine;
}

.socialsLink > svg {
	width: units.px2rem(44px);
	height: units.px2rem(44px);
}

.text > svg {
	position: relative;
	top: units.px2rem(1px);
	color: colors.$red;
	transform: scale(1);

	&:hover {
		animation: pulse 1s infinite;
	}
}

@keyframes pulse {
	10% {
		transform: scale(1.2);
	}
}
