@use 'sass:map';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.root {
	padding-bottom: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	text-align: center;
	border-bottom: units.px2rem(1px) solid colors.$gray-lighter;

	@include breakpoint.up(md) {
		text-align: left;
	}
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(7);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 13);
	line-height: map.get(typography.$line-height-values, 1);
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.box {
	padding-top: spacing.generate(6);
	padding-bottom: spacing.generate(6);
	text-align: center;
}

.code {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 13);
	line-height: map.get(typography.$line-height-values, 2);

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 15);
	}
}
