@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.toggle {
	position: relative;
	display: inline-flex;
	height: units.px2rem(45px);
	border: map.get(borders.$width-values, large) solid colors.$gray-lighter;
	border-radius: units.px2rem(25px);
	box-shadow: 0 units.px2rem(1px) units.px2rem(9px) #{rgba(colors.$black, 0.08)};
}

.toggleVertical {
	display: flex;
	flex-direction: column;
	height: auto;

	@include breakpoint.up(sm) {
		display: inline-flex;
		flex-direction: initial;
		height: units.px2rem(45px);
	}
}

.toggleItem {
	@include button.generate();
	@include transitions.generate('color');

	position: relative;
	z-index: 1;
	padding: spacing.generate(2) spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
	text-transform: none;
	color: colors.$gray-dark;
	background-color: transparent;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$gray-dark;
		opacity: 1;
	}

	&:active:not(:disabled) {
		background-color: transparent;
		transform: scale(1);
	}

	@include breakpoint.up(sm) {
		padding: spacing.generate(2) spacing.generate(4);
	}
}

.toggleItemActive {
	@include transitions.generate('color');

	color: colors.$white;

	&:hover,
	&:focus {
		color: colors.$white;
	}
}

.toggleActive {
	@include button.generate();
	@include transitions.generate('background-color, border-color, transform');

	position: absolute;
	top: units.px2rem(-2px);
	left: units.px2rem(-2px);
	height: units.px2rem(45px);
	padding: 0;
	background-color: colors.$orange;
	transform: translateX(0);

	@include breakpoint.up(sm) {
		padding: 0;
	}
}

.toggleItemInput {
	position: absolute;
	width: 0;
	height: 0;
	margin: 0;
	opacity: 0;
}

.tooltip {
	margin-left: spacing.generate(1);
}
