@use 'sass:map';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.form {
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(8);
	}
}

.inputWrap {
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);

	@include breakpoint.up(md) {
		display: flex;
		grid-gap: spacing.generate(3);
		align-items: flex-start;
		justify-content: center;
	}
}

.input {
	width: units.px2rem(224px);
	margin: 0 auto;

	@include breakpoint.up(md) {
		margin-right: 0;
		margin-left: 0;
	}
}

.label {
	padding-top: spacing.generate(3);
	padding-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);
}
