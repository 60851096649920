@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	margin-bottom: spacing.generate(10);
}

.modalText {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;
}

.modalControlItem {
	margin-bottom: spacing.generate(2);
}

.modalAccount {
	max-width: units.px2rem(420px);
	margin: spacing.generate(6) auto;
}
