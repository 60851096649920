@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use 'variables';

@mixin grid-wrap() {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-between;
	margin-inline: -1 * variables.$layout-grid-offset;
}

@mixin grid-item() {
	flex: 0 1 100%;
	width: 100%;
	max-width: 100%;
	padding-inline: variables.$layout-grid-offset;
}

@mixin section() {
	position: relative;
	clear: both;
	padding: spacing.generate(10) 0 spacing.generate(16) 0;
	margin-top: spacing.generate(6);
	scroll-margin-top: spacing.generate(16);

	@include breakpoint.up(md) {
		scroll-margin-top: 0;
	}
}
