@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: units.px2rem(300px);
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 10);
	line-height: map.get(typography.$line-height-values, 1);
}

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}

.text {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
}

.input {
	display: flex;
	max-width: units.px2rem(250px);
	margin-bottom: spacing.generate(4);
}

.input > div {
	flex-grow: 1;
}

.currency {
	margin-top: spacing.generate(4);
	margin-left: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);

	&:first-child {
		margin-right: spacing.generate(2);
		margin-left: 0;
	}
}

.currencySelect {
	margin-top: spacing.generate(2);
	margin-left: spacing.generate(2);
}

.cancelLink {
	font-weight: map.get(typography.$font-weight-values, semibold);
	white-space: nowrap;
}
