@use 'sass:map';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.subText,
.modalText {
	font-weight: map.get(typography.$font-weight-values, medium);
}

.modalSubText,
.modalListItem,
.semibold {
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.text {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 7);
	}
}

.linkList {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(8);
	align-items: center;
	justify-content: center;
	margin-top: spacing.generate(8);

	@include breakpoint.up(md) {
		flex-flow: row wrap;
	}
}

.linkWrap {
	display: inline-flex;
	flex: 1 0 calc(100% / 3 - #{spacing.generate(8)});
	align-items: center;
	max-width: max-content;
}

.linkWrap > svg {
	flex-shrink: 0;
	width: units.px2rem(30px);
	height: auto;
}

.linkListFew > .linkWrap > svg {
	width: auto;
}

.link {
	padding-top: spacing.generate(2);
	padding-bottom: spacing.generate(2);
	margin-left: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
}

.subText {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.modalText {
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 6);
	}
}

.modalSubText {
	font-size: map.get(typography.$font-size-values, 2);
}

.modalList {
	padding-bottom: spacing.generate(4);
	padding-left: spacing.generate(2);
}

.modalListItem {
	position: relative;
	padding-bottom: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, 2);

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(6px);
		left: units.px2rem(-10px);
		width: units.px2rem(2px);
		height: units.px2rem(2px);
		border-radius: units.px2rem(1px);
		background-color: colors.$black;
	}
}

.offset {
	padding-left: spacing.generate(4);
}

.wrap {
	padding: spacing.generate(4) spacing.generate(6);
	background-color: colors.$gray-lighter;
}
