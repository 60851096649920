@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';
@use '../../../scss/tools/spacing';

.wrap {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(4) spacing.generate(6);
}

.title {
	margin-bottom: spacing.generate(8);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	text-align: center;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.box {
	width: 100%;
	padding: spacing.generate(4);
	border-radius: units.px2rem(20px);
	background-color: colors.$gray-extra-lighter;

	@include breakpoint.up(md) {
		width: calc(50% - #{spacing.generate(4)});
	}
}

.number {
	margin-right: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 12);
	color: colors.$orange;
}

.subtitle {
	display: flex;
	align-items: center;
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
}
