@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
}

.buttonWrap {
	margin: spacing.generate(7) auto;
	text-align: center;

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(18);
	}
}

.description {
	margin-top: spacing.generate(10);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(25);
		font-size: map.get(typography.$font-size-values, 6);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.wrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(3);
	margin-top: spacing.generate(8);

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(16);
	}

	@include breakpoint.up(xl) {
		gap: spacing.generate(6);
	}
}

.content {
	width: 100%;
}

.menu {
	display: none;

	@include breakpoint.up(md) {
		display: block;
	}
}

.menuMobile {
	display: block;

	@include breakpoint.up(md) {
		display: none;
	}
}

.list {
	display: grid;
	grid-template-columns: 1fr;

	@include breakpoint.up(lg) {
		grid-template-columns: 1fr 1fr;
		gap: spacing.generate(5) spacing.generate(3);
	}

	@include breakpoint.up(xl) {
		gap: spacing.generate(10) spacing.generate(4);
	}
}

.list > article:first-child {
	grid-column: 1 / -1;
}
