@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(600px);
	margin-top: spacing.generate(10);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.section {
	margin-bottom: spacing.generate(6);
}

.title {
	margin-top: 0;
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 7);
	text-align: left;
}

.text {
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 2);
	text-align: left;
}

.changeEmail {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(sm) {
		width: auto;
		margin-bottom: 0;
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(2);
	align-items: center;
}
