@use '../mixins';

.root {
	display: block;
}

.image {
	@include mixins.shimmer-animation();

	position: relative;
	width: 100%;
}
