@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	border-bottom: borders.$width solid colors.$gray-lighter;
	background-color: colors.$gray-extra-light;
}

.wrap {
	display: flex;
	gap: spacing.generate(2);
	align-items: center;
	padding: spacing.generate(2);
}

.close {
	padding: 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 12);
	color: colors.$gray;
	border: 0;
	background: 0 0;
	cursor: pointer;
}

.title {
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: map.get(typography.$line-height-values, 0);
}

.small {
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 0);
}

.icon {
	border: borders.$width solid colors.$gray-lighter;
	border-radius: spacing.generate(2);
	background-color: colors.$white;
}

.icon > svg {
	display: block;
	width: auto;
	height: units.px2rem(60px);
	padding: spacing.generate(1);
}

.link {
	margin-right: spacing.generate(3);
	margin-left: auto;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$red;
}
