@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: units.px2rem(300px);
}

.title {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(34px);
	line-height: 1.4;
}

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(18px);
}

.text {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-weight: 500;
	font-size: units.px2rem(14px);
	line-height: 1.4;
}

.note {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-size: units.px2rem(13px);
	line-height: 1.4;
}

.list {
	padding-left: spacing.generate(2);
}

.listItem {
	position: relative;
	padding-left: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(7px);
		left: 0;
		width: units.px2rem(2px);
		height: units.px2rem(4px);
		border-radius: units.px2rem(1px);
		background-color: colors.$orange;
	}
}

.input {
	display: flex;
	max-width: units.px2rem(250px);
	margin-bottom: spacing.generate(4);
}

.input > div {
	flex-grow: 1;
}

.currency {
	margin-top: spacing.generate(4);
	margin-left: spacing.generate(2);
	font-weight: 700;
	font-size: units.px2rem(20px);

	&:first-child {
		margin-right: spacing.generate(2);
		margin-left: 0;
	}
}

.currencySelect {
	margin-top: spacing.generate(2);
	margin-left: spacing.generate(2);
}
