@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';

.title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);
}

.text {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modal {
	text-align: center;
}

.modalFooter {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(2);
	justify-content: center;
}

.icon {
	margin-block: spacing.generate(5) spacing.generate(2);
}
