@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/transitions' as transitions-settings;
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
}

.modalControl {
	max-width: units.px2rem(545px);
	margin-inline: auto;
}

.wrap {
	display: flex;
	flex-direction: row;
	gap: spacing.generate(2);
	justify-content: center;
	max-width: units.px2rem(365px);
	margin: auto;
}

.offset {
	margin-top: units.px2rem(24px);
}

.subtitle {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
}

.row {
	display: flex;
	gap: spacing.generate(1);
	padding-bottom: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 2);
}

.result {
	margin-top: spacing.generate(6);
}

.box {
	padding: spacing.generate(5);
	background-color: colors.$gray-extra-lighter;
}

.confirm {
	text-align: center;
}
