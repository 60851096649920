@use 'sass:color';
@use 'sass:map';
@use '../settings/colors';
@use 'units';

@mixin generate() {
	&::-webkit-scrollbar {
		width: units.px2rem(12px);
		height: units.px2rem(11px);
		border-radius: units.px2rem(10px);
		background-color: colors.$gray-lighter;
	}

	&::-webkit-scrollbar-thumb {
		width: 50%;
		border-radius: units.px2rem(10px);
		background: colors.$orange;
		box-shadow: inset 0 0 units.px2rem(6px) color.adjust(colors.$black, $alpha: -0.5);
	}

	&::-webkit-scrollbar-track {
		border-radius: units.px2rem(10px);
		box-shadow: inset 0 0 units.px2rem(6px) color.adjust(colors.$black, $alpha: -0.7);
	}
}
