@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.done {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-size: units.px2rem(15px);
	line-height: 1.5;
}

.text {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-size: units.px2rem(15px);
	line-height: 1.5;
}

.statusList {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: spacing.generate(4);
}

.statusItem {
	position: relative;
	display: flex;
	margin: units.px2rem(2px);
	border-radius: 50%;
}

.statusInput {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.statusNumber {
	@include transitions.generate('color, border-color, background-color, transform');

	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(26px);
	height: units.px2rem(25px);
	font-weight: bold;
	font-size: units.px2rem(16px);
	text-transform: uppercase;
	color: colors.$wine;
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: 50%;
	background-color: transparent;
	outline: none;
	cursor: pointer;

	&:hover,
	&:focus {
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}

	@include breakpoint.up(sm) {
		width: units.px2rem(50px);
		height: units.px2rem(50px);
		font-size: units.px2rem(18px);
	}
}

.statusInput:checked + .statusNumber {
	color: colors.$white;
	border-color: colors.$white;
	background-color: colors.$red;
}

.statusInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing.generate(2) 0;
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(sm) {
		padding: spacing.generate(2) spacing.generate(6);
	}
}
