@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/button';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/transitions';
@use '../../../../scss/tools/units';

.tab {
	@include transitions.generate(color);

	position: relative;
	padding: spacing.generate(2) spacing.generate(4);
	margin: 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	border: 0;
	background: none;
	cursor: pointer;

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 5);
	}

	&::after {
		@include transitions.generate(border-bottom-color);

		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: map.get(borders.$width-values, extra-large) solid transparent;
	}

	&:hover,
	&:focus {
		&::after {
			border-bottom-color: colors.$gray-light;
		}
	}

	&:focus-visible {
		outline-width: 0;
	}
}

.tabActive {
	&::after {
		border-bottom: map.get(borders.$width-values, extra-large) solid;
		border-bottom-color: inherit;
	}

	&:hover,
	&:focus {
		&::after {
			border-bottom-color: inherit;
		}
	}
}

.text {
	max-width: units.px2rem(710px);
	padding-right: spacing.generate(2);
	padding-left: spacing.generate(2);
	margin: spacing.generate(4) auto;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(lg) {
		font-weight: map.get(typography.$font-weight-values, regular);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.hidden {
	display: none;
}

.slider {
	padding-right: units.px2rem(1px);
	padding-left: units.px2rem(1px);

	@include breakpoint.up(xl) {
		padding-right: 0;
		padding-left: 0;
	}

	&::before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: borders.$width solid colors.$gray-light;

		@include breakpoint.up(xl) {
			right: units.px2rem(18px);
			left: units.px2rem(18px);
		}
	}
}

.counterWrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.counterInput {
	max-width: units.px2rem(70px);
}

.counter {
	@include transitions.generate(box-shadow);

	position: relative;
	width: units.px2rem(44px);
	height: units.px2rem(44px);
	margin-bottom: units.px2rem(16px);
	font-size: 0;
	border: map.get(borders.$width-values, large) solid colors.$gray-lighter;
	border-radius: units.px2rem(22px);
	background-color: colors.$white;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: units.px2rem(16px);
		border-width: 0 0 map.get(borders.$width-values, large) 0;
		border-style: solid;
		border-color: colors.$orange;
		transform: translate(-50%, -50%);
	}
}

.counter:hover,
.counter:focus {
	box-shadow: 0 0 units.px2rem(20px) 0 colors.$gray-light;
}

.counterIncrement {
	margin-left: spacing.generate(2);

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: units.px2rem(16px);
		border-width: 0 0 map.get(borders.$width-values, large) 0;
		border-style: solid;
		border-color: colors.$orange;
		transform: translate(-50%, -50%) rotate(-90deg);
	}
}

.counterDecrement {
	margin-right: spacing.generate(2);
}

.counter:disabled {
	@include button.disabled();
}
