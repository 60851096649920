@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: 0;

	@include breakpoint.up(md) {
		min-height: units.px2rem(700px);
	}
}

.title,
.subtitle,
.text {
	position: relative;
	z-index: 1;
}

.title,
.subtitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	line-height: map.get(typography.$line-height-values, -1);
	text-transform: uppercase;
}

.title {
	max-width: units.px2rem(250px);
	margin: 0 auto spacing.generate(5) auto;
	font-size: map.get(typography.$font-size-values, 14);
	text-align: center;
	color: colors.$wine;

	@include breakpoint.up(sm) {
		max-width: units.px2rem(450px);
		font-size: map.get(typography.$font-size-values, 15);
	}

	@include breakpoint.up(md) {
		max-width: 100%;
		font-size: map.get(typography.$font-size-values, 17);
	}
}

.subtitle {
	margin-bottom: spacing.generate(8);
	font-size: map.get(typography.$font-size-values, 11);
	color: colors.$orange;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 13);
	}

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 15);
	}
}

.text {
	max-width: units.px2rem(940px);
	margin: 0 auto;
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: 1.3;
	color: colors.$wine;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.bubbles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bubble {
	position: absolute;
	display: block;
	border-radius: 50%;
	opacity: 0.9;
}

.bubble1 {
	top: 9vw;
	left: 2vw;
	width: 14vw;
	height: 14vw;
	background-color: colors.$red;

	@include breakpoint.up(md) {
		top: 13vw;
		left: 6vw;
		width: 10vw;
		height: 10vw;
	}
}

.bubble2 {
	top: -23vw;
	left: -30vw;
	width: 44vw;
	height: 44vw;
	background-color: colors.$wine;

	@include breakpoint.up(md) {
		top: -7vw;
		left: -10vw;
		width: 25vw;
		height: 25vw;
	}
}

.bubble3 {
	right: -11vw;
	bottom: -12vw;
	width: 24vw;
	height: 24vw;
	background-color: colors.$orange;

	@include breakpoint.up(md) {
		bottom: -6vw;
		width: 30vw;
		height: 30vw;
	}
}
