@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/scrollbar';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	text-align: center;
}

.modal {
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	@include breakpoint.up(lg) {
		width: 75%;
	}
}

.modalText {
	margin-bottom: spacing.generate(8);
}

.tableWrap {
	@include scrollbar.generate();

	width: 100%;
	min-height: 0.01%;
	overflow: auto hidden;
	border: 0;

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}

	@include breakpoint.up(md) {
		overflow: visible;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	white-space: nowrap;
}

.table th {
	padding: spacing.generate(3) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	vertical-align: bottom;

	&:nth-child(1) {
		width: units.px2rem(245px);
	}

	&:nth-child(2) {
		width: units.px2rem(245px);
	}
}

.table td {
	padding: spacing.generate(2) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	vertical-align: middle;
	border-top: borders.$width solid colors.$gray-lighter;

	&:nth-child(1) {
		max-width: units.px2rem(245px);
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:nth-child(2) {
		max-width: units.px2rem(245px);

		@include breakpoint.up(md) {
			width: unset;
			max-width: unset;
		}
	}
}

.table > thead > tr {
	border-bottom-color: colors.$gray-lighter;
}

.table > tbody {
	border-bottom: map.get(borders.$width-values, large) solid colors.$gray-lighter;
}

.right.right {
	text-align: right;
}
