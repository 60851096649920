@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

@mixin generate() {
	@include transitions.generate('opacity, transform');

	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(2) spacing.generate(4);
	margin-bottom: 0;
	touch-action: manipulation;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
	font-family: inherit;
	text-align: center;
	vertical-align: middle;
	color: colors.$white;
	border: 0;
	border-radius: units.px2rem(25px);
	background-image: none;
	background-color: colors.$red;
	transform: scale(1);
	user-select: none;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$white;
		opacity: 0.9;
	}

	&:active:not(:disabled) {
		color: colors.$white;
		background-color: colors.$red-dark;
		transform: scale(0.95);
	}

	@include breakpoint.up(sm) {
		white-space: nowrap;
	}
}

@mixin disabled() {
	opacity: 0.65;
	cursor: not-allowed;
}

@mixin svg() {
	width: auto;
	height: 1em;
	margin-right: 0.2em;
}

@mixin narrow() {
	padding: spacing.generate(2) spacing.generate(6);
}

@mixin outline() {
	@include transitions.generate('color, background-color, transform');

	padding: spacing.generate(2) spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	text-decoration: none;
	color: colors.$wine;
	border: borders.$width solid colors.$gray-light;
	border-radius: units.px2rem(25px);
	background-color: transparent;

	&:hover,
	&:focus {
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active:not(:disabled) {
		color: colors.$white;
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}

@mixin outline-svg() {
	margin-right: spacing.generate(4);
	fill: colors.$orange;
}

@mixin flat() {
	padding: spacing.generate(1) spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 2);
}

@mixin secondary() {
	text-decoration: none;
	background-color: colors.$orange;

	&:active {
		background-color: colors.$orange;
	}
}

@mixin small() {
	padding: spacing.generate(1) spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 2);
}

@mixin small-outline() {
	padding: calc(#{spacing.generate(1)} - #{units.px2rem(2px)}) spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 2);
}

@mixin reset() {
	white-space: normal;
	border: 0;
	border-radius: 0;
	background-image: none;
	background-color: transparent;

	&:active {
		color: initial;
		background-color: initial;
		transform: none;
	}
}

@mixin long() {
	white-space: normal;
}

@mixin outline-heavy() {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: units.px2rem(36px);
	background-image: none;
	background-color: transparent;

	&:hover,
	&:focus {
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}
