@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;
	margin-bottom: spacing.generate(5);
}

.row {
	display: flex;
	flex-flow: row wrap;
	gap: spacing.generate(2);

	@include breakpoint.up(sm) {
		gap: spacing.generate(3) spacing.generate(4);
		justify-content: space-between;
	}
}

.title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: left;
	text-transform: uppercase;
}

.button {
	@include transitions.generate(box-shadow);

	position: relative;
	display: flex;
	align-items: center;
	width: calc(100% / 2 - #{units.px2rem(5px)});
	padding: spacing.generate(2) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$black;
	border: borders.$width solid colors.$black;
	border-radius: units.px2rem(25px);
	background: colors.$white;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$orange;
	}

	@include breakpoint.up(sm) {
		flex-direction: row;
		width: calc(100% / 2 - #{units.px2rem(10px)});
		margin-bottom: 0;
	}
}

.icon {
	display: block;
	width: units.px2rem(18px);
	height: units.px2rem(18px);
	margin-right: spacing.generate(2);
	border-radius: 50%;
}

.iconFlat {
	box-shadow: none;
}

.icon > svg {
	width: 100%;
	height: 100%;
}

.line {
	position: relative;
	margin-top: units.px2rem(30px);
	font-size: units.px2rem(14px);
	text-align: center;

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(7px);
		left: 0;
		display: block;
		width: 100%;
		height: units.px2rem(1px);
		background-color: colors.$gray-lighter;
	}
}

.lineTop {
	order: -1;
	margin-bottom: units.px2rem(30px);
}

.line > span {
	position: relative;
	z-index: 1;
	display: inline;
	padding: spacing.generate(2);
	background-color: colors.$white;
}
