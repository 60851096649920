@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.text {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(24px);
	line-height: 1.4;
}
