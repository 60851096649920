@use '../../../scss/settings/colors';
@use '../../../scss/settings/header';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';

$_circle-size: units.px2rem(10px);

.wrap {
	min-height: calc(100vh - #{header.$height-mobile});

	@include breakpoint.up(md) {
		min-height: calc(100vh - #{header.$height});
	}
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.root {
	position: relative;
	display: block;
	width: 1em;
	height: 1em;
	margin: ($_circle-size * 5) auto;
	font-size: $_circle-size;
	color: colors.$orange;
	border-radius: 50%;
	transform: translateZ(0);
	animation: loader 1.3s infinite linear;
	text-indent: -9999em;
}

.large {
	margin: ($_circle-size * 12) auto;
}

@keyframes loader {
	0%,
	100% {
		box-shadow:
			0 -3em 0 0.2em,
			2em -2em 0 0,
			3em 0 0 -1em,
			2em 2em 0 -1em,
			0 3em 0 -1em,
			-2em 2em 0 -1em,
			-3em 0 0 -1em,
			-2em -2em 0 0;
	}

	12.5% {
		box-shadow:
			0 -3em 0 0,
			2em -2em 0 0.2em,
			3em 0 0 0,
			2em 2em 0 -1em,
			0 3em 0 -1em,
			-2em 2em 0 -1em,
			-3em 0 0 -1em,
			-2em -2em 0 -1em;
	}

	25% {
		box-shadow:
			0 -3em 0 -0.5em,
			2em -2em 0 0,
			3em 0 0 0.2em,
			2em 2em 0 0,
			0 3em 0 -1em,
			-2em 2em 0 -1em,
			-3em 0 0 -1em,
			-2em -2em 0 -1em;
	}

	37.5% {
		box-shadow:
			0 -3em 0 -1em,
			2em -2em 0 -1em,
			3em 0 0 0,
			2em 2em 0 0.2em,
			0 3em 0 0,
			-2em 2em 0 -1em,
			-3em 0 0 -1em,
			-2em -2em 0 -1em;
	}

	50% {
		box-shadow:
			0 -3em 0 -1em,
			2em -2em 0 -1em,
			3em 0 0 -1em,
			2em 2em 0 0,
			0 3em 0 0.2em,
			-2em 2em 0 0,
			-3em 0 0 -1em,
			-2em -2em 0 -1em;
	}

	62.5% {
		box-shadow:
			0 -3em 0 -1em,
			2em -2em 0 -1em,
			3em 0 0 -1em,
			2em 2em 0 -1em,
			0 3em 0 0,
			-2em 2em 0 0.2em,
			-3em 0 0 0,
			-2em -2em 0 -1em;
	}

	75% {
		box-shadow:
			0 -3em 0 -1em,
			2em -2em 0 -1em,
			3em 0 0 -1em,
			2em 2em 0 -1em,
			0 3em 0 -1em,
			-2em 2em 0 0,
			-3em 0 0 0.2em,
			-2em -2em 0 0;
	}

	87.5% {
		box-shadow:
			0 -3em 0 0,
			2em -2em 0 -1em,
			3em 0 0 -1em,
			2em 2em 0 -1em,
			0 3em 0 -1em,
			-2em 2em 0 0,
			-3em 0 0 0,
			-2em -2em 0 0.2em;
	}
}
