@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';
@use '../variables';

.root {
	width: 100%;
	padding-right: variables.$layout-container-offset;
	padding-left: variables.$layout-container-offset;
	margin-right: auto;
	margin-left: auto;
}

.default {
	@include breakpoint.up(md) {
		max-width: units.px2rem(750px);
	}

	@include breakpoint.up(lg) {
		max-width: units.px2rem(970px);
	}

	@include breakpoint.up(xl) {
		max-width: units.px2rem(1170px);
	}

	@include breakpoint.up(xxl) {
		max-width: units.px2rem(1440px);
	}
}

.narrow {
	max-width: units.px2rem(1032px);
}

.narrower {
	max-width: units.px2rem(688px);
}

.center {
	text-align: center;
}
