@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';

.root {
	margin-bottom: spacing.generate(5);

	@include breakpoint.up(lg) {
		margin-bottom: 0;
	}
}
