@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-top: spacing.generate(10);
	margin-bottom: spacing.generate(10);
}

.download {
	@include transitions.generate('color, background-color, transform');

	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	width: auto;
	padding: units.px2rem(12px) spacing.generate(4);
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	color: colors.$wine;
	border: map.get(borders.$width-values, large) solid colors.$wine;
	border-radius: units.px2rem(50px);
	background-color: transparent;
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}

.download > svg {
	width: units.px2rem(22px);
	margin-right: spacing.generate(2);
}

.text {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 0);
}

.smsText {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 0);
}

.smsBlocked {
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	text-align: left;
	color: colors.$error;
}

.check {
	margin-top: spacing.generate(4);
}

.submit {
	margin-top: spacing.generate(6);
}

.hidden,
.visible {
	@include transitions.generate('visibility, opacity, height');
}

.hidden {
	height: 0;
	visibility: hidden;
	opacity: 0;
}

.visible {
	visibility: visible;
	opacity: 1;
}

.textCenter {
	text-align: center;
}

.documentWrap {
	margin-top: spacing.generate(4);
}

.downloadWrap {
	margin-top: spacing.generate(4);
}
