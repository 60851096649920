@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(4);
}

.disabled {
	opacity: 0.5;
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.helper {
	flex-grow: 1;
	margin-left: spacing.generate(4);
	font-weight: normal;
	font-size: units.px2rem(14px);
	text-align: right;
}

.inputWrap {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.select {
	width: 100%;
	max-width: calc(100% / 12 * 4 - #{units.px2rem(5px)});

	@include breakpoint.up(sm) {
		max-width: calc(100% / 12 * 4 - #{units.px2rem(15px)});
	}
}

.input {
	@include transitions.generate(box-shadow);

	appearance: none;
	display: block;
	width: 100%;
	max-width: calc(100% / 12 * 8 - #{units.px2rem(5px)});
	height: units.px2rem(56px);
	padding: units.px2rem(6px) units.px2rem(12px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(35px);
	font-family: inherit;
	color: colors.$gray-dark;
	border: 0;
	border-radius: units.px2rem(8px);
	background: colors.$gray-lighter;
	box-shadow: inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%);

	&:focus {
		outline: 0;
		box-shadow:
			inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
			0 0 units.px2rem(8px) rgb(102 175 233 / 60%);
	}

	@include breakpoint.up(sm) {
		max-width: calc(100% / 12 * 8 - #{units.px2rem(15px)});
	}
}

.error,
.warning {
	display: block;
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	text-align: left;
}

.error {
	color: colors.$error;
}

.warning {
	color: colors.$orange;
}

.rootError > .inputWrap > .input:focus {
	box-shadow:
		inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
		0 0 units.px2rem(6px) colors.$pink;
}

.passwordToggle {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(46px);
	padding: 0;
	font-size: 0;
	border: 0;
	border-radius: units.px2rem(8px);
	background: transparent;
	outline: 0;
}
