@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	@include transitions.generate('color, background-color, transform');

	display: inline-flex;
	grid-gap: spacing.generate(1);
	align-items: center;
	justify-content: space-around;
	min-width: units.px2rem(220px);
	min-height: units.px2rem(48px);
	padding: spacing.generate(2) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
	text-align: center;
	text-transform: uppercase;
	white-space: pre-wrap;
	vertical-align: middle;
	color: colors.$wine;
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: units.px2rem(36px);
	background-image: none;
	background-color: transparent;
	transform: scale(1);
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}

.root.small {
	min-width: unset;
	min-height: unset;
	padding: spacing.generate(1) spacing.generate(3);
	margin-right: spacing.generate(4);
}

.root > svg {
	color: colors.$orange;
}

.modalClose {
	text-align: center;
}
