@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/transitions' as transitions-settings;
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	font-weight: inherit;
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: center;
}

.body {
	margin-bottom: spacing.generate(9);
}

.sectionPart {
	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(4);
	}
}

.section,
:last-child > .sectionPart {
	margin-bottom: spacing.generate(4);
}

.sectionTitle {
	width: 100%;
	text-transform: uppercase;
}

.item.item {
	@include transitions.generate(
		'background-color, box-shadow',
		map.get(transitions-settings.$durations, slow)
	);

	padding: spacing.generate(2) spacing.generate(4) spacing.generate(2) spacing.generate(2);
	margin-bottom: 0;
	color: colors.$gray-darker;
	border-radius: units.px2rem(10px);
	background-color: transparent;
	box-shadow: none;
}

.item.item.isItemActive {
	margin-bottom: spacing.generate(4);
	background-color: colors.$white;
	box-shadow: 0 0 units.px2rem(20px) 0 colors.$gray-light;
}

.collapse {
	@include transitions.generate(max-height);

	max-height: 0;
	overflow-y: hidden;
}

.collapseTrigger.collapseTrigger {
	display: flex;
	padding: 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: inherit;
	font-family: inherit;
	text-align: left;
	text-decoration: none;
	color: colors.$wine;
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.collapse.isCollapseActive {
	transition-timing-function: ease-in;
}

.icon.icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(26px);
	height: units.px2rem(26px);
	margin-top: units.px2rem(-2px);
	margin-right: spacing.generate(3);
	color: colors.$orange;
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: 50%;
	transform: translateZ(0);
}

.itemTitle {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.itemTitleText {
	width: calc(100% - #{units.px2rem(26px)} - #{units.px2rem(15px)});
}

.itemBody {
	padding: spacing.generate(2) spacing.generate(1) spacing.generate(1) spacing.generate(9);
}

/* stylelint-disable selector-max-compound-selectors */
.itemBody > ul > li,
.itemBody > ul > li > ul > li {
	position: relative;
	padding-left: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(6px);
		left: 0;
		width: units.px2rem(2px);
		height: units.px2rem(3px);
		border-radius: units.px2rem(1px);
		background-color: colors.$black;
	}
}

.itemBody > ul > li > ul {
	margin-top: spacing.generate(2);
}

.itemBodySpacy {
	line-height: map.get(typography.$line-height-values, 0);
}

.searchWrap {
	max-width: units.px2rem(915px);
	margin-inline: auto;
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(15);
	}
}
