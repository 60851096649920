@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
}

.inputsGrid {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(6);
	justify-content: space-between;
	margin-bottom: spacing.generate(8);

	@include breakpoint.up(sm) {
		flex-wrap: nowrap;
	}
}

.portfolio,
.currency {
	width: 100%;
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(sm) {
		width: auto;
	}
}

.qr {
	padding: spacing.generate(4) 0;
	margin: spacing.generate(4) spacing.generate(4) spacing.generate(8);
	text-align: center;
	border-radius: units.px2rem(8px);
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$black, $alpha: -0.9);
}

.qrTitle {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
}

.qrImage {
	width: 60%;
	margin: spacing.generate(3) auto;
}

.payment {
	margin-bottom: spacing.generate(8);
	white-space: normal;
}

.paymentTitle {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}

.accountTitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 0);
	text-transform: uppercase;
}

.accountNumber {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	text-transform: uppercase;
	color: colors.$orange;

	&:lang(pl) {
		font-size: map.get(typography.$font-size-values, 6);

		@include breakpoint.up(sm) {
			font-size: map.get(typography.$font-size-values, 10);
		}
	}
}

.accountInfo {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 0);
}

.accountInternational {
	display: flex;
	flex-wrap: wrap;
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
}

.accountInternationalItem {
	margin-right: spacing.generate(4);
	margin-bottom: spacing.generate(2);
}

.accountInternationalTitle {
	font-size: map.get(typography.$font-size-values, -3);
}

.accountInternationalNumber {
	font-size: map.get(typography.$font-size-values, 4);
}

.note {
	margin-bottom: spacing.generate(3);
}

.noteTitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, -1);
	text-transform: uppercase;
}

.noteContent {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$orange;
}

.address {
	margin-bottom: spacing.generate(4);
}

.addressItem {
	font-size: map.get(typography.$font-size-values, 1);
}

.warning {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
}

.warning > svg {
	position: relative;
	top: units.px2rem(1px);
	height: 1em;
	margin-right: spacing.generate(2);
	line-height: map.get(typography.$line-height-values, -1);
	color: colors.$orange;
}

.wrap {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(2);
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(sm) {
		gap: spacing.generate(6);
	}
}

.copyButton {
	width: units.px2rem(28px);
	height: units.px2rem(28px);
	padding: 0;
	margin-left: spacing.generate(3);
	vertical-align: middle;
	color: colors.$wine;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	&:hover {
		color: colors.$wine-light;
	}

	&:active {
		transform: scale(0.95);
	}
}

.copyButton > svg {
	width: units.px2rem(28px);
	height: units.px2rem(28px);
}

.small {
	width: units.px2rem(18px);
	height: units.px2rem(18px);
	margin-left: spacing.generate(2);
	vertical-align: middle;
}

.small > svg {
	width: units.px2rem(18px);
	height: units.px2rem(18px);
}

.badgeWrap {
	display: inline-flex;
	flex-direction: column;
	margin-right: spacing.generate(2);
}

.badgeWrap span {
	margin-bottom: spacing.generate(1);
}

.badgeWrap span:last-child {
	margin-bottom: 0;
}

/* stylelint-disable selector-class-pattern */
.portfolio :global .selectField__single-value {
	display: flex;
	align-items: center;
}

.accountWrap {
	display: block;
}
