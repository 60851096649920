@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);
}

.text {
	margin-bottom: spacing.generate(12);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.button {
	margin-top: spacing.generate(10);
}

.modal {
	text-align: center;
}

.modalText,
.modalLinks {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalText {
	margin-bottom: spacing.generate(4);

	&:last-of-type {
		margin-bottom: spacing.generate(8);
	}
}

.modalLinks {
	margin-top: spacing.generate(6);
}

.modalButtons {
	margin-top: spacing.generate(4);
}

.modalFooter {
	margin-top: spacing.generate(9);
}
