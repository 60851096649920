@use 'sass:string';
@use '../settings/transitions';

@mixin generate(
	$property,
	$transition-duration: transitions.$duration,
	$delay: 0ms
) {
	transition: string.unquote($property) transitions.$duration transitions.$timing-function $delay;
}
