@use 'sass:math';
@use '../settings/layout';
@use '../tools/breakpoint';
@use '../tools/spacing';

@mixin container() {
	padding-inline: spacing.generate(3);
	margin-inline: auto;

	@include breakpoint.up(md) {
		width: layout.$container-width-md;
	}

	@include breakpoint.up(lg) {
		width: layout.$container-width-lg;
	}

	@include breakpoint.up(xl) {
		width: layout.$container-width-xl;
	}

	@include breakpoint.up(xxl) {
		width: layout.$container-width-xxl;
	}
}

@mixin grid-part($i) {
	@if $i > 0 {
		flex-basis: math.div(100%, layout.$layout-grid-columns) * $i;
		width: math.div(100%, layout.$layout-grid-columns) * $i;
		max-width: math.div(100%, layout.$layout-grid-columns) * $i;
	} @else {
		flex-basis: 0;
		width: 0;
		max-width: 0;
		padding-inline: 0;
	}
}
