@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	margin-top: spacing.generate(4);
}

.section {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text {
	max-width: units.px2rem(500px);
	margin-block: spacing.generate(1) spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
	text-align: center;
}
