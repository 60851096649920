@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-inline: spacing.generate(4);
	border-top: units.px2rem(1px) solid colors.$gray-extra-light;
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.lastFill {
	margin-bottom: spacing.generate(1);
}

.lastFill span {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
}

.possibleFill {
	margin-bottom: spacing.generate(3);
}

.possibleFill span {
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.dateIcon {
	display: inline-block;
	margin-left: spacing.generate(1);
}

.triggerButton {
	text-align: center;
}

.modal {
	text-align: center;
}

.modal p {
	margin-bottom: spacing.generate(4);
}
