@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: block;
	padding: spacing.generate(6) 0;
	border-radius: units.px2rem(8px);
	box-shadow: 0 units.px2rem(2px) units.px2rem(35px) 0 color.adjust(colors.$black, $alpha: -0.9);
}
