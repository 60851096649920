@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-inline: spacing.generate(3);
	padding-bottom: spacing.generate(6);
	margin-bottom: spacing.generate(6);
	border-bottom: borders.$width solid colors.$gray-lightest;
}

.header {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-left: spacing.generate(10);
	margin-bottom: spacing.generate(6);

	@include breakpoint.up(md) {
		padding-left: spacing.generate(16);
	}
}

.title {
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 9);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 13);
	}
}

.icon {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(30px);
	height: units.px2rem(30px);
	border-radius: 50%;
	background: colors.$white;
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(md) {
		width: units.px2rem(50px);
		height: units.px2rem(50px);
	}
}

.icon > svg {
	width: units.px2rem(18px);
	height: units.px2rem(18px);

	@include breakpoint.up(md) {
		width: units.px2rem(30px);
		height: units.px2rem(30px);
	}
}

.checks {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(1);
	width: 100%;

	@include breakpoint.up(sm) {
		flex-direction: row;
		gap: spacing.generate(8);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
}

.date {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
}

.wrap {
	@include breakpoint.up(md) {
		margin-left: spacing.generate(16);
	}
}
