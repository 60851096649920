@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-top: spacing.generate(6);
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, 0);
	text-transform: uppercase;
	color: colors.$wine;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
		line-height: map.get(typography.$line-height-values, -1);
	}

	@include breakpoint.up(lg) {
		margin-top: 0;
		margin-bottom: spacing.generate(6);
		text-align: left;
	}
}

.description {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 8);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 12);
	}

	@include breakpoint.up(lg) {
		margin-bottom: 0;
		text-align: left;
	}
}

.descriptionSemibold {
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.subtitle {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 8);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, bold);
		font-size: map.get(typography.$font-size-values, 9);
	}

	@include breakpoint.up(lg) {
		text-align: left;
	}
}

.list {
	padding-left: spacing.generate(4);
	text-align: left;
}

.listItem:not(:last-child) {
	margin-bottom: spacing.generate(3);
}

.listTitle {
	position: relative;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 6);
	}

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: units.px2rem(-20px);
		width: units.px2rem(10px);
		height: units.px2rem(10px);
		border-radius: units.px2rem(5px);
		background-color: colors.$red;
		transform: translateY(-50%);
	}
}

.listText {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, regular);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.cta {
	margin-top: spacing.generate(6);

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(16);
		margin-bottom: spacing.generate(6);
	}
}

.wrap {
	@include breakpoint.up(lg) {
		padding-top: spacing.generate(3);
		padding-bottom: spacing.generate(3);
		margin-top: spacing.generate(6);
	}
}

.wrapBorder {
	padding-top: spacing.generate(5);
	border-top: borders.$width solid colors.$gray-light;

	@include breakpoint.up(lg) {
		padding-top: spacing.generate(3);
		padding-left: spacing.generate(12);
		border-top: 0;
		border-left: borders.$width solid colors.$gray-light;
	}
}
