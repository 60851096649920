@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(538px);
	margin: 0 auto;
}

.title {
	margin-bottom: spacing.generate(2);
	font-weight: 700;
	font-size: units.px2rem(22px);
	line-height: 1.1;

	@include breakpoint.up(md) {
		font-size: units.px2rem(35px);
	}

	@include breakpoint.up(lg) {
		font-size: units.px2rem(45px);
	}
}

.linkButton {
	@include transitions.generate(text-decoration-color);

	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(10);
	font-weight: 700;
	font-size: units.px2rem(16px);
	text-decoration: underline;
	text-decoration-color: color.adjust(colors.$red, $alpha: -0.5);
	color: colors.$red;
	border-width: 0;
	background-color: transparent;
	outline: 0;

	&:hover,
	&:focus {
		text-decoration-color: colors.$red;
		color: colors.$red;
	}

	@include breakpoint.up(md) {
		font-size: units.px2rem(18px);
	}
}

.paragraph,
.enumeration,
.result {
	margin-bottom: spacing.generate(3);
}

.paragraph {
	font-weight: 500;
	font-size: units.px2rem(14px);
	line-height: 1.4;

	@include breakpoint.up(md) {
		font-size: units.px2rem(20px);
	}
}

.enumeration {
	font-weight: 700;
	color: colors.$orange;

	@include breakpoint.up(md) {
		font-size: units.px2rem(25px);
	}
}

.result {
	display: block;
	margin-top: spacing.generate(3);
	font-weight: 700;
	font-size: units.px2rem(30px);

	@include breakpoint.up(md) {
		font-size: units.px2rem(40px);
	}
}

.buttonGroup {
	display: flex;
	flex-direction: column;
	align-items: center;
}
