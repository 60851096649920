@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.box {
	padding: spacing.generate(8) spacing.generate(4);
	margin: spacing.generate(6) spacing.generate(4) spacing.generate(4);
	text-align: center;
	border-radius: spacing.generate(2);
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(sm) {
		padding: spacing.generate(8);
	}
}

.stepContent {
	position: relative;
	max-width: 600px;
	margin-top: spacing.generate(3);

	@include breakpoint.up(lg) {
		margin-left: calc(17% + #{spacing.generate(6)});
	}
}

.stepContent > p {
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 1);
}

.subStep {
	display: flex;
	align-items: center;
	margin-bottom: spacing.generate(4);
}

.subStepTitle {
	margin-bottom: 0;
	margin-left: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.subStepIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(30px);
	height: units.px2rem(30px);
	border-radius: 50%;
	background: colors.$white;
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(sm) {
		width: units.px2rem(50px);
		height: units.px2rem(50px);
	}
}

.subStepIcon > svg {
	width: units.px2rem(18px);
	height: units.px2rem(18px);

	@include breakpoint.up(sm) {
		width: units.px2rem(30px);
		height: units.px2rem(30px);
	}
}

.modal {
	text-align: center;
}

.modalIcon {
	margin-top: spacing.generate(10);
	margin-bottom: spacing.generate(6);
	color: colors.$orange;
}

.modalIcon > svg {
	height: units.px2rem(30px);
}

.modalTitle,
.modalText,
.modalLinks {
	padding-right: spacing.generate(2);
	padding-left: spacing.generate(2);
	margin-bottom: spacing.generate(8);
	font-size: units.px2rem(18px);

	@include breakpoint.up(lg) {
		padding-right: spacing.generate(8);
		padding-left: spacing.generate(8);
	}
}

.modalTextSmall {
	font-size: map.get(typography.$font-size-values, 0);
}

.modalTitle {
	font-weight: 600;
	font-size: units.px2rem(36px);
}

.modalText {
	font-weight: 500;
}

.smallOffset {
	margin-bottom: spacing.generate(5);
}

.modalFooter {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(2);
	justify-content: center;
}
