@use 'sass:map';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';

.root {
	padding-inline: spacing.generate(3);
	margin-inline: spacing.generate(-3);

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(4);
		margin-inline: spacing.generate(-4);
	}
}

.text {
	padding-left: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 0);

	&:not(:last-child) {
		margin-bottom: spacing.generate(2);
	}
}
