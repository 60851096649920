@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.root {
	padding: spacing.generate(4) spacing.generate(3);
	border-bottom: borders.$width solid colors.$gray-lighter;

	@include breakpoint.up(md) {
		padding: spacing.generate(3) 0;
	}
}

.inputWrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	@include breakpoint.up(md) {
		gap: spacing.generate(8);
	}
}

.portfolioName {
	display: flex;
	gap: spacing.generate(2);
	align-items: center;
	order: 1;

	@include breakpoint.up(md) {
		order: initial;
	}
}

.portfolioNameText {
	overflow: hidden;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 0);
	text-overflow: ellipsis;

	@include breakpoint.up(xs) {
		max-width: units.px2rem(115px);
	}

	@include breakpoint.up(sm) {
		max-width: none;
	}

	@include breakpoint.up(md) {
		max-width: units.px2rem(88px);
	}

	@include breakpoint.up(lg) {
		max-width: units.px2rem(120px);
	}
}

.customButton {
	order: 2;

	@include breakpoint.up(md) {
		order: initial;
	}
}

.modalText,
.modalLinks {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;
}

.modalClose {
	margin-top: spacing.generate(4);
	text-transform: uppercase;
}

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(4);

	&:last-of-type {
		margin-bottom: spacing.generate(8);
	}
}

.modalLinks {
	margin-top: spacing.generate(6);
}

.modalButtons {
	margin-top: spacing.generate(4);
}

.modalFooter {
	margin-top: spacing.generate(9);
}

.toggleWrap {
	text-align: center;
}

.imageWrap {
	position: relative;
}
