@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/ranger';
@use '../settings/typography' as typography-settings;
@use 'button';
@use 'spacing';
@use 'typography';
@use 'units';

@mixin root() {
	position: relative;
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(6);
}

@mixin labels() {
	display: flex;
	justify-content: space-between;
	margin-bottom: units.px2rem(13px);
	font-size: units.px2rem(18px);
}

@mixin label() {
	@include typography.link-unstyled();
	@include button.reset();

	font-size: inherit;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$wine-light;
	}
}

@mixin label-tooltip() {
	position: relative;
	top: units.px2rem(2px);
	margin-left: units.px2rem(6px);
	font-size: 0;
}

@mixin generate() {
	appearance: none;
	width: 100%;
	margin: units.px2rem(7px) 0;

	&:focus {
		outline: none;
	}
}

@mixin track() {
	width: 100%;
	height: units.px2rem(11px);
	border: 0;
	border-radius: units.px2rem(25px);
	cursor: pointer;
}

@mixin thumb() {
	width: ranger.$thumb-size;
	height: ranger.$thumb-size;
	border: 0;
	border-radius: units.px2rem(30px);
	background: colors.$red;
	cursor: pointer;
}

@mixin thumb-background() {
	display: none;
	width: 0;
	height: 0;
}

@mixin control() {
	display: flex;
	justify-content: center;
}

@mixin button() {
	padding: spacing.generate(2);
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 4);
	font-family: inherit;
	color: colors.$wine;
	border: 0;
	background: none;
	cursor: pointer;
}

@mixin label-small() {
	flex-shrink: 0;
	width: units.px2rem(42px);
}

@mixin input() {
	appearance: textfield;
	padding: 0;
	font-weight: map.get(typography-settings.$font-weight-values, bold);
	font-size: map.get(typography-settings.$font-size-values, 0);
	text-align: center;
	border-width: 0 0 map.get(borders.$width-values, large) 0;
	border-style: solid;
	border-color: colors.$wine;
	background: transparent;
	opacity: 1;
	-webkit-text-fill-color: colors.$wine;

	/* CSS specific to iOS devices */
	@supports (-webkit-touch-callout: none) {
		appearance: none;
		border-radius: 0;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}
}
