@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(6);
	margin-block: spacing.generate(12) spacing.generate(8);
}

.item {
	display: inline-flex;
	align-items: center;
}

.item:first-child {
	margin-left: 0;
}

.link {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: inherit;
	}
}

.link > svg {
	width: units.px2rem(12px);
	height: units.px2rem(12px);
	margin-left: units.px2rem(6px);
}
