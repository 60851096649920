@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		margin-bottom: spacing.generate(10);
	}

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(6);
	}
}

.root:nth-of-type(even) {
	@include breakpoint.up(md) {
		flex-direction: row-reverse;
	}
}

.image {
	display: block;
	max-width: 100%;
	height: auto;
}

.imageWrap,
.content {
	@include breakpoint.up(md) {
		width: 50%;
	}
}

.content {
	padding: 7% 1.5% 0;

	@include breakpoint.up(md) {
		padding: 5.5% 5% 0;
	}
}

.name {
	margin-bottom: spacing.generate(4);
	font-weight: 700;
	font-size: units.px2rem(24px);
	line-height: 1.4;
	color: colors.$orange;
}

.text,
.link {
	font-size: units.px2rem(16px);
	line-height: units.px2rem(23px);
}

.text:not(:last-of-type) {
	margin-bottom: spacing.generate(6);
}

.link {
	display: inline-block;
	margin-top: spacing.generate(4);
}
