@use '../settings/arrow';
@use '../settings/colors';
@use '../tools/transitions';
@use '../tools/units';

@mixin wrap() {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@mixin generate() {
	@include transitions.generate(box-shadow);

	display: flex;
	align-items: center;
	justify-content: center;
	width: arrow.$size;
	height: arrow.$size;
	border: units.px2rem(2px) solid colors.$gray-lighter;
	border-radius: 50%;
	background: colors.$white;

	&:hover,
	&:focus {
		box-shadow: 0 0 units.px2rem(20px) 0 colors.$gray-light;
	}

	&::after {
		content: '';
		display: block;
		width: units.px2rem(12px);
		height: units.px2rem(12px);
		border-bottom: units.px2rem(2px) solid colors.$red;
		border-left: units.px2rem(2px) solid colors.$red;
		transform: translateY(units.px2rem(-2px)) rotate(-45deg);
	}
}
