@use 'sass:color';
@use 'sass:map';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/portfolio-ranger';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.ranger {
	order: 3;
	width: 100%;
	margin-right: auto;

	@include breakpoint.up(md) {
		order: initial;
		max-width: units.px2rem(350px);
		margin-right: 0;
		margin-left: auto;
	}
}

.rangerTitles {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto;

	@include breakpoint.up(md) {
		display: none;
	}
}

.rangerTitle {
	padding: spacing.generate(3);

	&:first-child {
		padding-left: 0;
		text-align: left;
	}

	&:last-child {
		padding-right: 0;
		text-align: right;
	}
}

.rangerWrap {
	position: relative;
	width: 100%;
	margin: 0 auto;

	@include breakpoint.up(md) {
		max-width: units.px2rem(350px);
	}
}

.rangerSeparators {
	position: absolute;
	inset: 0 spacing.generate(2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	pointer-events: none;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: units.px2rem(-10px);
		left: units.px2rem(-10px);
		height: units.px2rem(10px);
		border-radius: units.px2rem(10px);
		background: colors.$gray-lighter;
		transform: translateY(-50%);
	}
}

.currentPosition {
	position: absolute;
	bottom: units.px2rem(5px);
	z-index: 99;
}

.plannedPosition {
	position: absolute;
	top: units.px2rem(5px);
	z-index: 99;
}

.rangerRecommend {
	position: absolute;
	inset: 0 spacing.generate(-2);
	overflow: visible;
	border-radius: units.px2rem(10px);
}

.rangerRecommendBar {
	position: absolute;
	top: 50%;
	height: units.px2rem(10px);
	border-radius: units.px2rem(10px);
	background:
		linear-gradient(
			to right,
			colors.$gray-lighter 0%,
			colors.$green-light 5%,
			colors.$green 51%,
			colors.$green-light 95%,
			colors.$gray-lighter 100%
		);
	transform: translateY(-50%);

	@include portfolio-ranger.ranger-bar($profile-count: 7, $end-size: units.px2rem(12.5px));
}

.rangerRecommendInfo {
	margin-top: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 2);
	text-align: center;
}

.rangerRecommendInfo > strong {
	color: colors.$green;
}

.rangerSeparators > span {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(5px);
	height: units.px2rem(10px);
	background: colors.$white;

	&:first-of-type,
	&:last-of-type {
		background: transparent;
	}
}
