@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	margin-bottom: spacing.generate(4);
	text-align: center;

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(6);
	}
}

.title {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(10);
		font-size: map.get(typography.$font-size-values, 14);
		line-height: map.get(typography.$line-height-values, 1);
	}
}

.products {
	display: flex;
}

.productWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(4);
	align-items: center;
	width: 100%;

	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.product {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	max-width: units.px2rem(720px);
	padding: spacing.generate(8);
	border-radius: units.px2rem(40px);
	background-color: colors.$gray-extra-lighter;
}

.productTitle {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, -1);
}

.productText {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.feeTitle,
.transparentFeeTitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
}

.transparentFeeTitle {
	margin-bottom: spacing.generate(4);
}

.feeAmount {
	width: units.px2rem(90px);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 12);
	line-height: 0.7;
	color: colors.$orange;

	@include breakpoint.up(md) {
		width: auto;
		font-size: map.get(typography.$font-size-values, 17);
	}
}

.feePeriod {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
}

.transparentFee {
	position: relative;
	display: block;
	flex-direction: column;
	width: 100%;
	max-width: units.px2rem(380px);
	padding: spacing.generate(4) spacing.generate(8);
	margin-inline: auto;
	box-shadow: 0 units.px2rem(8px) units.px2rem(30px) units.px2rem(-10px) color.adjust(colors.$black, $alpha: -0.9);

	@include breakpoint.up(md) {
		display: flex;
		max-width: units.px2rem(530px);
		padding: spacing.generate(4) spacing.generate(8);
	}

	&::after {
		content: '';
		position: absolute;
		bottom: spacing.generate(4);
		left: units.px2rem(142px);
		width: units.px2rem(1px);
		height: units.px2rem(170px);
		background-color: colors.$wine;

		@include breakpoint.up(md) {
			right: units.px2rem(40px);
			bottom: units.px2rem(50px);
			left: units.px2rem(40px);
			width: auto;
			height: units.px2rem(1px);
		}
	}
}

.transparentFeeInner {
	display: flex;
	flex-direction: column;

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

.transparentFeeText {
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
	white-space: nowrap;
}

.transparentFeeAmount {
	width: units.px2rem(90px);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 8);

	@include breakpoint.up(md) {
		width: initial;
	}
}

.transparentFeeRow {
	display: flex;
	flex-direction: row;
	gap: spacing.generate(5);

	@include breakpoint.up(md) {
		flex-direction: column;
		justify-content: flex-end;
	}
}

.icon {
	display: inline-flex;
	align-items: center;
	height: units.px2rem(38px);
	padding-inline: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 9);

	@include breakpoint.up(md) {
		height: 100%;
		padding-bottom: units.px2rem(7px);
	}
}
