@use 'sass:color';
@use 'sass:map';
@use '../../scss/settings/borders';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/scrollbar';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-top: 0;
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	text-align: center;
	color: colors.$orange;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.subtitle {
	margin-top: 0;
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
}

.text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: typography.$line-height-base;

	&:last-child {
		margin-bottom: 0;
	}
}

.list {
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: typography.$line-height-base;
	word-break: break-word;
	list-style: disc;
}

.list > li:not(:last-child) {
	margin-bottom: spacing.generate(1);
}

.tableWrap {
	@include scrollbar.generate();

	min-height: 0.01%;
	padding-bottom: spacing.generate(6);
	margin-bottom: spacing.generate(6);
	overflow-x: unset;
	overflow-y: hidden;
	border: map.get(borders.$width-values, large) solid colors.$wine;
	border-radius: units.px2rem(10px);

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	text-align: left;
	border-collapse: collapse;
	border-spacing: 0;
}

.table th {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.table th,
.table td {
	padding: spacing.generate(2) spacing.generate(3);
}

.table tr:nth-child(odd) td {
	background-color: colors.$gray-extra-light;
}
