@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: spacing.generate(10);

	@include breakpoint.up(sm) {
		flex-direction: row;
		justify-content: center;
	}

	@include breakpoint.up(lg) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.wallet {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: units.px2rem(150px);
	height: units.px2rem(150px);
	text-align: center;
	border-radius: 50%;
	box-shadow: 0 0 units.px2rem(20px) 0 colors.$gray-light;

	@include breakpoint.up(sm) {
		display: none;
	}

	@include breakpoint.up(lg) {
		display: flex;
	}
}

.wallet > svg {
	width: units.px2rem(80px);
	height: units.px2rem(80px);
}

.wallet > div {
	margin-top: 0;
	font-weight: bold;
}

.chevron {
	margin: spacing.generate(4);
	transform: rotate(90deg);

	@include breakpoint.up(sm) {
		transform: none;

		&:nth-child(2) {
			display: none;
		}
	}

	@include breakpoint.up(lg) {
		&:nth-child(2) {
			display: block;
		}
	}
}

.sliderWrap {
	position: relative;
	width: units.px2rem(150px);
	padding-bottom: spacing.generate(14);

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}
}

.slider {
	position: relative;
}

.sliderItem {
	height: units.px2rem(150px);
}

.sliderTitle {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: units.px2rem(70px);
	margin-top: spacing.generate(2);
	font-weight: bold;
	font-size: units.px2rem(24px);
	line-height: 1.4;
	text-align: center;

	@include breakpoint.up(sm) {
		top: 100%;
		bottom: auto;
	}
}

/* stylelint-disable selector-class-pattern */
.sliderWrap :global .slick-list.slick-list,
.sliderWrap :global .slick-slide.slick-slide {
	height: units.px2rem(150px);
}
/* stylelint-enable */
