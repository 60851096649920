@use '../../scss/settings/colors';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: units.px2rem(320px);
	background:
		center 70% / cover
		no-repeat
		url('../../images/campaign/cover.jpg');

	@include breakpoint.up(xs, true) {
		background-image: url('../../images/campaign/cover@2x.jpg');
	}

	@include breakpoint.up(md) {
		height: units.px2rem(470px);
	}

	@include breakpoint.up(lg) {
		height: units.px2rem(640px);
		background-image: url('../../images/campaign/cover-lg.jpg');
	}

	@include breakpoint.up(lg, true) {
		background-image: url('../../images/campaign/cover-lg@2x.jpg');
	}

	@include breakpoint.up(xl) {
		height: 65vh;
		background-image: url('../../images/campaign/cover-xl.jpg');
		background-size: 120%;
	}

	@include breakpoint.up(xl, 2) {
		background-image: url('../../images/campaign/cover-xl@2x.jpg');
	}
}

.box {
	padding-top: spacing.generate(6);
	padding-bottom: spacing.generate(5);
}

.boxInner {
	max-width: 40%;

	@include breakpoint.up(lg) {
		max-width: 60%;
	}
}

.title {
	margin-bottom: spacing.generate(3);
	font-weight: 700;
	font-size: units.px2rem(24px);
	line-height: 1.1;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(6);
		font-size: units.px2rem(40px);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(6);
		font-size: units.px2rem(55px);
	}
}

.subtitle {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(15px);
	line-height: 1.4;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(9);
		font-size: units.px2rem(18px);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(13);
		font-size: units.px2rem(20px);
	}
}
