@use '../../../scss/settings/colors';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding: spacing.generate(3);
	margin-bottom: spacing.generate(4);
	border: units.px2rem(1px) solid transparent;
	border-radius: units.px2rem(4px);
}

.success {
	color: colors.$success;
	border-color: colors.$success-light;
	background-color: colors.$success-lighter;
}

.danger {
	color: colors.$error;
	border-color: colors.$error-light;
	background-color: colors.$error-lighter;
}

.warning {
	color: colors.$warning;
	border-color: colors.$warning-light;
	background-color: colors.$warning-lighter;
}

.hasClose {
	padding-right: spacing.generate(7);
}

.close {
	position: relative;
	top: units.px2rem(-2px);
	right: units.px2rem(-21px);
	appearance: none;
	float: right;
	padding: 0;
	font-weight: 700;
	font-size: units.px2rem(21px);
	line-height: 1;
	font-family: inherit;
	text-shadow: 0 units.px2rem(1px) 0 colors.$white;
	color: inherit;
	border: 0;
	background: transparent;
	opacity: 0.2;
	cursor: pointer;
}
