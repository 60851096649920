@use 'sass:map';
@use 'sass:math';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/ranger' as ranger-settings;
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/ranger';
@use '../../../scss/tools/units';

.root {
	@include ranger.root();
}

.labels {
	@include ranger.labels();
}

.labelsSmall {
	justify-content: space-between;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
}

.label {
	@include ranger.label();
}

.labelTooltip {
	@include ranger.label-tooltip();
}

.rangerWrap {
	position: relative;
}

.ranger {
	@include ranger.generate();

	position: absolute;
	z-index: 100;
	background: transparent;
}

.ranger:last-child {
	z-index: 99;
}

.ranger::-webkit-slider-runnable-track {
	@include ranger.track();
}

.ranger::-webkit-slider-thumb {
	@include ranger.thumb();

	appearance: none;
	margin-top: units.px2rem(-7px);
}

.ranger::-moz-range-track {
	@include ranger.track();
}

.ranger::-moz-range-thumb {
	@include ranger.thumb();
}

.rangerBackground {
	position: static;
	width: 100%;
	border-radius: units.px2rem(25px);
	background:
		linear-gradient(
			to right,
			colors.$gray-lighter 0%,
			colors.$red-dark 0%,
			colors.$red-dark 100%,
			colors.$gray-lighter 100%
		);
	pointer-events: none;
}

.rangerBackground::-webkit-slider-thumb {
	@include ranger.thumb-background();
}

.rangerBackground::-moz-range-thumb {
	@include ranger.thumb-background();
}

.controlWrap {
	display: flex;
	justify-content: space-between;
}

.control {
	@include ranger.control();
}

.button {
	@include ranger.button();
}

.labelsSmall > .label {
	@include ranger.label-small();
}

.labelsSmall > .label:first-child,
.labelsSmall > .label:last-child {
	width: units.px2rem(56px);
}

.input {
	@include ranger.input();

	width: units.px2rem(55px);
}

@include breakpoint.up(md) {
	.labelsSmall > .label:first-child {
		margin-right: units.px2rem(-7px);
		margin-left: math.div(ranger-settings.$thumb-size, 2) - units.px2rem(28px);
	}

	.labelsSmall > .label:last-child {
		margin-right: math.div(ranger-settings.$thumb-size, 2) - units.px2rem(28px);
		margin-left: calc(#{units.px2rem(-9px)} - 11%); // TODO: replace magic 2%
		visibility: hidden;
	}
}
