@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	padding-block: spacing.generate(2) spacing.generate(12);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(10px) units.px2rem(10px) 0 color.adjust(colors.$black, $alpha: -0.9);

	@include breakpoint.up(md) {
		padding-block: spacing.generate(2);
	}
}

.wrapper {
	position: relative;
	max-width: units.px2rem(1530px);
	margin-inline: auto;

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(3);
	}
}

/* stylelint-disable selector-class-pattern */
.slider :global .slick-track.slick-track {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
/* stylelint-enable */

.wrap {
	padding-right: spacing.generate(3);
}

.wrap.last {
	padding-right: 0;
}

.box {
	@include transitions.generate('opacity color');

	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	min-width: units.px2rem(231px);
	padding: 0;
	border: borders.$width solid colors.$gray-lighter;
	border-radius: spacing.generate(2);
	background-color: colors.$white;
	cursor: pointer;

	&:hover,
	&:focus {
		color: colors.$red-dark;
		opacity: 0.9;
	}
}

.sliderArrow {
	position: absolute;
	bottom: units.px2rem(-50px);
	z-index: 100;
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
	justify-content: center;
	width: units.px2rem(120px);
	height: units.px2rem(40px);
	border: 0;
	cursor: pointer;

	@include breakpoint.up(md) {
		top: 50%;
		bottom: unset;
		gap: 0;
		width: units.px2rem(70px);
		height: units.px2rem(66px);
		transform: translateY(-50%);
	}

	&:first-of-type {
		left: 0;
		flex-flow: row;
		border-radius: spacing.generate(4);

		@include breakpoint.up(md) {
			flex-flow: column;
			border-top-right-radius: spacing.generate(4);
			border-bottom-right-radius: spacing.generate(4);
		}
	}

	&:last-of-type {
		right: 0;
		flex-flow: row-reverse;
		border-radius: spacing.generate(4);

		@include breakpoint.up(md) {
			flex-flow: column;
			border-top-left-radius: spacing.generate(4);
			border-bottom-left-radius: spacing.generate(4);
		}
	}
}

.sliderArrow[disabled] {
	display: none;
}

.text {
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, -1);
}

.inner {
	@include transitions.generate('opacity color');

	display: flex;
	align-items: center;
	width: 100%;
	padding: spacing.generate(3);
	border: map.get(borders.$width-values, large) solid transparent;
	border-radius: spacing.generate(2);

	@include breakpoint.up(md) {
		padding: spacing.generate(2);
	}
}

.name {
	width: calc(100% - #{spacing.generate(12)});
	margin-left: spacing.generate(2);
	overflow: hidden;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	text-align: left;
	text-overflow: ellipsis;

	@include breakpoint.up(sm) {
		text-align: center;
	}
}

.active {
	border-color: transparent;
}

.active > .box > .inner {
	border-color: colors.$orange;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(33px);
	height: units.px2rem(33px);

	@include breakpoint.up(md) {
		width: units.px2rem(50px);
		height: units.px2rem(50px);
	}
}
