@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	@include transitions.generate('opacity, transform');

	position: fixed;
	right: spacing.generate(4);
	bottom: spacing.generate(4);
	z-index: 999998;
	width: units.px2rem(260px);
	max-height: calc(100vh - #{spacing.generate(4)});
	padding: spacing.generate(7) spacing.generate(4) spacing.generate(4);
	overflow: auto;
	border: units.px2rem(2px) solid colors.$orange;
	border-radius: units.px2rem(10px);
	background: colors.$white;
	box-shadow: 0 units.px2rem(4px) units.px2rem(6px) colors.$gray-lighter;
	opacity: 0;
	transform: translateY(100%);

	@include breakpoint.up(sm) {
		top: units.px2rem(120px);
		bottom: auto;
		transform: translateX(100%);
	}
}

.rootVisible {
	opacity: 1;
	transform: translateY(0);

	@include breakpoint.up(sm) {
		transform: translateX(0);
	}
}

.close {
	position: absolute;
	top: units.px2rem(10px);
	right: units.px2rem(10px);
	width: units.px2rem(24px);
	height: units.px2rem(24px);
	font-size: 0;
	border: 0;
	background: none;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: units.px2rem(20px);
		height: units.px2rem(3px);
		background-color: colors.$orange;
	}

	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
}

.title {
	margin-bottom: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
}

.question {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 0);
}

.check {
	display: block;
}

.check > input {
	position: absolute;
	width: units.px2rem(1px);
	height: units.px2rem(1px);
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.checkButton {
	display: block;
	padding: units.px2rem(8px);
	margin-bottom: units.px2rem(8px);
	border: units.px2rem(2px) solid black;
	border-radius: units.px2rem(10px);
	cursor: pointer;
}

.checkButton > input {
	display: block;
	width: 100%;
	padding: 0;
	font-size: inherit;
	font-family: inherit;
	border: 0;
	background-color: transparent;
}

.check > input:checked ~ .checkButton {
	background-color: colors.$orange-light;
}

.validateError {
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	text-align: left;
	color: colors.$error;
}

.submit {
	text-align: center;
}
