@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	padding: 0;
	margin: 0 0 units.px2rem(35px);
	text-align: center;
}

.item {
	display: inline-block;
	padding: 0 units.px2rem(2px);
}

.button {
	@include transitions.generate('border-color');

	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(35px);
	height: units.px2rem(35px);
	padding: 0;
	font-weight: bold;
	font-size: units.px2rem(17px);
	color: colors.$wine;
	border: units.px2rem(2px) solid transparent;
	border-radius: 50%;
	background: transparent;
	cursor: pointer;

	&:hover,
	&:focus {
		border-color: colors.$wine;
	}

	&:disabled {
		pointer-events: none;
	}
}

.buttonActive {
	color: colors.$white;
	border-color: colors.$red;
	background: colors.$red;
	cursor: pointer;

	&:hover,
	&:focus {
		border-color: colors.$red;
	}
}
