@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.netGrowthWrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: spacing.generate(4);

	@include breakpoint.up(md) {
		display: block;
		margin-top: 0;
	}
}

.netGrowthTitle {
	max-width: units.px2rem(220px);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, semibold);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.netGrowthTitleIcon {
	position: relative;
	top: units.px2rem(2px);
	margin-left: units.px2rem(6px);
	font-size: 0;
}

.netGrowthValue {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, semibold);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.netGrowthUp {
	color: colors.$green-dark;
}

.netGrowthDown {
	color: colors.$red-light;
}
