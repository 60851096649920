@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';

.root {
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	text-align: center;
}

.modal {
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	@include breakpoint.up(lg) {
		width: 66%;
	}
}
