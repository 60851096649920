@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(4);
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.helper {
	flex-grow: 1;
	margin-left: spacing.generate(4);
	font-weight: normal;
	font-size: units.px2rem(14px);
	text-align: right;
}

.inputWrap {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.error,
.warning {
	display: block;
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	text-align: left;
}

.error {
	color: colors.$error;
}

.warning {
	color: colors.$orange;
}

.rootError > .inputWrap > .input:focus {
	box-shadow:
		inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
		0 0 units.px2rem(6px) colors.$pink;
}

.toggle {
	position: relative;
	display: flex;
	width: units.px2rem(236px);
	height: units.px2rem(46px);
	margin: spacing.generate(6) auto;
	border: units.px2rem(2px) solid colors.$wine-dark;
	border-radius: units.px2rem(25px);

	@include breakpoint.up(sm) {
		width: units.px2rem(305px);
	}
}

.toggleItem {
	@include button.generate();

	position: relative;
	z-index: 1;
	width: 100%;
	font-weight: 700;
	font-size: units.px2rem(16px);
	line-height: 1;
	text-transform: none;
	color: colors.$wine-dark;
	background-color: transparent;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$wine-dark;
		opacity: 1;
	}

	&:active {
		background-color: transparent;
	}

	@include breakpoint.up(sm) {
		padding: spacing.generate(2) spacing.generate(4);
	}
}

.toggleItemActive {
	color: colors.$white;

	&:hover,
	&:focus {
		color: colors.$white;
	}
}

.toggleActive {
	@include button.generate();
	@include transitions.generate('background-color, border-color, transform');

	position: absolute;
	top: units.px2rem(-2px);
	left: units.px2rem(-3px);
	width: units.px2rem(120px);
	height: units.px2rem(46px);
	background-color: colors.$orange;
	transform: translateX(0);

	&:hover,
	&:focus {
		border-color: colors.$orange-light;
		background-color: colors.$orange-light;
	}

	@include breakpoint.up(sm) {
		width: units.px2rem(154px);
	}
}

.toggleActiveIban {
	width: units.px2rem(126px);
	transform: translateX(units.px2rem(112px));

	@include breakpoint.up(sm) {
		width: units.px2rem(156px);
		transform: translateX(units.px2rem(152px));
	}
}

.toggleItemInput {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: inherit;
}
