@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';
@use '../../../scss/tools/spacing';

.box {
	padding: spacing.generate(8) spacing.generate(4);
	margin-top: spacing.generate(8);
	margin-bottom: spacing.generate(10);
	text-align: center;
	border-radius: spacing.generate(2);
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(sm) {
		padding: spacing.generate(8);
		margin: spacing.generate(8) spacing.generate(4) spacing.generate(12);
	}
}

.start,
.wrap {
	margin-top: spacing.generate(8);
	margin-bottom: spacing.generate(8);
}

.wrap {
	padding: spacing.generate(4) spacing.generate(6);
	background-color: colors.$gray-lighter;
}

.semibold {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
}

.text {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
}

.linkWrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: spacing.generate(4);
}

.link {
	padding-top: spacing.generate(2);
	padding-bottom: spacing.generate(2);
	margin-left: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
}
