@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.stepContent {
	position: relative;
	max-width: 600px;
	margin-top: spacing.generate(3);

	@include breakpoint.up(lg) {
		margin-left: calc(17% + #{spacing.generate(6)});
	}
}

.tooltip {
	position: absolute;
	top: 50%;
	margin-left: spacing.generate(1);
	transform: translateY(-50%);
}

.tooltipList {
	padding-bottom: spacing.generate(2);
	padding-left: spacing.generate(3);
}

.tooltipText,
.tooltipItem {
	font-size: map.get(typography.$font-size-values, 0);
}

.tooltipText:not(:last-child) {
	padding-bottom: spacing.generate(1);
}

.tooltipItem {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: units.px2rem(-15px);
		width: units.px2rem(5px);
		height: units.px2rem(5px);
		border-radius: 50%;
		background-color: colors.$white;
		transform: translateY(-50%);
	}
}

.text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
}

.wrap {
	position: relative;
}

.infoBox {
	position: relative;
	display: inline-flex;
	gap: spacing.generate(8);
	min-width: units.px2rem(300px);
	padding: spacing.generate(2) spacing.generate(2) spacing.generate(2) spacing.generate(7);
	margin-bottom: spacing.generate(6);
	border-radius: units.px2rem(6px);
	background-color: colors.$gray-extra-light;
}

.infoIcon {
	position: absolute;
	top: spacing.generate(2);
	left: spacing.generate(2);
}

.infoList {
	display: inline-flex;
}

.infoListTitle,
.infoListItem {
	font-size: map.get(typography.$font-size-values, 1);
}

.infoListTitle {
	margin-top: units.px2rem(3px);
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.infoListItem {
	padding-block: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, medium);
}

.infoListItem.isOrdered {
	position: relative;
	padding: spacing.generate(1) 0 spacing.generate(1) spacing.generate(4);

	&::before {
		content: '';
		position: absolute;
		top: calc(50% - #{units.px2rem(1px)});
		left: spacing.generate(2);
		width: units.px2rem(2px);
		height: units.px2rem(4px);
		border-radius: units.px2rem(1px);
		background-color: colors.$wine;
		transform: translateY(-50%);
	}
}
