@use '../../../scss/settings/colors';
@use '../../../scss/tools/arrow';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/layout';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: 400;
	font-size: units.px2rem(32px);
	line-height: 1.25;
	text-align: center;

	@include breakpoint.up(lg) {
		font-size: units.px2rem(60px);
	}
}

.chart {
	position: relative;
	display: grid;
	grid-template:
		'money money' 1fr
		'client .' 1fr
		'portfolio .' 1fr
		'. broker' 1fr
		'fondee .' 1fr
		'trading trading' 1fr
		/ 1fr 1fr;
	align-items: center;
	justify-content: center;
	margin-bottom: spacing.generate(14);

	&::before {
		content: '';
		position: absolute;
		inset:
			calc(8.33% - #{units.px2rem(1px)})
			calc(25% - #{units.px2rem(1px)});
		display: block;
		border: units.px2rem(2px) solid colors.$gray-lighter;
		border-radius: units.px2rem(40px);
	}

	@include breakpoint.up(sm) {
		grid-template:
			'money money' 1fr
			'client .' 1fr
			'portfolio broker' 1fr
			'fondee .' 1fr
			'trading trading' 1fr
			/ 1fr 1fr;

		&::before {
			top: calc(10% - #{units.px2rem(1px)});
			bottom: calc(10% - #{units.px2rem(1px)});
		}
	}

	@include breakpoint.up(lg) {
		grid-template:
			'money money money money money' #{units.px2rem(250px)}
			'client portfolio fondee trading broker' #{units.px2rem(250px)}
			/ 1fr 1fr 1fr 1fr 1fr;
		margin-bottom: spacing.generate(23);

		&::before {
			inset:
				calc(25% - #{units.px2rem(1px)})
				calc(10% - #{units.px2rem(1px)});
		}
	}

	@include breakpoint.up(xxl) {
		grid-template-rows: units.px2rem(280px) units.px2rem(280px);
	}
}

.money {
	grid-area: money;
}

.client {
	grid-area: client;
}

.portfolio {
	grid-area: portfolio;
}

.fondee {
	grid-area: fondee;
}

.trading {
	grid-area: trading;
}

.broker {
	grid-area: broker;
}

.circle {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: units.px2rem(140px);
	height: units.px2rem(140px);
	margin: auto;
	font-size: units.px2rem(19px);
	line-height: 1;
	border: units.px2rem(2px) solid colors.$gray-lighter;
	border-radius: 50%;
	background: colors.$white;

	@include breakpoint.up(sm) {
		width: units.px2rem(200px);
		height: units.px2rem(200px);
	}

	@include breakpoint.up(xxl) {
		width: units.px2rem(280px);
		height: units.px2rem(280px);
	}
}

.circle > svg {
	max-width: 90%;
}

.circle > p {
	margin-top: spacing.generate(3);
}

.arrowWrap {
	@include arrow.wrap();
}

.arrowWrap > p {
	position: absolute;
	font-size: units.px2rem(19px);
	line-height: 1;
	text-align: center;

	@include breakpoint.up(sm) {
		white-space: nowrap;
	}
}

.money.arrowWrap > p {
	top: units.px2rem(60px);
	left: 50%;
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		top: units.px2rem(70px);
	}
}

.portfolio.arrowWrap > p {
	top: 50%;
	left: calc(50% + #{units.px2rem(35px)});
	transform: translateY(-50%);

	@include breakpoint.up(sm) {
		left: calc(50% + #{units.px2rem(45px)});
	}

	@include breakpoint.up(lg) {
		top: units.px2rem(70px);
		left: 50%;
		transform: translateX(-50%);
	}
}

.trading.arrowWrap > p {
	bottom: units.px2rem(60px);
	left: 50%;
	max-width: units.px2rem(160px);
	transform: translateX(-50%);

	@include breakpoint.up(sm) {
		bottom: units.px2rem(70px);
		max-width: none;
	}

	@include breakpoint.up(lg) {
		top: units.px2rem(70px);
		bottom: auto;
	}
}

.arrow {
	@include arrow.generate();

	transform: rotate(-90deg);
}

.portfolio > .arrow {
	transform: rotate(0);

	@include breakpoint.up(lg) {
		transform: rotate(-90deg);
	}
}

.brokerInfo {
	max-width: units.px2rem(800px);
	margin-right: auto;
	margin-left: auto;
}

.brokerTitle {
	margin-bottom: spacing.generate(4);
	font-weight: 700;
	font-size: units.px2rem(24px);
}

.brokerText {
	font-size: units.px2rem(18px);
	line-height: 1.5;
}
