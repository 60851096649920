@use '../settings/chart';
@use 'breakpoint';

@mixin wrap() {
	position: relative;
	width: chart.$width;

	@include breakpoint.up(sm) {
		width: chart.$size-sm;
	}

	@include breakpoint.up(lg) {
		width: chart.$size-lg;
	}
}

@mixin generate() {
	flex-shrink: 0;
	width: chart.$width;
	height: chart.$height;

	@include breakpoint.up(sm) {
		width: chart.$size-sm;
	}

	@include breakpoint.up(lg) {
		width: chart.$size-lg;
		height: chart.$size-lg;
	}
}
