@use '../../scss/tools/breakpoint';

.root {
	position: relative;
}

.toggleWrap {
	text-align: center;

	@include breakpoint.up(md) {
		text-align: right;
	}
}
