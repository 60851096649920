@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.backdrop {
	@include transitions.generate('visibility, opacity');

	position: fixed;
	inset: 0;
	z-index: 1050;
	display: flex;
	align-items: flex-start;
	padding: spacing.generate(2);
	overflow: hidden auto;
	background: color.adjust(colors.$black, $alpha: -0.5);
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

.backdrop.isVisible {
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
}

.root {
	position: relative;
	z-index: 1;
	min-width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	text-align: center;
	border: 1px solid color.adjust(colors.$black, $alpha: -0.8);
	border-radius: 12px;
	background-color: #fff;
	outline: 0;
	box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

	@include breakpoint.up(md) {
		min-width: auto;
		margin: spacing.generate(4) auto;
	}
}

.root.overflow {
	overflow-x: visible;
}

.default {
	@include breakpoint.up(md) {
		width: units.px2rem(600px);
	}
}

.medium {
	@include breakpoint.up(md) {
		width: units.px2rem(600px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(775px);
	}
}

.large {
	@include breakpoint.up(md) {
		width: units.px2rem(600px);
	}

	@include breakpoint.up(lg) {
		width: units.px2rem(900px);
	}
}

.close {
	position: absolute;
	top: units.px2rem(15px);
	right: units.px2rem(15px);
	appearance: none;
	height: 20px;
	padding: 0;
	font-weight: 500;
	font-size: 3em;
	line-height: 20px;
	font-family: inherit;
	text-shadow: 0 1px 0 #fff;
	color: red;
	border: 0;
	background: 0 0;
	cursor: pointer;
}

.header {
	padding: spacing.generate(3);
	text-align: center;
}

.title.title {
	padding-top: spacing.generate(10);
	margin-bottom: spacing.generate(2);
	font-weight: 600;
	font-size: units.px2rem(36px);
}

.subtitle.subtitle.subtitle {
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(18px);
}

.body {
	max-height: calc(100vh - #{units.px2rem(110px)});
	padding: spacing.generate(2) spacing.generate(2) spacing.generate(10);
	overflow: hidden auto;
	text-align: left;

	@include breakpoint.up(sm) {
		padding: spacing.generate(2) spacing.generate(6) spacing.generate(10);
	}

	@include breakpoint.up(md) {
		padding: spacing.generate(2) spacing.generate(10) spacing.generate(10);
	}
}

.root.overflow > .body {
	overflow: visible;
}
