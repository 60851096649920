@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/transitions';
@use '../../../../scss/tools/units';

.wrap {
	position: relative;
	margin-bottom: spacing.generate(5);
}

.title {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
}

.list {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(1);
}

.button {
	@include transitions.generate('background-color');

	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(1) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: map.get(typography.$line-height-values, 0);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;
	border: 0;
	border-radius: units.px2rem(25px);
	background-color: colors.$white;
	box-shadow: 0 0 units.px2rem(10px) 0 #{rgba(colors.$black, 0.09)};
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			color: colors.$white;
			background-color: colors.$orange-light;
		}
	}

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.button.selected {
	color: colors.$white;
	background-color: colors.$orange;
}
