@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.priceFlow {
	display: inline-flex;
	align-items: center;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
}

.priceFlowPositive {
	color: colors.$green-dark;
}

.priceFlowNegative {
	color: colors.$red-dark;
}

.iconPriceFlow {
	display: inline-block;
	height: units.px2rem(14px);
	margin-right: spacing.generate(1);
}

.priceFlowNegative > .iconPriceFlow {
	transform: rotate(90deg);
}
