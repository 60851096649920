@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/scrollbar';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(10);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(8);
		font-size: map.get(typography.$font-size-values, 14);
		line-height: map.get(typography.$line-height-values, 1);
	}
}

.tableWrap {
	@include scrollbar.generate();

	width: 100%;
	min-height: 0.01%;
	padding-bottom: spacing.generate(6);
	margin-bottom: spacing.generate(3);
	overflow-x: unset;
	overflow-y: hidden;
	border: 0;

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}

	@include breakpoint.up(md) {
		width: auto;
		margin-bottom: 0;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

/* stylelint-disable selector-max-compound-selectors */
.table > thead > tr > td,
.table > thead > tr > th {
	padding: spacing.generate(2);
	line-height: 1.4;
}

.table > thead > tr > th {
	vertical-align: top;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
	padding: spacing.generate(2);
	line-height: 1.4;
}
/* stylelint-enable */

.tableWrap.tableWrap th {
	font-weight: normal;
	font-size: units.px2rem(22px);
	text-align: center;
	border-bottom: units.px2rem(2px) solid colors.$wine;
}

.tableWrap th > strong {
	display: block;
}

.tableWrap.tableWrap td {
	padding: units.px2rem(2.5px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(36px);
	text-align: center;
	border: 0;

	@include breakpoint.up(xl) {
		width: units.px2rem(168px);
	}
}

.tableWrap tr:nth-child(3) td,
.tableWrap tr:nth-child(6) td {
	border-top: units.px2rem(1px) solid colors.$gray-light;
}

/* stylelint-disable selector-max-compound-selectors */
.tableWrap > .table > tbody > tr > td,
.tableWrap > .table > tbody > tr > th,
.tableWrap > .table > tfoot > tr > td,
.tableWrap > .table > tfoot > tr > th,
.tableWrap > .table > thead > tr > td > strong,
.tableWrap > .table > thead > tr > th > strong {
	white-space: nowrap;
}

.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th {
	border-top: 0;
}
/* stylelint-enable */

.tableWrap.tableWrap td:first-child {
	font-weight: 600;
	text-align: left;

	@include breakpoint.up(xl) {
		width: auto;
	}
}

.tableWrap.tableWrap td:nth-child(2) {
	font-weight: 600;
	color: colors.$orange;
}

/* stylelint-disable selector-max-compound-selectors */
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
	border-top: 0;
}
/* stylelint-enable */

.footer > p {
	max-width: 100ch;
	margin-top: spacing.generate(1);
	font-size: 90%;
}
