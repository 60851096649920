@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	margin-bottom: spacing.generate(6);
}

.phoneWrap {
	max-width: units.px2rem(400px);
}

.phoneTitle {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
}

.phoneText {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 1);
}

.addressWrap {
	padding: spacing.generate(4);
	margin-inline: spacing.generate(-4);
	margin-bottom: spacing.generate(2);
	border-radius: units.px2rem(6px);
	background-color: colors.$gray-extra-light;
}

.addressTitle {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
}

.addressChangeWrap {
	display: none;
	padding-top: spacing.generate(4);
	margin-block: spacing.generate(4) spacing.generate(-4);
	border-top: units.px2rem(1px) solid colors.$gray-lighter;
}

.addressChangeWrap.isOpen {
	display: block;
}

.addressChange {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;
}

.modalControlItem {
	margin-bottom: spacing.generate(2);
}

.listItem,
.text {
	font-size: map.get(typography.$font-size-values, 4);
}

.list {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(1);
	padding-block: spacing.generate(2);
}

.listItem {
	font-weight: map.get(typography.$font-weight-values, medium);
}
