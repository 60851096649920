@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-block: spacing.generate(5);
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.title {
	width: 100%;
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 7);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
}

.warning {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	color: colors.$red;
}

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: spacing.generate(2);
	white-space: nowrap;
}

.buttonWrap {
	margin-left: auto;
}
