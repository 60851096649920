@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: spacing.generate(4);

	@include breakpoint.up(md) {
		display: block;
		margin-top: 0;
	}
}

.title {
	max-width: units.px2rem(220px);
	margin-bottom: 0;
	font-size: units.px2rem(18px);
}

.titleIcon {
	position: relative;
	top: units.px2rem(2px);
	margin-left: units.px2rem(6px);
	font-size: 0;
}

.value {
	font-size: units.px2rem(35px);
	line-height: 1.4;
}
