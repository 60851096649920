@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(35px);
	line-height: 1.4;

	@include breakpoint.up(md) {
		margin-top: 0;
	}
}

.text {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-size: units.px2rem(18px);
	line-height: 1.5;
	white-space: pre-line;
}
