@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	margin-bottom: spacing.generate(20);
}

.wrap {
	display: flex;
	flex-direction: column;

	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.title {
	margin-bottom: spacing.generate(8);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	text-align: center;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.item {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-inline: spacing.generate(2);
	text-align: center;

	@include breakpoint.up(lg) {
		width: 33.33%;
	}

	@include breakpoint.up(xl) {
		padding-inline: spacing.generate(7);
	}
}

.icon {
	display: flex;
	align-items: flex-end;
	width: auto;
	height: units.px2rem(80px);
	margin-bottom: spacing.generate(7);
}

.icon > svg {
	width: 100%;
	height: auto;
	max-height: 100%;
}

.subTitle {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 9);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.text {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}

.text > a {
	font-weight: map.get(typography.$font-weight-values, bold);
}
