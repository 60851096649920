@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: fixed;
	top: units.px2rem(10px);
	left: 50%;
	z-index: 1031;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	padding: spacing.generate(3);
	background: colors.$white;
	box-shadow: 0 units.px2rem(4px) units.px2rem(6px) colors.$gray-light;
	transform: translateX(-50%);
	animation: show-popup-top 150ms ease-in-out forwards;

	@include breakpoint.up(md) {
		top: auto;
		bottom: units.px2rem(30px);
		flex-direction: row;
		width: 66.66%;
		animation-name: show-popup-bottom;
	}
}

.close {
	position: absolute;
	top: units.px2rem(10px);
	right: units.px2rem(10px);
	padding: 0;
	color: colors.$gray-light;
	border: 0;
	background: transparent;
	cursor: pointer;
}

.text {
	margin-bottom: spacing.generate(4);
	font-size: units.px2rem(18px);
	line-height: 1.5;

	@include breakpoint.up(md) {
		width: calc(100% / 12 * 8 - #{units.px2rem(15px)});
		margin-bottom: 0;
	}
}

.button {
	@include breakpoint.up(md) {
		width: calc(100% / 12 * 4 - #{units.px2rem(15px)});
	}
}

@keyframes show-popup-bottom {
	from {
		display: flex;
		opacity: 0;
		transform: translateX(-50%) translateY(units.px2rem(10px));
	}

	to {
		display: flex;
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}
}

@keyframes show-popup-top {
	from {
		display: block;
		opacity: 0;
		transform: translateX(-50%) translateY(units.px2rem(-10px));
	}

	to {
		display: block;
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}
}
