@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

$_height: units.px2rem(40px);

.root {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-left: spacing.generate(3);

	@include breakpoint.up(md) {
		flex-direction: row;
		justify-content: end;
		padding-left: 0;
	}
}

.trigger,
.listItem {
	font-weight: 600;
	font-size: units.px2rem(18px);
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;
	background-color: colors.$white;
	cursor: pointer;
}

.trigger {
	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	height: $_height;
	padding: spacing.generate(2) spacing.generate(7) spacing.generate(2) spacing.generate(2);
	text-align: left;
	border: borders.$width solid colors.$gray-light;
	border-radius: units.px2rem(25px);

	&::after {
		@include transitions.generate(transform);

		content: '';
		position: absolute;
		top: 0;
		right: units.px2rem(12px);
		width: units.px2rem(9px);
		height: units.px2rem(9px);
		border-width: map.get(borders.$width-values, large) 0 0 map.get(borders.$width-values, large);
		border-style: solid;
		border-color: colors.$wine;
		transform: translateY(calc(50% + #{units.px2rem(8px)})) rotate(225deg);
		transform-origin: center;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$wine;
		background-color: colors.$white;
	}

	&:active {
		background-color: colors.$white;
	}
}

.list {
	@include transitions.generate('visibility, opacity');

	z-index: 3;
	margin-top: spacing.generate(1);
	border-radius: units.px2rem(17px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(3px) units.px2rem(6px) colors.$gray-extra-light;
	visibility: hidden;
	opacity: 0;

	@include breakpoint.up(md) {
		position: absolute;
		top: $_height + spacing.generate(1);
		right: 0;
		margin-top: 0;
	}
}

.isRootOpen .list {
	visibility: visible;
	opacity: 1;
}

.isRootOpen > .trigger::after {
	transform: translateY(calc(50% + #{units.px2rem(12px)})) rotate(45deg);
}

.listItem {
	@include transitions.generate('color, background-color');

	display: block;
	width: 100%;
	padding: spacing.generate(2) spacing.generate(4);
	text-align: center;
	border-radius: units.px2rem(17px);

	&:first-child {
		border-end-start-radius: 0;
		border-end-end-radius: 0;
	}

	&:last-child {
		border-start-start-radius: 0;
		border-start-end-radius: 0;
	}
}

.listItem.isItemActive {
	background-color: colors.$orange-lighter;
}
