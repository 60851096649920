@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-inline: spacing.generate(4);
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	font-size: units.px2rem(18px);
	line-height: 1.1;
}

.subtitle {
	margin-top: 0;
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.5;
}
