@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	z-index: 100;
	margin-bottom: spacing.generate(4);

	&:only-child {
		margin-bottom: 0;
	}
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.selectFieldWrap {
	display: block;
	width: 100%;
}

.error {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	color: colors.$error;
}

/* stylelint-disable selector-class-pattern */
.selectFieldWrap :global .selectField__control {
	@include transitions.generate(box-shadow);

	appearance: none;
	height: units.px2rem(56px);
	padding: units.px2rem(6px) 0 units.px2rem(6px) units.px2rem(12px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(35px);
	font-family: inherit;
	color: colors.$gray-dark;
	border: 0;
	border-radius: units.px2rem(8px);
	background: colors.$gray-lighter;
	box-shadow: inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%);
}

.selectFieldWrap :global .selectField__value-container {
	flex-wrap: nowrap;
	padding: 0;
}

.selectFieldWrap :global .selectField__control.selectField__control--is-focused,
.selectFieldWrap :global .selectField__control.selectField__control--is-focused:hover {
	outline: 0;
	box-shadow:
		inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
		0 0 units.px2rem(8px) rgb(102 175 233 / 60%);
}

.selectFieldWrap :global .selectField__indicator.selectField__dropdown-indicator {
	padding: units.px2rem(8px) units.px2rem(4px);
	color: colors.$wine;

	@include breakpoint.up(sm) {
		padding: units.px2rem(8px);
	}
}

.selectFieldWrap :global .selectField__indicator-separator {
	display: none;
}

.selectFieldWrap :global .selectField__menu {
	z-index: 1;
	margin: 0;
	overflow: hidden;
	border-radius: units.px2rem(6px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(2px) units.px2rem(12px) #{rgba(colors.$black, 0.15)};
}

.selectFieldWrap :global .selectField__menu-list {
	max-height: units.px2rem(200px);
	padding: 0;
	border: 0;
}

.selectFieldWrap :global .selectField__option {
	padding: spacing.generate(2) spacing.generate(3);
	text-align: left;
	color: colors.$wine;
	background-color: colors.$white;
	cursor: pointer;
}

.selectFieldWrap :global .selectField__option--is-focused {
	color: colors.$white;
	background-color: colors.$blue-light;
}

.selectFieldWrap :global .selectField__option--is-selected {
	color: colors.$wine;
}

.selectFieldWrap :global .selectField__single-value {
	color: colors.$gray-dark;
}

.selectFieldWrap :global .selectField__placeholder {
	color: colors.$gray-dark;
}

.selectFieldWrap :global .selectField__multi-value {
	background-color: transparent;
}

.selectFieldWrap :global .selectField__multi-value__label {
	padding-right: 0;
}

/* stylelint-disable selector-max-compound-selectors, selector-max-specificity */
.selectFieldWrap :global .selectField__multi-value:not(:last-of-type) > .selectField__multi-value__label {
	&::after {
		content: ',';
	}
}
/* stylelint-enable selector-max-compound-selectors, selector-max-specificity */

.selectFieldWrap :global .selectField__multi-value__remove {
	display: none;
}

.inputWrap {
	position: relative;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

.input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: inherit;
}

.optionLabel {
	position: relative;
	z-index: 2;
	display: block;
	padding-top: units.px2rem(1px);
	padding-left: units.px2rem(18px) + units.px2rem(7px);
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: units.px2rem(18px);
	color: colors.$wine;
	user-select: none;
	pointer-events: none;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: units.px2rem(18px);
		height: units.px2rem(18px);
		border: units.px2rem(1px) solid colors.$gray;
		border-radius: units.px2rem(3.5px);
		background-color: colors.$gray-lighter;
	}

	&::after {
		content: '';
		position: absolute;
		top: units.px2rem(4px);
		left: units.px2rem(6.5px);
		display: none;
		width: units.px2rem(5px);
		height: units.px2rem(8.5px);
		border: solid colors.$orange;
		border-width: 0 units.px2rem(2.5px) units.px2rem(2.5px) 0;
		transform: rotate(45deg);
	}
}

.isLabelLarge {
	padding-left: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: units.px2rem(22px);

	&::before {
		width: units.px2rem(22px);
		height: units.px2rem(22px);
	}

	&::after {
		top: units.px2rem(5px);
		left: units.px2rem(8px);
		width: units.px2rem(6px);
		height: units.px2rem(10px);
		border-width: 0 units.px2rem(3px) units.px2rem(3px) 0;
	}
}

.input:checked + .optionLabel::after {
	display: block;
}

.input:disabled + .optionLabel {
	opacity: 0.65;
	cursor: not-allowed;
}
