@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(8);
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: spacing.generate(6) spacing.generate(8);
	margin-inline: auto;
	border-radius: units.px2rem(30px);
	background-color: colors.$wine-lighter;

	@include breakpoint.up(md) {
		flex-direction: row;
		padding: spacing.generate(16) spacing.generate(12);
	}
}

.title {
	margin: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	color: colors.$white;
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);
	text-align: center;
	color: colors.$white;

	@include breakpoint.up(md) {
		max-width: units.px2rem(490px);
		text-align: left;
	}
}

.text:not(:last-of-type) {
	margin-bottom: spacing.generate(6);
}

.logoWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(6);
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	max-width: units.px2rem(635px);

	@include breakpoint.up(xl) {
		flex-direction: row;
	}
}

.logoJt > svg {
	width: units.px2rem(210px);
	height: auto;
}

.logo {
	color: colors.$white;
}

.logo > svg {
	width: units.px2rem(193px);
	height: auto;
}
