@use 'sass:color';
@use 'sass:map';
@use '../../scss/settings/borders';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/scrollbar';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-top: 0;
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	text-align: center;
	color: colors.$orange;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.subtitle {
	margin-top: 0;
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);
}

.text,
.listText,
.contactText {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: typography.$line-height-base;
}

.text:last-child {
	margin-bottom: 0;
}

.listText {
	margin-bottom: spacing.generate(3);
}

.contactText {
	margin-bottom: 0;
}

.contactTitle {
	margin-block: spacing.generate(6) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: typography.$line-height-base;
}

.list {
	padding-left: spacing.generate(4);
	margin-block: spacing.generate(3) 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: typography.$line-height-base;
	word-break: break-word;
	list-style: disc;
}

.list > li:not(:last-child) {
	margin-bottom: spacing.generate(3);
}

.offset {
	padding-left: spacing.generate(4);
}
