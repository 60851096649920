@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding: spacing.generate(4);
	border-radius: units.px2rem(20px);
	box-shadow: 0 0 units.px2rem(20px) units.px2rem(10px) colors.$gray-lightest;

	@include breakpoint.up(md) {
		padding: spacing.generate(10);
	}
}

.head {
	padding: spacing.generate(14) 0 spacing.generate(8);
	text-align: center;

	@include breakpoint.up(md) {
		text-align: left;
	}
}

.pretitle {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(35px);
	line-height: 1;

	@include breakpoint.up(md) {
		margin-bottom: 0;
		font-size: units.px2rem(45px);
	}
}

.sum.sum {
	margin-bottom: spacing.generate(6);
	font-weight: bold;
	font-size: units.px2rem(50px);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(2);
		font-size: units.px2rem(100px);
	}
}

.growthLabel {
	font-size: units.px2rem(18px);
}

.growthValue {
	display: flex;
	justify-content: center;

	@include breakpoint.up(md) {
		justify-content: flex-start;
	}
}

.donut {
	display: flex;
	justify-content: center;
	margin-top: spacing.generate(10);

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.info {
	margin-top: spacing.generate(4);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-top: 0;
		text-align: left;
	}
}

.infoProfile {
	@include breakpoint.up(lg) {
		display: flex;
		align-items: center;
	}
}

.infoProfile > img {
	margin-bottom: spacing.generate(2);

	@include breakpoint.up(lg) {
		margin-right: spacing.generate(4);
		margin-bottom: 0;
	}
}

.infoLabel {
	margin-top: spacing.generate(2);
	font-size: units.px2rem(20px);
	line-height: 1;
}

.infoValue {
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	font-size: units.px2rem(26px);
	line-height: 1.1;
}

.note {
	font-size: 90%;
	text-align: left;
}

.legendNote {
	margin-top: spacing.generate(4);
	font-weight: 600;
	text-align: left;
}
