@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/transitions';
@use '../../../../scss/tools/units';

.root {
	padding-bottom: spacing.generate(3);
	text-align: center;

	@include breakpoint.up(lg) {
		max-width: 83%;
		margin-right: auto;
		margin-left: auto;
	}
}

.question {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 7);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 9);
	}

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.singleChoice {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.singleChoiceMany {
	max-width: units.px2rem(400px);
	margin: spacing.generate(10) auto;
}

.button {
	@include transitions.generate('opacity, color, border-color, background-color, transform');

	display: flex;
	align-items: center;
	justify-content: center;
	min-width: units.px2rem(200px);
	min-height: units.px2rem(46px);
	padding: 0 spacing.generate(2);
	font-weight: bold;
	font-size: map.get(typography.$font-size-values, 2);
	line-height: units.px2rem(30px);
	font-family: inherit;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;
	border: map.get(borders.$width-values, large) solid colors.$wine;
	border-radius: units.px2rem(25px);
	background-image: none;
	background-color: transparent;
	outline: 0;
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		color: colors.$red;
		border-color: colors.$red;
		opacity: 0.9;
	}

	&:active {
		transform: scale(0.95);
	}
}

.label {
	position: relative;
	margin: units.px2rem(14px) 0;
}

.error {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	color: colors.$error;
}

.input {
	position: absolute;
	width: units.px2rem(1px);
	height: units.px2rem(1px);
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.input:checked + .button,
.input:focus + .button {
	color: colors.$white;
	border-color: colors.$red;
	background-color: colors.$red;
}

.slider {
	max-width: units.px2rem(600px);
	margin: spacing.generate(4) auto spacing.generate(10);
}

.inputWrap {
	display: flex;
	justify-content: space-between;
	max-width: units.px2rem(200px);
	margin: spacing.generate(4) auto spacing.generate(10);
}

.inputWrap > div {
	flex-grow: 1;
}

.currency.currency {
	flex-grow: 0;
	margin-top: spacing.generate(4);
	margin-left: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
}

.text,
.checkboxText {
	font-weight: map.get(typography.$font-weight-values, medium);
	line-height: map.get(typography.$line-height-values, 1);
}

.text {
	margin-bottom: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 4);
}

.checkboxText {
	font-size: map.get(typography.$font-size-values, 2);
}

.checkboxText:not(:last-of-type) {
	margin-bottom: spacing.generate(2);
}

.note {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, medium);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: left;
}

.note > ul {
	padding: spacing.generate(1) 0 spacing.generate(1) spacing.generate(2);
}

.note > ul > li {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(6px);
		left: units.px2rem(-10px);
		width: units.px2rem(2px);
		height: units.px2rem(2px);
		border-radius: units.px2rem(1px);
		background-color: colors.$black;
	}
}

.smaller {
	font-size: map.get(typography.$font-size-values, 9);
}

.buttonWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(4);
	align-items: center;
	justify-content: center;
}

.tooltipWrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: spacing.generate(5);
}

.tooltip {
	padding: spacing.generate(1);
	margin-left: spacing.generate(2);
}

.offsetWrap {
	margin-top: spacing.generate(7);
}
