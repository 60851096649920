@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';
@use '../../../../scss/tools/transitions';

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: units.px2rem(1310px);
	margin: 0 auto spacing.generate(12);
}

.title {
	margin-block: spacing.generate(8) spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 9);

	@include breakpoint.up(lg) {
		margin-block: spacing.generate(12) spacing.generate(9);
	}
}

.list {
	display: grid;
	align-items: start;
	max-width: units.px2rem(600px);
	padding-inline: spacing.generate(3);

	@include breakpoint.up(lg) {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: spacing.generate(8);
		max-width: unset;
		padding-inline: spacing.generate(6);
	}
}
