@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$orange;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(2);
		font-size: map.get(typography.$font-size-values, 16);
		line-height: map.get(typography.$line-height-values, 1);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 9);
	}
}

.benefitsTitle {
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 4);
	text-align: left;
}
