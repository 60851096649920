$red-light: #f00;
$red: #e94549;
$red-dark: #b63d45;
$wine-lighter: #743a4b;
$wine-light: #851013;
$wine: #47202a;
$wine-dark: #371b1d;
$orange-lighter: #fdf7f1;
$orange-light: #fdb64a;
$orange: #f39200;
$pink: #ce8483;
$blue-light: #5897fb;
$blue: #23527c;
$blue-dark: #333b45;
$green-light: #48b958;
$green: #5bbe23;
$green-dark: #008000;
$purple: #99556a;
$error-lighter: #f2dede;
$error-light: #ebccd1;
$error: #a94442;
$success-lighter: #dff0d8;
$success-light: #d6e9c6;
$success: #3c763d;
$warning-lighter: #ffefdc;
$warning-light: #fbd09e;
$warning: #f38d16;
$brown-lightest: #f4dfbf;

$black: #000;
$white: #fff;

$gray-extra-lighter: #fafafa;
$gray-extra-light: #f5f5f5;
$gray-lightest: #f3f3f3;
$gray-lighter: #ebebeb;
$gray-light: #ddd;
$gray: #a9a9a9;
$gray-dark: #555;
$gray-darker: #333;
