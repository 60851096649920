@use 'sass:map';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.rangerWrap {
	max-width: units.px2rem(670px);
	padding-right: spacing.generate(2);
	padding-left: spacing.generate(2);
	margin: 0 auto;
}

.text {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);

	@include breakpoint.up(lg) {
		text-align: left;
	}
}
