@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/layout';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';
@use 'mixins';

.root {
	margin-block: spacing.generate(5) spacing.generate(1);
}

.headerContent {
	display: flex;
	flex-wrap: wrap;
	grid-gap: spacing.generate(4) spacing.generate(2);
	align-items: center;
}

.linkBack {
	display: block;
	margin-bottom: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 4);
	text-decoration: none;
	color: inherit;
}

.badgeButton {
	@include transitions.generate('background-color');

	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(1) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: map.get(typography.$line-height-values, 0);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;
	border: 0;
	border-radius: units.px2rem(25px);
	background-color: colors.$white;
	box-shadow: 0 0 units.px2rem(10px) 0 #{rgba(colors.$black, 0.09)};
	cursor: pointer;

	&:hover {
		color: colors.$white;
		background-color: colors.$orange-light;
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.date {
	margin-right: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 1);
	color: colors.$purple;
}

.title {
	flex: 1 0 100%;
	margin-bottom: 0;
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, -1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.perex,
.perexRich,
.perexRich > p {
	@include mixins.centered();

	width: 100%;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 6);
	}
}

.content > p {
	@include mixins.centered();

	margin-top: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.content > h2 {
	@include mixins.centered();

	margin-top: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 7);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(5);
		font-size: map.get(typography.$font-size-values, 9);
	}
}

.content > h3 {
	@include mixins.centered();

	margin-top: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 5);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(5);
		font-size: map.get(typography.$font-size-values, 6);
	}
}

.content > ul {
	@include mixins.unordered-list();
	@include mixins.centered();

	margin-top: spacing.generate(5);
}

/* stylelint-disable selector-max-compound-selectors */
.content > figure > img,
.content > figure > a > img {
	display: block;
	width: 100%;
	height: auto;
	margin-top: spacing.generate(5);
	border-radius: units.px2rem(10px);
}
/* stylelint-enable */

.content > figure > figcaption {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 2);
	color: colors.$purple;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

/* stylelint-disable selector-class-pattern */
.content :global .figure-desktop {
	display: none;

	@include breakpoint.up(md) {
		display: block;
	}
}

.content :global .figure-mobile {
	display: block;

	@include breakpoint.up(md) {
		display: none;
	}
}
/* stylelint-enable */

.contentSkeleton {
	margin-block: spacing.generate(5) spacing.generate(8);
}
