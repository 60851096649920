@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: 700;
	font-size: units.px2rem(120px);
	line-height: 1.4;
}

.subtitle {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(32px);
	line-height: 1.4;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(4);
		font-size: units.px2rem(24px);
	}
}
