@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-right: spacing.generate(1);
	padding-left: spacing.generate(1);
	margin-top: spacing.generate(10);
	margin-bottom: spacing.generate(7);
}

.title {
	margin-bottom: spacing.generate(4);
	font-weight: 500;
	font-size: units.px2rem(24px);
	line-height: 1.4;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: spacing.generate(4);
	border-collapse: collapse;
	border-spacing: 0;
}

.table td {
	padding: units.px2rem(8px);
	line-height: 1.4;
	vertical-align: middle;
	border-top: units.px2rem(1px) solid colors.$gray-light;

	&:last-child {
		text-align: right;
	}
}
