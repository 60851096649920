@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title,
.text {
	padding: 0 spacing.generate(6);
	text-shadow: 0 units.px2rem(2px) units.px2rem(12px) #{rgba(colors.$black, 0.25)};
}

.title {
	margin-bottom: spacing.generate(8);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, -2);
	text-transform: uppercase;
	color: colors.$white;

	@include breakpoint.up(sm) {
		font-size: map.get(typography.$font-size-values, 16);
	}

	@include breakpoint.up(lg) {
		margin-top: spacing.generate(6);
		margin-bottom: spacing.generate(8);
		font-size: map.get(typography.$font-size-values, 17);
	}
}

.text {
	max-width: units.px2rem(600px);
	margin: 0 auto spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$white;
}

.hero {
	position: absolute;
	inset: 0;
	z-index: -1;
	overflow: hidden;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: #{rgba(colors.$blue-dark, 0.7)};
		opacity: 0.7;
	}
}

.hero img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: blur(units.px2rem(5px));
	transform: scale(1.03);
}
