@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';
@use '../mixins';

.root {
	@include mixins.shimmer-animation();

	display: block;
	width: 100%;
	border-radius: units.px2rem(16px);
}

.gap1 {
	margin-bottom: spacing.generate(1);
}

.gap2 {
	margin-bottom: spacing.generate(2);
}
