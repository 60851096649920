@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(4);
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.helper {
	flex-grow: 1;
	margin-left: spacing.generate(4);
	font-weight: normal;
	font-size: units.px2rem(14px);
	text-align: right;
}

.inputWrap {
	position: relative;
}

.textarea {
	@include transitions.generate(box-shadow);

	appearance: none;
	display: block;
	width: 100%;
	min-height: units.px2rem(180px);
	max-height: units.px2rem(360px);
	padding: units.px2rem(6px) units.px2rem(12px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(22px);
	font-family: inherit;
	color: colors.$gray-dark;
	border: borders.$width solid colors.$gray-light;
	border-radius: units.px2rem(8px);
	resize: vertical;

	&:focus {
		outline: 0;
	}
}

.error {
	display: block;
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	text-align: left;
	color: colors.$error;
}

.rootError > .inputWrap > .textarea:focus {
	box-shadow:
		inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
		0 0 units.px2rem(6px) colors.$pink;
}
