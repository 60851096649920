@use 'sass:map';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../mixins';

.root {
	@include mixins.post-box();
}

.content {
	@include mixins.centered();
}

.title {
	@include mixins.post-box-title();

	margin-bottom: spacing.generate(3);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(5);
	}
}

.list {
	list-style-type: none;
	counter-reset: list;
}

.item::before,
.link {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
}

.item {
	position: relative;
	padding-left: spacing.generate(5);
	margin-bottom: spacing.generate(2);

	@include breakpoint.up(md) {
		padding-left: spacing.generate(6);
		margin-bottom: spacing.generate(3);
	}
}
