@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint.up(md) {
		align-items: flex-start;
	}
}

.form {
	width: 100%;
}

.selectWrap {
	margin-bottom: spacing.generate(3);
}

.buttons {
	display: flex;
	justify-content: center;
}
