@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/transitions' as transitions-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	display: flex;
	align-items: center;
	min-width: units.px2rem(260px);
	margin-bottom: spacing.generate(8);
	font-weight: 600;
	text-decoration: none;
	color: colors.$wine;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$wine;
	}
}

.icon {
	flex-shrink: 0;
	width: 17%;
	padding-right: spacing.generate(3);
	margin-right: units.px2rem(7px);
	text-align: left;

	@include breakpoint.up(md) {
		width: 25%;
	}
}

.icon > svg {
	display: block;
	width: 100%;
	max-width: units.px2rem(60px);
	height: auto;
}

.text {
	display: flex;
	flex-direction: column;
}

.title {
	font-size: units.px2rem(14px);
	line-height: 1;
	text-align: left;
}

.link {
	@include transitions.generate(
		color,
		map.get(transitions-settings.$durations, slow)
	);

	font-size: units.px2rem(26px);
	text-decoration: underline;
	color: colors.$red;
}

.root:hover > .text > .link,
.root:focus > .text > .link {
	text-decoration: none;
	color: colors.$wine-light;
}
