@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: map.get(typography.$line-height-values, 0);
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	border-radius: units.px2rem(25px);
	box-shadow: 0 0 units.px2rem(10px) 0 #{rgba(colors.$black, 0.09)};

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.tiny,
.smaller {
	padding-inline: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: map.get(typography.$line-height-values, 0);
	text-transform: none;
	border-radius: units.px2rem(4px);
	box-shadow: none;
}

.tiny {
	font-size: map.get(typography.$font-size-values, -2);
}

.smaller {
	font-size: map.get(typography.$font-size-values, -2);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 0);
	}
}

.small {
	padding: spacing.generate(1) spacing.generate(3);
	font-size: map.get(typography.$font-size-values, -2);

	@include breakpoint.up(lg) {
		padding: spacing.generate(2) spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.medium {
	padding: spacing.generate(2) spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 0);
}

.white {
	color: colors.$wine;
	background-color: colors.$white;
}

.orange {
	color: colors.$white;
	background-color: colors.$orange;
}

.wine {
	color: colors.$white;
	background-color: colors.$wine-lighter;
}

.blue {
	color: colors.$white;
	background-color: colors.$blue;
}
