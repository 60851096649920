@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	text-align: center;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(10);
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.wrap {
	padding: spacing.generate(4);
	margin-bottom: spacing.generate(10);
	text-align: center;
	border-radius: units.px2rem(20px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(4px) units.px2rem(30px) colors.$gray-light;

	@include breakpoint.up(md) {
		padding: spacing.generate(8);
		margin-bottom: spacing.generate(12);
	}
}
