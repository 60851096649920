@use 'sass:math';
@use '../../../scss/settings/arrow' as arrow-settings;
@use '../../../scss/settings/colors';
@use '../../../scss/tools/arrow';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root,
.link {
	display: flex;
	align-items: center;
	justify-content: center;
	height: arrow-settings.$size;
}

.root {
	position: absolute;
	right: 0;
	bottom: math.div(arrow-settings.$size, -2);
	left: 0;
	z-index: 1;
}

.link {
	@include arrow.generate();

	font-size: 0;
	text-decoration: none;
	border-width: units.px2rem(1px);
}
