@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.root {
	@include button.generate();
}

.root:disabled {
	@include button.disabled();
}

.root > svg {
	@include button.svg();
}

.orange {
	background-color: colors.$orange;

	&:hover,
	&:focus {
		border-color: colors.$orange-light;
		background-color: colors.$orange-light;
	}
}

.narrow {
	@include button.narrow();
}

.outline {
	@include button.outline();
}

.outline > svg {
	@include button.outline-svg();
}

.flat {
	@include button.flat();
}

.small {
	@include button.small();
}

.small.outline {
	@include button.small-outline();
}

.variantLink {
	@include button.reset();
	@include typography.link();

	&:active:not(:disabled) {
		color: inherit;
		background-color: inherit;
		transform: none;
	}
}

.long {
	@include button.long();
}

.tooltipWrap {
	position: relative;
	display: inline-block;
}

.tooltip {
	@include transitions.generate('visibility, opacity');

	position: absolute;
	top: calc(100% + #{units.px2rem(30px)});
	left: 50%;
	z-index: 1;
	width: units.px2rem(250px);
	text-align: initial;
	white-space: initial;
	border-radius: units.px2rem(8px);
	background-color: colors.$white;
	box-shadow: 0 0 units.px2rem(25px) 0 rgb(0 0 0 / 20%);
	visibility: hidden;
	opacity: 0;
	transform: translateX(-50%);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		z-index: -1;
		width: units.px2rem(20px);
		height: units.px2rem(20px);
		background-color: colors.$white;
		box-shadow: 0 0 units.px2rem(25px) 0 rgb(0 0 0 / 20%);
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}
}

.tooltip > p {
	width: units.px2rem(250px);
	padding: spacing.generate(4);
	font-weight: 500;
	line-height: 1.2;
	border-radius: units.px2rem(8px);
	background-color: colors.$white;
}

.tooltipWrap:hover > .tooltip,
.tooltipWrap:focus > .tooltip {
	visibility: visible;
	opacity: 1;
}

.outlineHeavy {
	@include button.outline-heavy();
}
