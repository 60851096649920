@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(4);

	&:only-child {
		margin-bottom: 0;
	}
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.small > .labelWrap {
	margin-bottom: 0;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.selectFieldWrap {
	display: block;
	width: 100%;
}

.error {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	color: colors.$error;
}

/* stylelint-disable selector-class-pattern */
.selectFieldWrap :global .selectField__control {
	@include transitions.generate(box-shadow);

	appearance: none;
	height: units.px2rem(56px);
	padding: units.px2rem(6px) 0 units.px2rem(6px) units.px2rem(12px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(35px);
	font-family: inherit;
	text-align: left;
	color: colors.$gray-dark;
	border: borders.$width solid colors.$gray-light;
	border-radius: units.px2rem(8px);
}

.selectFieldWrap :global .selectField__value-container {
	min-height: units.px2rem(40px);
	padding: 0;
}

.selectFieldWrap :global .selectField__control.selectField__control--is-focused,
.selectFieldWrap :global .selectField__control.selectField__control--is-focused:hover {
	outline: 0;
	box-shadow:
		inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
		0 0 units.px2rem(8px) rgb(102 175 233 / 60%);
}

.selectFieldWrap :global .selectField__indicator.selectField__dropdown-indicator {
	padding: units.px2rem(8px) units.px2rem(4px);
	color: colors.$wine;

	@include breakpoint.up(sm) {
		padding: units.px2rem(8px);
	}
}

.selectFieldWrap :global .selectField__indicator-separator {
	display: none;
}

.selectFieldWrap :global .selectField__menu {
	z-index: 100;
	margin: 0;
	border: units.px2rem(1px) solid colors.$gray-dark;
	border-top: 0;
	border-radius: 0 0 units.px2rem(4px) units.px2rem(4px);
	background-color: colors.$white;
	box-shadow: none;
}

.selectFieldWrap :global .selectField__menu-list {
	max-height: units.px2rem(200px);
	padding: 0;
	border: 0;
}

.selectFieldWrap :global .selectField__option {
	display: flex;
	align-items: center;
	padding: units.px2rem(6px);
	text-align: left;
	color: colors.$wine;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.selectFieldWrap :global .selectField__option > img {
	margin-right: spacing.generate(2);
}

.selectFieldWrap :global .selectField__option--is-focused {
	color: colors.$white;
	background-color: colors.$blue-light;
}

.selectFieldWrap :global .selectField__option--is-selected {
	color: colors.$wine;
	background-color: colors.$gray-light;
}

.selectFieldWrap :global .selectField__single-value {
	display: flex;
	align-items: center;
	color: colors.$gray-dark;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.selectFieldWrap :global .selectField__single-value > img {
	margin-right: spacing.generate(2);
	vertical-align: middle;
}

.selectFieldWrap :global .selectField__placeholder {
	text-align: left;
	color: colors.$gray-dark;
}

.selectFieldWrap :global .selectField__control--is-disabled {
	background-color: #{rgba(239, 239, 239, 0.3)};
}

.selectFieldWrap :global .selectField__option--is-disabled {
	cursor: not-allowed;
}

.small :global .selectField__value-container {
	min-height: units.px2rem(30px);
}

.small :global .selectField__control {
	height: units.px2rem(36px);
	min-height: units.px2rem(36px);
	padding-block: 0;
}
