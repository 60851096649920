@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	min-height: units.px2rem(380px);
	text-align: left;
}

.rootOnlyChart {
	min-height: units.px2rem(315px);
}

.chartWrap {
	position: relative;
	overflow-x: scroll;

	@include breakpoint.up(lg) {
		overflow-x: auto;
	}
}

.chart {
	overflow-y: hidden;
}

.legendTitle {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-size: units.px2rem(18px);
	line-height: 1.5;
}

.legendList {
	display: flex;
	flex-wrap: wrap;
}

.legendListItem {
	display: flex;
	align-items: center;
	margin-right: units.px2rem(30px);
	margin-bottom: spacing.generate(1);
}

.legendCircle {
	display: block;
	width: units.px2rem(15px);
	height: units.px2rem(15px);
	margin-right: spacing.generate(2);
	border-radius: 50%;
}

.legendCircleValue {
	background-color: colors.$green-light;
}

.legendCircleIncrease {
	background-color: colors.$orange;
}

.legendCircleDecrease {
	background-color: colors.$purple;
}

.loading {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: spacing.generate(4) 0;
}

.loading > p {
	margin-bottom: units.px2rem(30px);
	font-weight: 700;
	font-size: units.px2rem(24px);
}

.chart .tooltipGroup {
	display: block;
}

.loadButton {
	@include button.generate();

	position: absolute;
	top: spacing.generate(3);
	left: spacing.generate(23);
	z-index: 1;
	width: units.px2rem(28px);
	height: units.px2rem(28px);
	padding: spacing.generate(1);
	opacity: 0.5;

	@include breakpoint.up(sm) {
		padding: spacing.generate(1);
	}
}

.loadButton:disabled {
	display: none;
}
