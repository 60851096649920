@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-right: spacing.generate(4);
	padding-bottom: spacing.generate(2);
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(10);
	border-bottom: units.px2rem(1px) solid colors.$gray-lighter;
}

.link {
	display: inline-block;
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.2;
}

.alert {
	max-width: units.px2rem(300px);
	margin-right: auto;
	margin-left: auto;
	text-align: left;
}
