@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.button {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0;
	margin-bottom: spacing.generate(4);
	border: 0;
	background: transparent;
	cursor: pointer;
}

.buttonIcon {
	flex-shrink: 0;
	width: 17%;
	padding-right: spacing.generate(3);
	margin-right: units.px2rem(7px);
	text-align: left;

	@include breakpoint.up(md) {
		width: 25%;
	}
}

.buttonIcon > svg {
	display: block;
	width: 100%;
	max-width: units.px2rem(60px);
	height: auto;
}

.buttonText {
	@include transitions.generate('color, transform');

	display: flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(2) spacing.generate(4);
	font-weight: bold;
	font-size: units.px2rem(16px);
	line-height: 1.4286;
	text-transform: uppercase;
	color: colors.$red;
	border: units.px2rem(1px) solid colors.$red;
	border-radius: units.px2rem(40px);
	background: transparent;
}

.button:hover > .buttonText,
.button:focus > .buttonText {
	color: colors.$wine-light;
}

.button:active > .buttonText {
	border-color: colors.$red-dark;
	transform: scale(0.95);
}

.modal {
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	@include breakpoint.up(lg) {
		width: 75%;
	}
}
