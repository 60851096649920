@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/transitions' as transitions-settings;
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';
@use '../../layout/variables';

.wrap {
	position: relative;
	height: units.px2rem(200px);

	@include breakpoint.up(md) {
		height: units.px2rem(110px);
	}
}

.root {
	@include transitions.generate(transform);
	@include transitions.generate(
		box-shadow,
		map.get(transitions-settings.$durations, fast)
	);

	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	height: units.px2rem(200px);
	padding: spacing.generate(4) 0 0;
	background-color: colors.$white;
	box-shadow: none;

	@include breakpoint.up(md) {
		height: units.px2rem(120px);
		padding-bottom: spacing.generate(4);
	}
}

.fixed {
	position: fixed;
	z-index: 120;
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$black, $alpha: -0.8);
}

.fixed.collapsed {
	transform: translateY(units.px2rem(-190px));

	@include breakpoint.up(md) {
		transform: translateY(units.px2rem(-110px));
	}

	@include breakpoint.up(lg) {
		transform: translateY(0);
	}
}

.button {
	@include transitions.generate('color, background-color, transform');

	appearance: button;
	display: inline-block;
	padding: units.px2rem(7px) units.px2rem(16px);
	margin: spacing.generate(2) 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);
	font-family: inherit;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	vertical-align: middle;
	color: colors.$white;
	border: units.px2rem(1px) solid colors.$red;
	border-radius: 50px;
	background-color: colors.$red;
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		text-decoration: none;
		opacity: 0.9;
	}

	&:disabled {
		opacity: 0.65;
		cursor: not-allowed;
	}

	&:active {
		background-color: colors.$red-dark;
		transform: scale(0.95);
	}

	@include breakpoint.up(sm) {
		padding: units.px2rem(7px) units.px2rem(24px);

		&::before {
			content: attr(data-pretitle);
		}
	}
}

.active {
	background-color: colors.$red-dark;
}

.collapseTrigger {
	appearance: button;
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100% + #{variables.$layout-container-offset} * 2);
	padding: spacing.generate(4) 0;
	margin-left: -1 * variables.$layout-container-offset;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
	font-family: inherit;
	text-align: center;
	border: 0;
	background: colors.$white;
	box-shadow: none;

	@include breakpoint.up(lg) {
		display: none;
	}
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'dates dates'
		'radio select';
	max-width: units.px2rem(700px);

	@include breakpoint.up(md) {
		grid-template-columns: 2fr 1fr;
		grid-template-areas:
			'dates select'
			'radio radio';
	}
}

.dateWrap {
	display: flex;
	flex-direction: row;
	grid-area: dates;
}

.selectWrap {
	grid-area: select;
}

.select {
	width: 100%;
	max-width: units.px2rem(105px);
}

.radioWrap {
	grid-area: radio;
}
