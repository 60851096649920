@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';

.root {
	display: block;
	margin-bottom: spacing.generate(3);
}

.horizontal {
	display: flex;
	flex-flow: column wrap;
	gap: spacing.generate(2);

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(4);
		margin-bottom: spacing.generate(0);
	}
}
