@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';

.title {
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: center;

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 14);
		line-height: map.get(typography.$line-height-values, 1);
	}
}

.text {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: map.get(typography.$line-height-values, 1);
	color: colors.$gray-dark;
}
