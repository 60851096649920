@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';

.title {
	margin-block: spacing.generate(2) spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 10);
	line-height: map.get(typography.$line-height-values, 1);
}

.text {
	margin-block: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
}
