@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	padding-bottom: spacing.generate(8);
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.root:not(:first-child) {
	padding-top: spacing.generate(8);
}

.root.isSmall {
	padding-bottom: spacing.generate(5);
}

.root.isSmall:not(:first-child) {
	padding-top: spacing.generate(5);
}

.title {
	padding-right: spacing.generate(6);
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, medium);
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.title.isSmall {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 7);
}

.collapse {
	@include transitions.generate(opacity);

	height: 0;
	overflow-y: hidden;
	opacity: 0;
}

.collapseTrigger {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 0 spacing.generate(3);
	text-decoration: none;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	&::after {
		@include transitions.generate(transform);

		content: '';
		position: absolute;
		top: 50%;
		right: spacing.generate(4);
		width: units.px2rem(17px);
		height: units.px2rem(17px);
		border-width: map.get(borders.$width-values, large) 0 0 map.get(borders.$width-values, large);
		border-style: solid;
		border-color: colors.$red;
		transform: translateY(-50%) rotate(225deg);
		transform-origin: center;
	}
}

.isSmall .collapseTrigger {
	padding: 0;
}

.isRootOpen > .collapse {
	@include transitions.generate(opacity);

	height: auto;
	margin-top: spacing.generate(4);
	overflow-y: visible;
	opacity: 1;
}

.isRootOpen > .collapseTrigger::after {
	transform: rotate(45deg);
}

.isRootDisabled .title {
	opacity: 0.5;
}

.disabledMessage {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	color: colors.$red;
}

.icon {
	padding-right: spacing.generate(2);
}
