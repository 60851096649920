@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
	display: flex;
	align-items: flex-end;
	max-width: units.px2rem(1440px);
	min-height: units.px2rem(270px);
	padding: 0 spacing.generate(3) spacing.generate(3) spacing.generate(2);
	margin: spacing.generate(2) auto 0 auto;

	@include breakpoint.up(md) {
		min-height: units.px2rem(300px);
		padding-bottom: spacing.generate(8);
		margin-top: 0;
	}

	@include breakpoint.up(lg) {
		min-height: units.px2rem(330px);
	}
}

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	padding: spacing.generate(3) spacing.generate(1.5);
	margin-inline: auto;
	border-radius: units.px2rem(20px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(12px) units.px2rem(60px) units.px2rem(-10px) #{rgba(colors.$black, 0.3)};

	@include breakpoint.up(sm) {
		width: 100%;
		max-width: units.px2rem(865px);
		padding: spacing.generate(5) spacing.generate(2);
	}

	@include breakpoint.up(md) {
		flex-direction: row;
		padding: spacing.generate(5) spacing.generate(12);
	}
}

.copy {
	margin-bottom: spacing.generate(3);
	text-align: center;

	@include breakpoint.up(md) {
		margin-bottom: 0;
		text-align: left;
	}
}

.title {
	max-width: units.px2rem(280px);
	margin-inline: auto;
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 10);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		max-width: 100%;
		margin-bottom: spacing.generate(1);
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.bubbles {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.bubble {
	position: absolute;
	display: block;
	border-radius: 50%;
}

.bubble1 {
	top: units.px2rem(45px);
	right: units.px2rem(-105px);
	width: units.px2rem(280px);
	height: units.px2rem(280px);
	background-color: colors.$red;
	opacity: 0.9;

	@include breakpoint.up(sm) {
		right: units.px2rem(-35px);
		width: units.px2rem(430px);
		height: units.px2rem(430px);
	}

	@include breakpoint.up(md) {
		top: units.px2rem(95px);
		right: 0;
	}

	@include breakpoint.up(xl) {
		right: units.px2rem(170px);
	}
}

.bubble2 {
	top: 0;
	left: 50%;
	width: units.px2rem(395px);
	height: units.px2rem(395px);
	background-color: colors.$orange;
	transform: translateX(-50%);

	@include breakpoint.up(md) {
		width: units.px2rem(805px);
		height: units.px2rem(805px);
	}
}

.bubble3 {
	top: units.px2rem(45px);
	left: units.px2rem(-105px);
	width: units.px2rem(280px);
	height: units.px2rem(280px);
	background-color: colors.$purple;
	opacity: 0.9;

	@include breakpoint.up(sm) {
		left: units.px2rem(-35px);
		width: units.px2rem(340px);
		height: units.px2rem(340px);
	}

	@include breakpoint.up(md) {
		top: units.px2rem(95px);
		left: 0;
	}

	@include breakpoint.up(xl) {
		left: units.px2rem(170px);
	}
}
