@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(920px);
	margin-top: spacing.generate(15);
	margin-right: auto;
	margin-left: auto;

	@include breakpoint.up(md) {
		margin-top: spacing.generate(30);
	}
}

.block {
	max-width: units.px2rem(600px);
}

.titleWrap {
	display: flex;
}

.title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 5);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 13);
	}
}

.status {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: units.px2rem(30px);
	height: units.px2rem(30px);
	margin-left: spacing.generate(3);
	border-radius: 50%;
	background: colors.$white;
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(sm) {
		width: units.px2rem(50px);
		height: units.px2rem(50px);
	}
}

.status > svg {
	width: units.px2rem(18px);
	height: units.px2rem(18px);

	@include breakpoint.up(sm) {
		width: units.px2rem(30px);
		height: units.px2rem(30px);
	}
}

.text {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 2);
}

.line {
	margin: spacing.generate(6) 0;
	border-top: borders.$width solid colors.$gray-lighter;
	border-right: 0;
	border-bottom: 0;
	border-left: 0;
}

.modalClose {
	text-align: center;
}
