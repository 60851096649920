@use '../../../../scss/settings/colors';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/chart';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/transitions';
@use '../../../../scss/tools/units';

.chartWrap {
	@include chart.wrap();

	margin: 0 auto;
}

.chart {
	@include chart.generate();
}

.chart svg {
	overflow: visible;
}

.chart path {
	@include transitions.generate('transform, transform-origin, filter');

	filter: none;
	cursor: pointer;

	&:hover,
	&:focus {
		filter: drop-shadow(units.px2rem(3px) units.px2rem(3px) units.px2rem(3px) #{rgba(colors.$black, 0.25)});
		transform: scale(1.035);
		transform-origin: center;
	}
}
