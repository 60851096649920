@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/layout';
@use '../../../scss/settings/typography' as typography-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.root {
	margin: 0;
}

.wrapper {
	max-width: units.px2rem(730px);
	margin-inline: auto;
}

.title {
	@include typography.generate(title);
}

.text {
	@include typography.generate(description);

	margin-bottom: spacing.generate(4);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(12);
	}
}

.loaderWrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: spacing.generate(4);
	margin-bottom: spacing.generate(5);
	border-radius: units.px2rem(10px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(2px) units.px2rem(35px) #{rgba(colors.$black, 0.1)};

	@include breakpoint.up(md) {
		flex-direction: row;
		padding: spacing.generate(7);
		margin-bottom: spacing.generate(8);
	}
}

.priceWrap,
.flowWrap {
	@include breakpoint.up(md) {
		flex-grow: 1;
	}
}

.range {
	margin-bottom: spacing.generate(2);
	color: colors.$gray-dark;
}

.flowWrap {
	@include breakpoint.up(md) {
		flex-shrink: 0;
		margin-left: spacing.generate(6);
		text-align: left;
	}
}

.priceLabel,
.price,
.flowLabel {
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
}

.priceLabel {
	font-size: map.get(typography-settings.$font-size-values, 9);
}

.flowLabel {
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography-settings.$font-size-values, 7);
}

.priceFlowWrap {
	display: flex;
	grid-gap: spacing.generate(4);
	justify-content: center;

	@include breakpoint.up(md) {
		justify-content: flex-start;
	}
}

.source,
.medium {
	font-weight: map.get(typography-settings.$font-weight-values, medium);
}

.price {
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography-settings.$font-size-values, 13);

	@include breakpoint.up(md) {
		margin-bottom: 0;
		font-size: map.get(typography-settings.$font-size-values, 15);
	}
}

.priceFlow {
	display: inline-flex;
	align-items: center;
	font-size: map.get(typography-settings.$font-size-values, 2);
	line-height: map.get(typography-settings.$line-height-values, -1);
}

.iconPriceFlow {
	display: inline-block;
	height: units.px2rem(14px);
	margin-right: spacing.generate(1);
}

.priceFlowNegative > .iconPriceFlow {
	transform: rotate(90deg);
}

.slider {
	margin-right: spacing.generate(2);
	margin-left: spacing.generate(2);

	@include breakpoint.up(md) {
		margin-right: spacing.generate(4);
		margin-left: spacing.generate(4);
	}
}

.instrumentListWrap {
	margin-right: -1 * layout.$layout-offset;
	margin-bottom: spacing.generate(5);
	margin-left: -1 * layout.$layout-offset;
	overflow-x: auto;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(11);
		overflow-x: hidden;
	}
}

.instrumentList {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(2) spacing.generate(2) spacing.generate(5) spacing.generate(2);
}

.instrumentItem {
	padding-right: spacing.generate(1);
	padding-left: spacing.generate(1);

	&:first-child {
		padding-left: layout.$layout-offset;
	}

	&:last-child {
		padding-right: layout.$layout-offset;
	}

	@include breakpoint.up(md) {
		padding-right: spacing.generate(2);
		padding-left: spacing.generate(2);
	}
}

.button {
	@include transitions.generate('border-color, box-shadow');

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: units.px2rem(60px);
	height: units.px2rem(60px);
	padding: spacing.generate(1);
	border: borders.$width solid colors.$gray-lighter;
	border-radius: 50%;
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(2px) units.px2rem(15px) transparent;
}

.buttonActive,
.button:hover,
.button:focus {
	border-color: colors.$red;
	box-shadow: 0 units.px2rem(2px) units.px2rem(15px) #{rgba(colors.$red, 0.3)};
	cursor: pointer;
}

.icon {
	width: auto;
	height: units.px2rem(30px);
}

.source {
	font-size: map.get(typography-settings.$font-size-values, 2);
}

.bubble {
	position: absolute;
	bottom: -15vw;
	left: -8vw;
	display: block;
	width: 18vw;
	height: 18vw;
	border-radius: 50%;
	background-color: colors.$orange;
	opacity: 0.9;

	@include breakpoint.up(md) {
		bottom: -5vw;
		left: -5vw;
		width: 15vw;
		height: 15vw;
	}
}
