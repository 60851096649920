@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: block;
	margin-bottom: spacing.generate(4);
	text-align: left;
}

.noError {
	margin-bottom: 0;
}

.inputWrap {
	position: relative;
	display: inline-flex;
	align-items: center;
}

.input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: inherit;
}

.label {
	position: relative;
	z-index: 2;
	display: block;
	padding-top: units.px2rem(1px);
	padding-left: units.px2rem(18px) + units.px2rem(7px);
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: units.px2rem(18px);
	color: colors.$wine;
	user-select: none;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: units.px2rem(18px);
		height: units.px2rem(18px);
		border: units.px2rem(1px) solid colors.$gray-light;
		border-radius: units.px2rem(3.5px);
	}

	&::after {
		content: '';
		position: absolute;
		top: units.px2rem(4px);
		left: units.px2rem(6.5px);
		display: none;
		width: units.px2rem(5px);
		height: units.px2rem(8.5px);
		border: solid colors.$orange;
		border-width: 0 units.px2rem(2.5px) units.px2rem(2.5px) 0;
		transform: rotate(45deg);
	}
}

.isLabelLarge {
	padding-left: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: units.px2rem(22px);

	&::before {
		width: units.px2rem(22px);
		height: units.px2rem(22px);
	}

	&::after {
		top: units.px2rem(5px);
		left: units.px2rem(8px);
		width: units.px2rem(6px);
		height: units.px2rem(10px);
		border-width: 0 units.px2rem(3px) units.px2rem(3px) 0;
	}
}

.input:checked + .label::after {
	display: block;
}

.input:disabled + .label {
	opacity: 0.65;
	cursor: not-allowed;
}

.input:focus + .label::before {
	box-shadow: inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%), 0 0 units.px2rem(8px) rgb(102 175 233 / 60%);
}

.error {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	color: colors.$error;
}

.tooltip {
	margin-left: spacing.generate(1);
}

.noLabel {
	&::before {
		top: units.px2rem(-15px);
	}

	&::after {
		top: units.px2rem(-11px);
	}
}
