@use '../../scss/settings/colors';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(45px);
	line-height: 1.1;
}

.subtitle {
	margin-top: spacing.generate(2);
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(14px);
	line-height: 1.1;
}

.boxText {
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(3);
	text-align: center;
}

.tradingDay {
	margin-top: spacing.generate(6);
}

.linkText {
	display: inline-flex;
	align-items: center;
	margin-top: spacing.generate(6);
	text-decoration: none;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: inherit;
	}
}

.linkText > svg {
	width: units.px2rem(12px);
	height: units.px2rem(12px);
	margin-left: units.px2rem(6px);
}
