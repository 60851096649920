@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: spacing.generate(3) spacing.generate(2);
	border-bottom: borders.$width solid colors.$gray-lightest;

	@include breakpoint.up(lg) {
		@include transitions.generate('box-shadow');

		padding: spacing.generate(2);
		border-bottom: 0;
		border-radius: spacing.generate(1);
	}
}

.wrap.forcedMobile {
	@include transitions.generate('background-color');

	background-color: transparent;

	@include breakpoint.up(lg) {
		padding: spacing.generate(3) spacing.generate(2);
		border-bottom: borders.$width solid colors.$gray-lightest;
	}
}

.wrap:hover {
	box-shadow: 0 units.px2rem(8px) units.px2rem(30px) units.px2rem(-10px) #{rgba(colors.$black, 0.2)};
}

.wrap.forcedMobile:hover {
	background-color: colors.$white;
}

.wrap:first-child {
	padding-top: spacing.generate(2);
}

.wrap:last-child {
	padding-bottom: spacing.generate(2);
	border-bottom: 0;
}

.postLink {
	position: absolute;
	inset: 0;
	z-index: 60;
}

.header {
	display: flex;
	flex-direction: row;
	gap: spacing.generate(2);
	margin-bottom: spacing.generate(2);

	@include breakpoint.up(lg) {
		flex-direction: column;
	}

	@include breakpoint.up(xl) {
		gap: spacing.generate(3);
		margin-bottom: spacing.generate(3);
	}
}

.header.forcedMobile {
	@include breakpoint.up(lg) {
		flex-direction: row;
		gap: spacing.generate(2);
		margin-bottom: spacing.generate(2);
	}
}

.header.largePreview {
	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.info {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include breakpoint.up(lg) {
		padding-inline: spacing.generate(1);
	}

	@include breakpoint.up(xl) {
		padding-inline: spacing.generate(2);
	}
}

.info.forcedMobile {
	@include breakpoint.up(lg) {
		padding-inline: 0;
	}
}

.info.largePreview {
	@include breakpoint.up(lg) {
		width: 65%;
	}
}

.imageWrap {
	position: relative;
	width: units.px2rem(130px);
	min-width: units.px2rem(130px);
	height: auto;
	min-height: units.px2rem(70px);

	@include breakpoint.up(md) {
		width: units.px2rem(160px);
		min-width: units.px2rem(160px);
	}

	@include breakpoint.up(lg) {
		width: 100%;
		height: auto;
	}
}

.imageWrap.forcedMobile {
	@include breakpoint.up(md) {
		width: units.px2rem(90px);
		min-width: units.px2rem(90px);
	}

	@include breakpoint.up(xl) {
		width: units.px2rem(120px);
		min-width: units.px2rem(120px);
	}
}

.imageWrap.largePreview {
	@include breakpoint.up(lg) {
		width: 35%;
	}
}

.content {
	width: 100%;

	@include breakpoint.up(lg) {
		padding-inline: spacing.generate(1);
	}

	@include breakpoint.up(xl) {
		padding-inline: spacing.generate(2);
	}
}

.content.forcedMobile {
	@include breakpoint.up(lg) {
		padding-inline: 0;
	}
}

.image {
	display: block;
	width: 100%;
	height: auto;
}

.badgeWrap {
	position: absolute;
	bottom: spacing.generate(2);
	left: spacing.generate(2);
	z-index: 70;
	display: none;
	flex-flow: row wrap;
	gap: spacing.generate(1);

	@include breakpoint.up(lg) {
		display: flex;
	}
}

.badgeWrap.forcedMobile {
	@include breakpoint.up(lg) {
		display: none;
	}
}

.badgeWrapMobile {
	position: relative;
	z-index: 70;
	display: block;
	margin-top: spacing.generate(2);

	@include breakpoint.up(lg) {
		display: none;
	}
}

.badgeWrapMobile.forcedMobile {
	@include breakpoint.up(lg) {
		display: block;
	}
}

.badgeButton {
	@include transitions.generate('background-color');

	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(1) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	line-height: map.get(typography.$line-height-values, 0);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;
	border: 0;
	border-radius: units.px2rem(25px);
	background-color: colors.$white;
	box-shadow: 0 0 units.px2rem(10px) 0 #{rgba(colors.$black, 0.09)};
	cursor: pointer;

	&:hover {
		color: colors.$white;
		background-color: colors.$orange-light;
	}

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.date {
	display: block;
	margin-bottom: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, -2);
	line-height: map.get(typography.$line-height-values, 2);
	color: colors.$gray;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(2);
		font-size: map.get(typography.$font-size-values, 3);
	}

	@include breakpoint.up(xl) {
		margin-bottom: spacing.generate(3);
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.date.forcedMobile {
	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(1);
		font-size: map.get(typography.$font-size-values, -2);
	}
}

.title {
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 6);
	}

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.title.forcedMobile {
	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.text {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 3);
	}

	@include breakpoint.up(xl) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.text.forcedMobile {
	@include breakpoint.up(lg) {
		margin-bottom: 0;
		font-size: map.get(typography.$font-size-values, 0);
	}
}
