@use '../../../scss/settings/breakpoints';
@use '../../../scss/settings/layout' as layout-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/layout';
@use '../mixins';
@use '../variables';

.root {
	@include mixins.grid-item();
}

@each $key, $value in breakpoints.$values {
	$name: $key;

	@include breakpoint.up($name) {
		@for $i from 0 through layout-settings.$layout-grid-columns {
			.#{$name}-#{$i} {
				@include layout.grid-part($i);
			}
		}
	}
}
