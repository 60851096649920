@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/transitions' as transitions-settings;
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.info {
	padding-bottom: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 0);
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(3);

	@include breakpoint.up(sm) {
		flex-wrap: nowrap;
	}
}

.tooltip {
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
	justify-content: flex-end;
	padding-block: spacing.generate(12) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-block: spacing.generate(3);
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.title {
	padding-right: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 7);
}
