@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';
@use '../mixins';

.root {
	display: block;
	width: 100%;
}

.row {
	@include mixins.shimmer-animation();

	width: 100%;
	font-size: inherit;
	border-radius: units.px2rem(4px);
}

.row::before {
	content: '\00a0';
}

.lastRow {
	width: 80%;
}

.gap1 {
	margin-bottom: spacing.generate(1);
}

.gap2 {
	margin-bottom: spacing.generate(2);
}
