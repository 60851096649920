@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.root.noActivePortfolio {
	border-bottom: 0;
}

.wrapper {
	padding-block: spacing.generate(4) spacing.generate(3);
}

.root.noActivePortfolio .wrapper {
	position: relative;
	bottom: spacing.generate(-6);
	padding-bottom: 0;
	margin-top: spacing.generate(6);

	@include breakpoint.up(md) {
		margin-top: spacing.generate(20);
	}
}

.child {
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
}

.inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.root.noActivePortfolio .inner {
	justify-content: center;
}

.name {
	overflow: hidden;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 12);
	line-height: map.get(typography.$line-height-values, 0);
	text-overflow: ellipsis;
	color: colors.$orange;
}

.root.noActivePortfolio .name {
	display: none;
}

.archive {
	@include transitions.generate(color);

	display: inline-flex;
	gap: spacing.generate(1);
	font-size: map.get(typography.$font-size-values, 0);
	text-decoration: underline;
	color: colors.$red;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$red-dark;
	}
}

.archiveImage {
	margin-right: spacing.generate(2);
}

.archiveTitle {
	padding-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	border-bottom: map.get(borders.$width-values, large) solid colors.$wine;
}

.archiveList {
	display: flex;
	flex-direction: column;
}

.archiveItem {
	display: flex;
	align-items: center;
	padding-block: spacing.generate(2);
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.archiveName {
	width: 100%;
	overflow-x: hidden;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	text-align: left;
	text-overflow: ellipsis;
}

.badgeWrap span {
	margin-right: spacing.generate(2);
	white-space: nowrap;
}
