@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';

/* stylelint-disable selector-class-pattern */
.tippy :global .tippy-content {
	font-weight: normal;
	font-size: units.px2rem(14px);
	line-height: 1.4;
	text-align: left;
	white-space: normal;
	color: colors.$white;
}

.tippy :global a {
	text-decoration: underline;
	color: colors.$white;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}
/* stylelint-enable */
