@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(4);
	text-align: left;
}

.center {
	text-align: center;
}

.disabled {
	opacity: 0.5;
}

.noError {
	margin-bottom: 0;
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.helper {
	flex-grow: 1;
	margin-left: spacing.generate(3);
	font-weight: normal;
	font-size: units.px2rem(14px);
	text-align: right;
}

.inputWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(3);

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: 0;
	}
}

.input {
	display: none;
}

.error,
.warning {
	display: block;
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	text-align: left;
}

.error {
	color: colors.$error;
}

.warning {
	color: colors.$orange;
}

.tooltip {
	margin-left: spacing.generate(1);
}

.fileWrap {
	display: flex;
	gap: spacing.generate(2);
	align-items: center;
	justify-content: center;
	padding: spacing.generate(1) spacing.generate(2);
}

.fileName,
.fileRemove {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
}
