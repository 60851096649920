@use 'sass:map';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/transitions';
@use '../../../../scss/tools/units';

.wrap {
	display: flex;
	flex-direction: column;
	margin-bottom: spacing.generate(6);
}

.title {
	padding-block: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: left;
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-block: spacing.generate(4);
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.name {
	display: flex;
	justify-content: center;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 0);
}

.name > svg {
	margin-right: spacing.generate(2);
	color: colors.$orange;
}

.buttonWrap {
	display: flex;
	justify-content: center;
	padding-block: spacing.generate(3);
}

.button {
	@include transitions.generate('color, background-color, transform');

	display: inline-flex;
	grid-gap: spacing.generate(1);
	align-items: center;
	justify-content: space-around;
	min-width: unset;
	min-height: unset;
	padding: spacing.generate(1) spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, -1);
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	vertical-align: middle;
	color: colors.$wine;
	border: units.px2rem(2px) solid colors.$wine;
	border-radius: units.px2rem(36px);
	background-image: none;
	background-color: transparent;
	transform: scale(1);
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}
