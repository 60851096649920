@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/spacing';
@use '../mixins';

.root {
	@include mixins.post-box();
}

.title {
	@include mixins.post-box-title();

	margin-bottom: spacing.generate(3);
}

.item {
	width: 100%;
	margin-bottom: spacing.generate(2);
	overflow: hidden;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 3);
	text-overflow: ellipsis;
	white-space: nowrap;
	color: colors.$red;

	&:hover,
	&:focus {
		color: colors.$wine-light;
	}
}
