@use '../../../scss/tools/button';

.root {
	@include button.generate();
}

.disabled {
	@include button.disabled();
}

.root > svg {
	@include button.svg();
}

.narrow {
	@include button.narrow();
}

.outline {
	@include button.outline();
}

.outline > svg {
	@include button.outline-svg();
}

.flat {
	@include button.flat();
}

.secondary {
	@include button.secondary();
}

.small {
	@include button.small();
}

.small.outline {
	@include button.small-outline();
}

.outlineHeavy {
	@include button.outline-heavy();
}
