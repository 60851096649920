@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	margin-top: spacing.generate(5);
}

.inner {
	display: flex;
	flex-direction: column;
	padding: spacing.generate(5);
	background-color: colors.$gray-extra-lighter;

	@include breakpoint.up(xxl) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.lastFill {
	margin-bottom: spacing.generate(1);
}

.lastFill span {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 0);
}

.possibleFill {
	margin-bottom: spacing.generate(3);

	@include breakpoint.up(xxl) {
		margin-bottom: 0;
	}
}

.possibleFill span {
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.dateIcon {
	display: inline-block;
	margin-left: spacing.generate(1);
}

.modal {
	text-align: center;
}

.modal p {
	margin-bottom: spacing.generate(4);
}
