@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	max-width: units.px2rem(1000px);
	margin-top: spacing.generate(30);
	margin-right: auto;
	margin-left: auto;
}

.step {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	opacity: 0.7;

	&:not(:last-of-type) {
		padding-bottom: spacing.generate(4);
		margin-bottom: spacing.generate(4);
		border-bottom: units.px2rem(1px) solid colors.$gray-lighter;
	}

	@include breakpoint.up(lg) {
		width: 92%;
	}
}

.stepHeader {
	display: flex;
	align-items: baseline;
}

.stepNumber {
	position: relative;
	flex-shrink: 0;
	width: 25%;
	margin-right: spacing.generate(6);
	font-weight: bold;
	font-size: units.px2rem(63px);
	text-align: right;

	@include breakpoint.up(sm) {
		font-size: units.px2rem(91px);
	}

	@include breakpoint.up(lg) {
		width: 17%;
	}
}

.stepIcon {
	position: absolute;
	right: units.px2rem(40px);
	bottom: units.px2rem(-5px);
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(30px);
	height: units.px2rem(30px);
	border-radius: 50%;
	background: colors.$white;
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$gray, $alpha: -0.5);

	@include breakpoint.up(sm) {
		right: units.px2rem(60px);
		bottom: units.px2rem(-10px);
		width: units.px2rem(50px);
		height: units.px2rem(50px);
	}
}

.stepIcon > svg {
	width: units.px2rem(18px);
	height: units.px2rem(18px);

	@include breakpoint.up(sm) {
		width: units.px2rem(30px);
		height: units.px2rem(30px);
	}
}

.stepInfo {
	flex-grow: 1;
	max-width: units.px2rem(600px);
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(3);
}

.stepTitle {
	margin-bottom: 0;
	font-weight: 400;
	font-size: units.px2rem(21px);

	@include breakpoint.up(sm) {
		font-size: units.px2rem(45px);
	}
}

.isStepActive {
	opacity: 1;
}

.stepContent {
	position: relative;
	max-width: 600px;
	margin-top: spacing.generate(3);

	@include breakpoint.up(lg) {
		margin-left: calc(17% + #{spacing.generate(6)});
	}
}

.stepContent > p {
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 1);
}

.listTitle {
	display: block;
	margin-bottom: spacing.generate(2);
}

.list {
	padding-bottom: spacing.generate(3);
	padding-left: spacing.generate(4);
}

.listItem {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: units.px2rem(-20px);
		width: units.px2rem(8px);
		height: units.px2rem(8px);
		border-radius: 50%;
		background-color: colors.$red;
		transform: translateY(-50%);
	}
}

.listItem:not(:last-child) {
	margin-bottom: spacing.generate(2);
}

.tooltip {
	position: absolute;
	top: 50%;
	margin-left: spacing.generate(1);
	transform: translateY(-50%);
}

.tooltipList {
	padding-bottom: spacing.generate(2);
	padding-left: spacing.generate(3);
}

.tooltipText,
.tooltipItem {
	font-size: map.get(typography.$font-size-values, 0);
}

.tooltipText:not(:last-child) {
	padding-bottom: spacing.generate(1);
}

.tooltipItem {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: units.px2rem(-15px);
		width: units.px2rem(5px);
		height: units.px2rem(5px);
		border-radius: 50%;
		background-color: colors.$white;
		transform: translateY(-50%);
	}
}
