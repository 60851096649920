@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	align-items: center;
}

.root > svg {
	flex-shrink: 0;
	margin-right: units.px2rem(8px);
	color: colors.$wine;
}

.xlsx > svg {
	margin-right: spacing.generate(1);
	margin-left: units.px2rem(-3px);
}

.root > span {
	font-weight: map.get(typography.$font-weight-values, medium);
	text-decoration: underline;
	text-underline-position: under;
	color: currentcolor;
}

.root,
.root:hover,
.root:focus,
.root:hover > span,
.root:focus > span {
	text-decoration: none;
}

.root > strong {
	flex-shrink: 0;
	margin-left: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, medium);
	color: colors.$gray-light;
}
