@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.legend {
	margin-bottom: spacing.generate(4);
}

.legend p {
	display: flex;
	gap: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
}

.tabHeader {
	display: flex;
	gap: spacing.generate(8);
	align-items: center;
	padding: spacing.generate(2);
	border-bottom: borders.$width solid colors.$gray-lighter;

	@include breakpoint.up(md) {
		padding: 0;
	}
}

.tabHeadline {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);
}

.tabTitles {
	display: none;

	@include breakpoint.up(md) {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: units.px2rem(350px);
		margin-left: auto;
	}
}

.tabTitle {
	padding: spacing.generate(3);

	&:first-child {
		padding-left: 0;
		text-align: left;
	}

	&:last-child {
		padding-right: 0;
		text-align: right;
	}
}

.hiddenButton {
	visibility: hidden;
}

.recommendInfo {
	margin-top: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 2);
	text-align: center;
}

.recommendInfo > strong {
	color: colors.$green;
}

.confirmButton {
	margin-top: spacing.generate(5);
	text-align: center;
}

.resetButton {
	margin-top: spacing.generate(3);
	text-align: center;
}

.modal {
	text-align: center;
}

.modalText,
.modalLinks {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalText {
	margin-bottom: spacing.generate(4);

	&:last-of-type {
		margin-bottom: spacing.generate(8);
	}
}

.modalList {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalList > li {
	margin-bottom: spacing.generate(2);
}

.modalLinks {
	margin-top: spacing.generate(2);
}
