@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';

.modal {
	position: relative;
}

.modalText {
	margin-bottom: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 4);
}

.modalControl {
	display: flex;
	flex-direction: column;
	text-align: center;
}
