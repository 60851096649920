@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.root {
	padding: spacing.generate(4) spacing.generate(3);
	margin-inline: spacing.generate(-3);
	border-top: borders.$width solid colors.$gray-extra-light;

	@include breakpoint.up(sm) {
		padding: spacing.generate(4);
		margin-inline: spacing.generate(-4);
	}
}

.title {
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.inputWrap {
	display: flex;
	gap: spacing.generate(4);
	align-items: center;
	margin-bottom: spacing.generate(1);
}

.name {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, 1);
}

.childIcon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(34px);
	height: units.px2rem(34px);
	margin-inline: spacing.generate(1);
	border-radius: 50%;
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(1px) units.px2rem(9px) 0 #{rgba(colors.$black, 0.08)};

	@include breakpoint.up(sm) {
		width: units.px2rem(44px);
		height: units.px2rem(44px);
	}
}

.childIcon > svg {
	width: units.px2rem(15px);
	height: units.px2rem(20px);

	@include breakpoint.up(sm) {
		width: units.px2rem(20px);
		height: units.px2rem(25px);
	}
}

.titleIcon {
	margin-left: spacing.generate(3);
}

.modalText,
.modalLinks {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 1);
}

.modalControl {
	margin-top: spacing.generate(6);
	text-align: center;
}

.modalClose {
	margin-top: spacing.generate(4);
	text-transform: uppercase;
}

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(4);

	&:last-of-type {
		margin-bottom: spacing.generate(8);
	}
}

.modalLinks {
	margin-top: spacing.generate(6);
}

.modalFooter {
	margin-top: spacing.generate(9);
}

.toggleWrap {
	text-align: center;
}

.imageWrap {
	position: relative;
}

.changeWrap {
	position: absolute;
	right: spacing.generate(-3);
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: units.px2rem(7px);
	border: borders.$width solid colors.$gray-lighter;
	border-radius: 50%;
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(1px) units.px2rem(9px) 0 #{rgba(colors.$black, 0.08)};
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		background-color: colors.$orange-lighter;
		transform: scale(0.95);
	}
}
