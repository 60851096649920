@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/scrollbar';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: center;

	@include breakpoint.up(lg) {
		text-align: left;
	}
}

.text {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(lg) {
		margin-top: 0;
	}
}

.tableWrap {
	@include scrollbar.generate();

	width: 100%;
	min-height: 0.01%;
	overflow: auto hidden;
	border: 0;

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}

	@include breakpoint.up(md) {
		overflow: visible;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	white-space: nowrap;
}

.table th {
	padding: spacing.generate(3) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	vertical-align: bottom;
	border-top: map.get(borders.$width-values, large) solid colors.$wine;

	&:nth-child(1) {
		width: units.px2rem(300px);
	}

	&:nth-child(2) {
		width: units.px2rem(150px);
	}

	&:nth-child(3) {
		width: units.px2rem(150px);
	}

	&:nth-child(4) {
		width: units.px2rem(110px);
	}

	&:nth-child(5) {
		width: units.px2rem(110px);
	}

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.table td {
	padding: spacing.generate(2) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	vertical-align: middle;
	border-top: borders.$width solid colors.$gray-lighter;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}

	&:nth-child(1) {
		width: 100%;
		max-width: units.px2rem(185px);
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:nth-child(2) {
		width: 100%;
		max-width: units.px2rem(120px);

		@include breakpoint.up(md) {
			width: unset;
			max-width: unset;
		}
	}
}

.table td > img {
	margin-right: spacing.generate(2);
	margin-bottom: spacing.generate(-2);
}

.table > thead > tr {
	border-bottom-color: colors.$gray-lighter;
}

.table > tbody {
	border-bottom: map.get(borders.$width-values, large) solid colors.$wine;
}

.table .highlight td {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.hideMobile {
	display: none;

	@include breakpoint.up(sm) {
		display: table-cell;
	}
}

.hideDesktop {
	@include breakpoint.up(sm) {
		display: none;
	}
}

.cellWrap {
	white-space: normal;
}

.right.right {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	text-align: right;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.btnMore {
	@include transitions.generate('color, background-color, transform');

	display: inline-block;
	min-width: units.px2rem(80px);
	padding: spacing.generate(1) spacing.generate(3);
	margin-left: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 1);
	line-height: map.get(typography.$line-height-values, 1);
	font-family: inherit;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	vertical-align: middle;
	color: colors.$wine;
	border: map.get(borders.$width-values, large) solid colors.$wine;
	border-radius: units.px2rem(34px);
	background: colors.$gray-extra-light;
	outline: 0;
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$white;
		background-color: colors.$wine;
	}

	&:active {
		background-color: colors.$wine-dark;
		transform: scale(0.95);
	}
}

.center {
	text-align: center;
}

.link {
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: colors.$wine;
}

.badgeWrap span {
	margin-right: spacing.generate(2);
}

.name {
	overflow: hidden;
	text-overflow: ellipsis;
}
