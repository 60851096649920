@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/header';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	z-index: 113;
	height: header.$height-mobile;

	@include breakpoint.up(md) {
		height: auto;
	}
}

.newspaperHomepage {
	width: 100%;
	height: header.$height-mobile;

	@include breakpoint.up(md) {
		height: header.$height;
	}
}

.inner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	@include breakpoint.up(md) {
		position: static;
	}
}

.innerHasShadow {
	box-shadow: 0 units.px2rem(10px) units.px2rem(10px) 0 color.adjust(colors.$black, $alpha: -0.9);
}

.container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: borders.$width solid colors.$gray-lightest;
	background-color: colors.$white;

	@include breakpoint.up(md) {
		align-items: flex-start;
		padding-inline: spacing.generate(3);
	}

	@include breakpoint.up(xxl) {
		padding-inline: spacing.generate(12);
	}
}

.logoWrap {
	padding: 0 spacing.generate(2);

	@include breakpoint.up(sm) {
		padding-left: spacing.generate(5);
	}

	@include breakpoint.up(md) {
		padding: spacing.generate(4) spacing.generate(3) 0;
	}

	@include breakpoint.up(xxl) {
		flex-basis: units.px2rem(400px);
	}
}

.logo {
	display: inline-block;
}

.logo > svg {
	width: units.px2rem(93px);
	height: auto;
	color: colors.$wine;

	@include breakpoint.up(md) {
		width: units.px2rem(143px);
	}
}

.navToggle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(58px);
	height: header.$height-mobile;
	padding: 0 spacing.generate(2);
	border: 0;
	background: transparent;
	cursor: pointer;

	@include breakpoint.up(sm) {
		width: units.px2rem(80px);
		padding: 0;
	}

	@include breakpoint.up(md) {
		display: none;
	}
}

.navToggle > svg {
	width: units.px2rem(24px);
	height: units.px2rem(24px);
	pointer-events: none;
}

.navWrap {
	@include transitions.generate(max-height);

	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	max-height: 0;
	overflow: hidden;
	border-top: borders.$width solid colors.$gray-lightest;
	background-color: colors.$white;

	@include breakpoint.up(md) {
		position: static;
		display: flex;
		flex-grow: 1;
		max-height: none;
		overflow: visible;
		border-top: 0;
		background-color: transparent;
		box-shadow: none;
	}
}

.navWrapInner {
	max-height: calc(100vh - #{units.px2rem(80px)});
	padding-top: spacing.generate(3);
	padding-bottom: spacing.generate(6);
	overflow: auto;
	text-align: center;
	border-top: units.px2rem(1px) solid colors.$gray-lighter;

	@include breakpoint.up(md) {
		display: flex;
		flex-flow: wrap row-reverse;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding-top: 0;
		padding-bottom: 0;
		margin-top: spacing.generate(3);
		overflow: visible;
		text-align: left;
		white-space: nowrap;
		border-top: 0;
	}

	@include breakpoint.up(xxl) {
		flex-flow: nowrap row-reverse;
	}
}

.navUser {
	display: flex;
	gap: spacing.generate(2);
	justify-content: center;
	padding-inline: spacing.generate(3);

	@include breakpoint.up(md) {
		flex-basis: units.px2rem(400px);
		gap: 0;
		align-items: center;
		justify-content: flex-end;
		padding-inline: 0;
		padding-bottom: spacing.generate(3);
	}
}

.nav {
	margin-inline: auto;
	text-align: center;

	@include breakpoint.up(md) {
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		text-align: left;
	}

	@include breakpoint.up(xxl) {
		flex-wrap: nowrap;
		justify-content: center;
		padding-inline: spacing.generate(4);
	}
}

.navLink {
	@include transitions.generate(color);

	display: inline-block;
	width: 100%;
	padding: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, -1);
	text-decoration: none;
	color: colors.$wine;
	border-left: units.px2rem(2px) solid transparent;

	@include breakpoint.up(md) {
		width: auto;
		padding: spacing.generate(3) spacing.generate(2) spacing.generate(6) spacing.generate(2);
		border-bottom: units.px2rem(2px) solid transparent;
		border-left: 0;
	}

	@include breakpoint.up(xxl) {
		padding-inline: spacing.generate(3);
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$orange;
	}
}

.navLogout {
	margin-top: spacing.generate(4);
	font-family: inherit;
	border: 0;
	background: transparent;
	outline: 0;

	@include breakpoint.up(md) {
		display: none;
	}
}

.isNavLinkActive.isNavLinkActive {
	color: colors.$gray-dark;
	border-left-color: colors.$orange;
	background-color: colors.$gray-extra-lighter;

	@include breakpoint.up(md) {
		border-bottom-color: colors.$orange;
		border-left-color: transparent;
		background-color: transparent;
	}
}

.navButton {
	@include transitions.generate('opacity, color, background-color, transform');

	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: spacing.generate(2) spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 0);
	text-decoration: none;
	color: colors.$white;
	border: borders.$width solid colors.$gray-light;
	border-radius: units.px2rem(25px);
	background-color: colors.$red;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$white;
		opacity: 0.9;
	}

	&:active {
		background-color: colors.$red-dark;
		transform: scale(0.95);
	}
}

.navLogin {
	display: inline-flex;
	color: colors.$wine;
	background-color: colors.$white;

	&:hover,
	&:focus {
		color: colors.$wine;
		background-color: colors.$white;
	}

	&:active {
		background-color: colors.$white;
	}

	@include breakpoint.up(md) {
		margin-right: spacing.generate(3);
	}
}

.navLogin > svg {
	position: relative;
	top: units.px2rem(-1px);
	width: units.px2rem(13px);
	height: units.px2rem(18px);
	margin-right: spacing.generate(1);
	color: colors.$orange;
}

.navAccount > li:first-child,
.navAccount .navLogin {
	width: 100%;

	@include breakpoint.up(md) {
		width: auto;
	}
}

.rootHasBox {
	position: relative;
}

.registrationButtonWrap {
	flex-grow: 1;
	text-align: right;
}

.languageToggleWrap {
	margin-top: spacing.generate(2);

	@include breakpoint.up(md) {
		margin-top: 0;
		margin-left: spacing.generate(4);
	}
}

.newOffer {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: units.px2rem(3px);
		right: units.px2rem(3px);
		width: units.px2rem(10px);
		height: units.px2rem(10px);
		border-radius: 50%;
		background-color: colors.$red;
	}
}

.white,
.white > svg {
	color: colors.$white;
}

.dropdownWrap {
	@include breakpoint.up(md) {
		position: relative;
	}
}

.dropdown {
	position: relative;
	width: 100%;
	text-align: center;
	border: 0;
	border-left: units.px2rem(2px) solid transparent;
	background-color: transparent;
	cursor: pointer;

	@include breakpoint.up(md) {
		width: auto;
		padding-right: spacing.generate(4);
		border-bottom: units.px2rem(2px) solid transparent;
	}

	&::after {
		@include transitions.generate('border-color, transform');

		content: '';
		position: absolute;
		top: 50%;
		right: spacing.generate(5);
		width: units.px2rem(7px);
		height: units.px2rem(7px);
		border-width: units.px2rem(1.5px) 0 0 units.px2rem(1.5px);
		border-style: solid;
		border-color: colors.$wine;
		transform: translateY(-50%) rotate(135deg);
		transform-origin: center;

		@include breakpoint.up(md) {
			top: calc(50% - #{units.px2rem(8px)});
			right: spacing.generate(1);
			transform: translateY(-50%) rotate(225deg);
		}
	}

	&:hover,
	&:focus {
		&::after {
			border-color: colors.$orange;
		}
	}
}

.subNav {
	position: absolute;
	top: 0;
	z-index: 100;
	display: none;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(12px) units.px2rem(60px) units.px2rem(-10px) color.adjust(colors.$black, $alpha: -0.9);

	@include breakpoint.up(md) {
		top: initial;
		width: auto;
		min-width: units.px2rem(350px);
		height: auto;
		padding: spacing.generate(2);
		border-bottom-right-radius: units.px2rem(10px);
		border-bottom-left-radius: units.px2rem(10px);
	}
}

.subNav > li:first-of-type {
	margin-top: spacing.generate(10);
	border-top: borders.$width solid colors.$gray-lightest;

	@include breakpoint.up(md) {
		margin-top: 0;
		border-top: 0;
	}
}

.subNavClose {
	position: fixed;
	right: 0;
	left: 0;
	width: 100%;
	padding: spacing.generate(3) spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	border: 0;
	background-color: colors.$white;
	cursor: pointer;

	@include breakpoint.up(md) {
		display: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: spacing.generate(3);
		width: units.px2rem(7px);
		height: units.px2rem(7px);
		border-width: units.px2rem(1.5px) 0 0 units.px2rem(1.5px);
		border-style: solid;
		border-color: colors.$wine;
		transform: translateY(-50%) rotate(315deg);
		transform-origin: center;
	}
}

.subNavLink {
	@include transitions.generate('background-color');

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: spacing.generate(3);
	text-decoration: none;
	text-transform: uppercase;
	color: colors.$wine;

	@include breakpoint.up(md) {
		padding: spacing.generate(2);
		border-radius: spacing.generate(1);
	}

	&:hover,
	&:focus {
		background-color: color.adjust(colors.$orange, $alpha: -0.9);
	}
}

.subNavLinkIcon {
	display: block;
	width: units.px2rem(32px);
	height: units.px2rem(32px);
	margin-right: spacing.generate(2);
}

.subNavContent {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.subNavTitle {
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 2);
	color: colors.$wine;
}

.subNavSubTitle {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	color: colors.$gray-dark;
}

.isDropdownOpen > .dropdown {
	border-bottom-color: colors.$orange;

	&::after {
		transform: translateY(-50%) rotate(45deg);
	}
}

.isDropdownOpen > .subNav {
	display: block;
}

.navLoginWrap {
	flex-grow: 1;
	text-align: right;

	@include breakpoint.up(md) {
		display: none;
	}
}

.navLoginWrap > .navLogin {
	padding-inline: units.px2rem(12px);
}

.navLoginWrap > .navLogin > svg {
	margin-right: 0;
}
