@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	font-size: units.px2rem(18px);
	text-align: center;
}

.wrapper {
	max-width: units.px2rem(800px);
	margin-inline: auto;
}

.buttonsWrap {
	width: 100%;
	margin-bottom: spacing.generate(3);
	text-align: center;
}

.modalClose {
	margin-top: spacing.generate(4);
	text-transform: uppercase;
}
