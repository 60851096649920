@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';
@use '../mixins';

.root {
	@include mixins.section();

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: 0;

	@include breakpoint.up(md) {
		min-height: units.px2rem(700px);
	}
}

.isRootHomepageHero {
	min-height: units.px2rem(550px);
	padding: 0;
}

.isRootBordered {
	border-bottom: units.px2rem(1px) solid colors.$gray-lighter;
}
