@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-top: spacing.generate(6);
	padding-right: spacing.generate(3);
	padding-left: spacing.generate(3);
	border-top: units.px2rem(1px) solid colors.$gray-lighter;
}

.title {
	max-width: units.px2rem(400px);
	margin-right: auto;
	margin-left: auto;
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.2;
	white-space: pre-line;
}

.number {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(45px);
	line-height: 1.4;

	@include breakpoint.up(sm) {
		font-size: units.px2rem(60px);
	}
}
