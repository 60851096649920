@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	margin-bottom: spacing.generate(6);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(24);
	}
}

.title {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$orange;

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(2);
		font-size: map.get(typography.$font-size-values, 16);
		line-height: map.get(typography.$line-height-values, 1);
	}
}

.description {
	max-width: units.px2rem(575px);
	margin-inline: auto;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 9);
	}
}

.item {
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(sm) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: units.px2rem(130px);
	}

	@include breakpoint.up(lg) {
		min-height: units.px2rem(182px);
	}

	@include breakpoint.up(xl) {
		min-height: units.px2rem(152px);
	}
}

.itemTitle {
	display: block;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: map.get(typography.$line-height-values, 2);
}

.value {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: center;
	margin-right: units.px2rem(2px);
	font-size: map.get(typography.$font-size-values, 14);
	line-height: map.get(typography.$line-height-values, 0);
	color: colors.$orange;

	@include breakpoint.up(sm) {
		flex-direction: column;
		align-items: center;
	}

	@include breakpoint.up(lg) {
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-start;
		font-size: map.get(typography.$font-size-values, 15);
	}
}

.period {
	position: relative;
	top: units.px2rem(-11px);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 0);
	color: colors.$wine-dark;
}

.itemFirst > .itemTitle,
.itemFirst .value,
.itemFirst .period {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.info {
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(8);
		font-size: map.get(typography.$font-size-values, 9);
	}
}

.benefitsTitle {
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 4);
}
