@use 'sass:map';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);

	&:last-child {
		margin-top: spacing.generate(5);
	}
}

.emailAliasText {
	margin-bottom: spacing.generate(5);
}

.line {
	position: relative;
	margin-block: spacing.generate(6);
	font-size: units.px2rem(14px);
	text-align: center;

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(7px);
		left: 0;
		display: block;
		width: 100%;
		height: units.px2rem(1px);
		background-color: colors.$gray-lighter;
	}
}

.line > span {
	position: relative;
	z-index: 1;
	display: inline;
	padding: spacing.generate(2);
	background-color: colors.$white;
}

.steps {
	margin-block: spacing.generate(8);

	@include breakpoint.up(md) {
		margin-block: spacing.generate(16);
	}
}

.text {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 4);
}
