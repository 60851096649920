@use 'sass:map';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.item {
	position: relative;
	margin-bottom: spacing.generate(3);
	margin-left: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 7);
	}

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(8px);
		left: units.px2rem(-20px);
		width: units.px2rem(12px);
		height: units.px2rem(12px);
		border-radius: 50%;
		background-color: colors.$red;

		@include breakpoint.up(lg) {
			top: units.px2rem(10px);
		}
	}
}

.list {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
}

.item > .list > .item::before {
	border: 2px solid colors.$red;
	background-color: transparent;
}
