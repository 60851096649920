@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(4);
	font-size: units.px2rem(32px);
	line-height: 1.1;
	text-align: center;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(16);
		font-size: units.px2rem(60px);
	}
}

.buttonWrap {
	margin-top: spacing.generate(6);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-top: 0;
		text-align: right;
	}
}
