@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/layout';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';
@use '../../../scss/tools/spacing';
@use './variables';

.column {
	flex: 0 1 100%;
	width: 100%;
	max-width: 100%;
	padding-right: layout.$layout-offset;
	padding-left: layout.$layout-offset;

	@include breakpoint.up(sm) {
		flex-basis: variables.$grid-columns-2;
		width: variables.$grid-columns-2;
		max-width: variables.$grid-columns-2;
	}

	@include breakpoint.up(md) {
		flex-basis: variables.$grid-columns-3;
		width: variables.$grid-columns-3;
		max-width: variables.$grid-columns-3;
		text-align: left;
	}

	@include breakpoint.up(xl) {
		flex-basis: variables.$grid-columns-5;
		width: variables.$grid-columns-5;
		max-width: variables.$grid-columns-5;
	}
}

.icon {
	width: auto;
	height: variables.$image-height;
	margin-bottom: spacing.generate(2);
}

.itemWrap {
	display: flex;
	flex-direction: column;
}

.number {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: map.get(typography.$line-height-values, 1);
}

.itemDescription {
	margin-bottom: spacing.generate(10);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(5);
	}
}

.column:not(:last-child) .icon {
	@include breakpoint.up(md) {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: spacing.generate(12);
			right: 0;
			left: spacing.generate(28);
			height: units.px2rem(2px);
			border-radius: units.px2rem(1px);
			background-color: colors.$wine;
		}

		&::before {
			content: '';
			position: absolute;
			top: units.px2rem(54.5px);
			right: units.px2rem(-2px);
			width: units.px2rem(8px);
			height: units.px2rem(15px);
			background-image: url('../../../images/arrowIcon.svg');
			background-repeat: no-repeat;
		}
	}
}
