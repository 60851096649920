@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.wrap {
	max-width: units.px2rem(820px);
	margin: 0 auto spacing.generate(-30);
	transform: translateY(spacing.generate(-30));

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(-28);
		transform: translateY(spacing.generate(-28));
	}

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(-44);
		transform: translateY(spacing.generate(-44));
	}
}

.embedContainer {
	position: relative;
	max-width: 100%;
	height: 0;
	padding-bottom: 100%;
	overflow: hidden;
	box-shadow: 0 units.px2rem(4px) units.px2rem(60px) #{rgba(colors.$black, 0.15)};
}

.embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.preview {
	width: 100%;
	height: 100%;
	box-shadow: 0 units.px2rem(4px) units.px2rem(60px) #{rgba(colors.$black, 0.15)};
}
