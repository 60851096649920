@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: relative;
	margin-bottom: spacing.generate(4);
	text-align: left;
}

.center {
	text-align: center;
}

.disabled {
	opacity: 0.5;
}

.noError {
	margin-bottom: 0;
}

.labelWrap {
	display: flex;
	margin-bottom: spacing.generate(1);
	color: colors.$wine;
}

.labelWrapHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.label.label {
	width: auto;
	margin-bottom: 0;
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
}

.helper {
	flex-grow: 1;
	margin-left: spacing.generate(3);
	font-weight: normal;
	font-size: units.px2rem(14px);
	text-align: right;
}

.inputWrap {
	position: relative;
}

.input {
	@include transitions.generate(box-shadow);

	appearance: textfield;
	display: block;
	width: 100%;
	height: units.px2rem(56px);
	padding: units.px2rem(6px) units.px2rem(12px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(35px);
	font-family: inherit;
	text-align: inherit;
	color: colors.$gray-dark;
	border: borders.$width solid colors.$gray-light;
	border-radius: units.px2rem(8px);

	/* CSS specific to iOS devices */
	@supports (-webkit-touch-callout: none) {
		appearance: none;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}

	&:focus {
		outline: 0;
		box-shadow:
			inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
			0 0 units.px2rem(8px) rgb(102 175 233 / 60%);
	}
}

.inputFlat {
	height: units.px2rem(46px);
	font-weight: 700;
	font-size: units.px2rem(17px);
	text-align: right;
	color: colors.$red;
	background-color: colors.$white;
	box-shadow: none;
}

.inputWithUnit {
	padding-right: spacing.generate(6);
	text-align: right;
}

.unit {
	position: absolute;
	top: 50%;
	right: units.px2rem(8px);
	font-size: units.px2rem(18px);
	line-height: units.px2rem(35px);
	transform: translateY(-50%);
}

.inputFlat,
.inputFlat ~ .unit {
	font-weight: 700;
	font-size: units.px2rem(17px);
	color: colors.$red;
}

.error,
.warning {
	display: block;
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(2);
	font-weight: bold;
	text-align: left;
}

.error {
	color: colors.$error;
}

.warning {
	color: colors.$orange;
}

.rootError > .inputWrap > .input:focus {
	box-shadow:
		inset 0 units.px2rem(1px) units.px2rem(1px) rgb(0 0 0 / 8%),
		0 0 units.px2rem(6px) colors.$pink;
}

.passwordToggle {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: units.px2rem(46px);
	padding: 0;
	font-size: 0;
	border: 0;
	border-radius: units.px2rem(8px);
	background: transparent;
	outline: 0;
}

.tooltip {
	margin-left: spacing.generate(1);
}
