@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(6);
	text-align: center;
}

.active {
	text-align: center;
}

.activeText {
	margin-bottom: spacing.generate(6);
}

.alert {
	max-width: units.px2rem(300px);
	margin-top: spacing.generate(6);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.modal {
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	@include breakpoint.up(lg) {
		width: 66%;
	}
}

.qr {
	display: block;
	width: units.px2rem(200px);
	max-width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.qrCode {
	font-weight: 500;
	font-size: units.px2rem(18px);
}

.text {
	padding: spacing.generate(2);
	font-weight: 600;
	font-size: units.px2rem(18px);
	line-height: 1.5;
	text-align: left;

	@include breakpoint.up(lg) {
		padding: 0;
	}
}

.list {
	font-weight: 400;
	list-style: decimal;
}

.form {
	margin-top: spacing.generate(6);
	text-align: center;
}
