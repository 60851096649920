@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.link {
	display: inline-block;
	margin-top: spacing.generate(3);
	font-size: map.get(typography.$font-size-values, 2);
	text-transform: uppercase;
}

.modal {
	text-align: center;
}

.text {
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}

.message {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 10);
	text-align: center;
}
