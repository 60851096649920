@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.grid {
	display: flex;
	flex-flow: row wrap;
	gap: spacing.generate(3);
	align-items: baseline;
	justify-content: center;
	margin-bottom: spacing.generate(3);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(8);
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	gap: spacing.generate(2);
	padding-bottom: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	border-bottom: borders.$width solid colors.$gray-lighter;

	@include breakpoint.up(sm) {
		gap: spacing.generate(3);
		padding-bottom: spacing.generate(6);
		margin-bottom: spacing.generate(6);
	}
}

.avatarImage {
	display: block;
	width: units.px2rem(84px);

	@include breakpoint.up(sm) {
		width: units.px2rem(96px);
	}
}

.button {
	position: relative;
	appearance: none;
	padding: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	&::after {
		@include transitions.generate(border-color);

		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 50%;
		z-index: -1;
		display: block;
		width: units.px2rem(70px);
		height: units.px2rem(70px);
		border: units.px2rem(1px) solid colors.$wine;
		border-radius: 50%;
		transform: translateX(-50%);

		@include breakpoint.up(sm) {
			width: units.px2rem(80px);
			height: units.px2rem(80px);
		}
	}

	&:hover {
		&::after {
			border-color: colors.$orange;
		}
	}
}

.active {
	&::after {
		border-color: colors.$orange;
	}
}
