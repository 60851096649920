@use 'sass:map';
@use '../../scss/settings/colors';
@use '../../scss/settings/typography';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.title {
	margin-block: 0;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(sm) {
		margin-top: spacing.generate(20);
	}

	@include breakpoint.up(lg) {
		margin-bottom: spacing.generate(6);
		font-size: map.get(typography.$font-size-values, 15);
	}
}

.text {
	margin-bottom: spacing.generate(3);
}

.list {
	display: flex;
	flex-direction: column;
	padding-left: spacing.generate(4);
	text-align: left;
}

.listTitle {
	margin-block: spacing.generate(6) spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: map.get(typography.$line-height-values, 1);
}

.listItem {
	position: relative;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, regular);
		font-size: map.get(typography.$font-size-values, 4);
	}

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: units.px2rem(-20px);
		width: units.px2rem(10px);
		height: units.px2rem(10px);
		border-radius: units.px2rem(5px);
		background-color: colors.$red;
		transform: translateY(-50%);
	}
}

.listItem:not(:last-child) {
	margin-bottom: spacing.generate(3);
}
