@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(3);
	font-weight: 700;
	font-size: units.px2rem(22px);
	line-height: 1.1;

	@include breakpoint.up(md) {
		font-size: units.px2rem(35px);
	}

	@include breakpoint.up(lg) {
		font-size: units.px2rem(45px);
	}
}

.form {
	max-width: units.px2rem(430px);
	margin: 0 auto;
}

.toggle {
	position: relative;
	display: inline-flex;
	width: units.px2rem(268px);
	height: units.px2rem(46px);
	margin-bottom: spacing.generate(6);
	border: units.px2rem(2px) solid colors.$wine-dark;
	border-radius: units.px2rem(25px);
}

.toggleItem {
	@include button.generate();

	position: relative;
	z-index: 1;
	font-weight: 700;
	font-size: units.px2rem(16px);
	text-transform: none;
	color: colors.$wine-dark;
	background-color: transparent;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$wine-dark;
	}

	&:active {
		background-color: transparent;
	}

	@include breakpoint.up(sm) {
		padding: spacing.generate(2) spacing.generate(6);
	}
}

.toggleItemActive {
	color: colors.$white;

	&:hover,
	&:focus {
		color: colors.$white;
	}
}

.toggleActive {
	@include button.generate();
	@include transitions.generate('background-color, border-color, transform');

	position: absolute;
	top: units.px2rem(-2px);
	left: units.px2rem(-3px);
	width: units.px2rem(140px);
	height: units.px2rem(46px);
	background-color: colors.$orange;
	transform: translateX(0);

	&:hover,
	&:focus {
		border-color: colors.$orange-light;
		background-color: colors.$orange-light;
	}
}

.toggleActiveFondee {
	transform: translateX(units.px2rem(130px));
}

.toggleItemInput {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: inherit;
}

.inputWrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	text-align: left;
}

.inputLabel {
	margin-right: spacing.generate(2);
	margin-bottom: spacing.generate(4);
	font-weight: 600;
	font-size: units.px2rem(16px);
}

.paragraph {
	margin-bottom: spacing.generate(3);
	font-weight: 500;
	font-size: units.px2rem(14px);
	line-height: 1.4;

	@include breakpoint.up(md) {
		font-size: units.px2rem(20px);
	}
}

.result {
	display: block;
	margin-top: spacing.generate(3);
	font-weight: 700;
	font-size: units.px2rem(30px);

	@include breakpoint.up(md) {
		font-size: units.px2rem(40px);
	}
}

.link {
	@include transitions.generate(text-decoration-color);

	font-weight: 700;
	font-size: units.px2rem(16px);
	text-decoration: underline;
	text-decoration-color: color.adjust(colors.$red, $alpha: -0.5);
	color: colors.$red;
	border-width: 0;
	background-color: transparent;
	outline: 0;

	&:hover,
	&:focus {
		text-decoration-color: colors.$red;
		color: colors.$red;
	}

	@include breakpoint.up(md) {
		font-size: units.px2rem(18px);
	}
}

.textOrange {
	color: colors.$orange;
}

.resultBox {
	margin-top: spacing.generate(5);
}
