@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: units.px2rem(300px);
}

.title {
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 6);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 8);
	}
}

.evaluation {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 9);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, bold);
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.bold {
	font-weight: map.get(typography.$font-weight-values, bold);
}

.wrap {
	display: flex;
	grid-gap: spacing.generate(4);
	justify-content: center;
	margin-bottom: spacing.generate(5);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(10);
	}
}

.name {
	position: relative;

	@include breakpoint.up(md) {
		padding-left: spacing.generate(4);
	}
}

.indicator {
	@include breakpoint.up(md) {
		position: absolute;
		top: 50%;
		left: 0;
		width: units.px2rem(13px);
		height: units.px2rem(13px);
		border: borders.$width solid colors.$white;
		border-radius: 50%;
		transform: translateY(-50%);
	}
}

.tableWrap {
	overflow-x: auto;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 1);
	}
}

.th {
	padding: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	text-align: right;
}

.tbody tr {
	&:nth-child(odd) {
		background-color: colors.$gray-lightest;
	}
}

.table td {
	padding: spacing.generate(2);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: right;
	vertical-align: middle;

	&:first-child {
		border-top-left-radius: units.px2rem(5px);
		border-bottom-left-radius: units.px2rem(5px);
	}

	&:last-child {
		border-top-right-radius: units.px2rem(5px);
		border-bottom-right-radius: units.px2rem(5px);
	}
}

.table tr td:not(.center):first-child {
	text-align: left;
}

.semibold {
	font-weight: map.get(typography.$font-weight-values, semibold);
}

.hidden {
	font-size: 0;
}

.table .center {
	text-align: center;
}

.paragraph {
	padding-bottom: spacing.generate(4);
}
