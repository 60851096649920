@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/spacing';

.root {
	text-align: center;
}

.text {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}
