@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.feeWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(7);
	align-items: center;
	max-width: units.px2rem(1125px);
	margin-inline: auto;

	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.fee {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	max-width: units.px2rem(515px);
}

.title {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	text-align: center;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
		text-align: left;
	}
}

.text {
	margin-bottom: spacing.generate(5);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}
}

.list {
	display: flex;
	flex-direction: column;
}

.listItem {
	position: relative;
	padding-left: spacing.generate(6);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 2);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 5);
	}

	&::after {
		content: '';
		position: absolute;
		top: units.px2rem(9px);
		left: units.px2rem(14px);
		width: units.px2rem(2px);
		height: units.px2rem(4px);
		border-radius: units.px2rem(1px);
		background-color: colors.$wine;
	}
}
