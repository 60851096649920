@use 'sass:color';
@use 'sass:map';
@use '../../../../scss/settings/borders';
@use '../../../../scss/settings/colors';
@use '../../../../scss/settings/typography';
@use '../../../../scss/tools/breakpoint';
@use '../../../../scss/tools/transitions';
@use '../../../../scss/tools/spacing';
@use '../../../../scss/tools/units';

.root {
	position: relative;
	z-index: 90;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: calc(100vh - #{units.px2rem(66px)});
	border: borders.$width solid colors.$gray-light;
	background-color: colors.$gray-extra-lighter;

	@include breakpoint.up(md) {
		width: units.px2rem(290px);
		max-height: unset;
		border-radius: spacing.generate(2);
	}

	@include breakpoint.up(xl) {
		width: units.px2rem(340px);
	}

	@include breakpoint.up(xxl) {
		width: units.px2rem(410px);
	}
}

.wrap {
	min-height: units.px2rem(60px);
}

.fixed {
	position: fixed;
	z-index: 90;

	@include breakpoint.up(md) {
		position: relative;
	}
}

.title {
	display: none;
	margin-bottom: spacing.generate(5);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);

	@include breakpoint.up(md) {
		display: block;
	}
}

.toggle {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	min-height: units.px2rem(52px);
	padding: spacing.generate(3) spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 4);
	border: 0;
	border-radius: 0;
	background-color: colors.$gray-extra-lighter;

	&::after {
		@include transitions.generate(transform);

		content: '';
		position: absolute;
		top: 50%;
		right: spacing.generate(4);
		width: units.px2rem(12px);
		height: units.px2rem(12px);
		border-width: map.get(borders.$width-values, large) 0 0 map.get(borders.$width-values, large);
		border-style: solid;
		border-color: colors.$wine;
		transform: translateY(-50%) rotate(225deg);
		transform-origin: center;
	}

	@include breakpoint.up(md) {
		display: none;
	}
}

.toggleOpen::after {
	transform: rotate(45deg);
}

.content {
	display: none;
	flex-direction: column;
	padding: spacing.generate(3);
	overflow: hidden auto;

	@include breakpoint.up(md) {
		display: block;
		overflow: visible;
	}

	@include breakpoint.up(lg) {
		padding: spacing.generate(4) spacing.generate(3);
	}

	@include breakpoint.up(xl) {
		padding: spacing.generate(4);
	}
}

.open {
	display: flex;
}
