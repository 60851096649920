@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.box {
	padding: spacing.generate(6) spacing.generate(3);
	margin-bottom: spacing.generate(8);
	border-radius: units.px2rem(8px);
	box-shadow: 0 units.px2rem(14px) units.px2rem(50px) #{rgba(colors.$black, 0.1)};

	@include breakpoint.up(sm) {
		padding-inline: spacing.generate(4);
	}

	@include breakpoint.up(lg) {
		max-width: units.px2rem(525px);
		margin-bottom: spacing.generate(4);
	}
}

.title {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 9);

	@include breakpoint.up(md) {
		font-weight: map.get(typography.$font-weight-values, medium);
		font-size: map.get(typography.$font-size-values, 11);
	}
}
