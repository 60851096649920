@use 'sass:color';
@use '../../scss/settings/colors';
@use '../../scss/tools/breakpoint';
@use '../../scss/tools/spacing';
@use '../../scss/tools/units';

.header {
	margin-bottom: spacing.generate(4);

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(16);
	}
}

.title {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(45px);
	line-height: 1.1;
	text-align: center;
}

.text {
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: 1.2;
}

.box {
	padding-top: spacing.generate(12);
	padding-bottom: spacing.generate(12);
	margin-bottom: spacing.generate(6);
	text-align: center;
	border-radius: units.px2rem(8px);
	box-shadow: 0 0 units.px2rem(25px) 0 color.adjust(colors.$black, $alpha: -0.8);
}

.boxTitle.boxTitle {
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);
	margin-bottom: spacing.generate(8);
	font-size: units.px2rem(32px);

	@include breakpoint.up(sm) {
		margin-bottom: spacing.generate(16);
		font-size: units.px2rem(45px);
	}
}

.boxImage {
	display: block;
	margin-bottom: spacing.generate(6);
}

.boxImage > img {
	width: 80%;
	max-width: units.px2rem(450px);
}

.boxContent {
	padding-top: spacing.generate(4);
	padding-right: spacing.generate(4);
	padding-left: spacing.generate(4);
	border-top: units.px2rem(1px) solid colors.$gray-extra-light;
}

.boxLine {
	max-width: units.px2rem(400px);
	margin-right: auto;
	margin-bottom: spacing.generate(4);
	margin-left: auto;
}

.boxCode {
	width: 45%;
	min-width: units.px2rem(240px);
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}
