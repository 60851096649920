@use 'sass:map';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	max-width: units.px2rem(660px);
	margin: spacing.generate(12) auto;
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: center;

	@include breakpoint.up(lg) {
		font-size: map.get(typography.$font-size-values, 14);
		line-height: map.get(typography.$line-height-values, 1);
	}
}
