@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: units.px2rem(300px);
}

.title {
	margin: 0 spacing.generate(3) spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 9);
	line-height: map.get(typography.$line-height-values, 1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 11);
	}
}

.modal {
	text-align: center;
}

.modalText {
	margin-bottom: spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
}

.withdrawalText,
.text {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);
}

.withdrawalText {
	margin: 0 0 spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, semibold);

	@include breakpoint.up(md) {
		margin-right: spacing.generate(4);
		margin-bottom: 0;
	}
}

.note {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, -1);
	line-height: map.get(typography.$line-height-values, 1);
}

.input {
	display: flex;
	max-width: units.px2rem(250px);
	margin-bottom: spacing.generate(4);
}

.input > div {
	flex-grow: 1;
}

.currency {
	margin-top: spacing.generate(4);
	margin-left: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);

	&:first-child {
		margin-right: spacing.generate(2);
		margin-left: 0;
	}
}

.currencySelect {
	margin-top: spacing.generate(2);
	margin-left: spacing.generate(2);
}

.wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: spacing.generate(2) spacing.generate(3);
	margin-inline: spacing.generate(3);
	border-radius: units.px2rem(8px);
	background-color: colors.$gray-extra-light;

	@include breakpoint.up(md) {
		flex-direction: row;
		align-items: center;
	}
}

.alertWrap {
	margin-inline: spacing.generate(3);
	margin-top: spacing.generate(2);
}

.listTitle {
	margin: 0 0 spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 2);
	}
}

.list {
	padding-left: spacing.generate(2);
}

.listItem {
	position: relative;
	padding-left: spacing.generate(4);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 1);

	&::before {
		content: '';
		position: absolute;
		top: units.px2rem(7px);
		left: 0;
		width: units.px2rem(2px);
		height: units.px2rem(4px);
		border-radius: units.px2rem(1px);
		background-color: colors.$orange;
	}
}

.badgeWrap {
	display: inline-flex;
	flex-direction: column;
	margin-right: spacing.generate(2);
}

.badgeWrap span {
	margin-bottom: spacing.generate(1);
}

.badgeWrap span:last-child {
	margin-bottom: 0;
}

/* stylelint-disable selector-class-pattern */
.portfolio :global .selectField__single-value {
	display: flex;
	align-items: center;
}

.select {
	margin-bottom: spacing.generate(3);
}
