@use 'sass:color';
@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.root {
	position: absolute;
	inset: 0;
	z-index: 100;
	display: block;
	width: 100%;
	height: 100%;
	background-color: colors.$white;
	box-shadow: 0 0 units.px2rem(10px) 0 color.adjust(colors.$black, $alpha: -0.9);
	visibility: hidden;
	opacity: 0;

	@include breakpoint.up(md) {
		top: spacing.generate(8);
		right: initial;
		bottom: initial;
		left: initial;
		min-width: units.px2rem(190px);
		max-width: units.px2rem(320px);
		height: initial;
		border-radius: units.px2rem(6px);
	}
}

.root.isOpen {
	visibility: visible;
	opacity: 1;
}

.wrap {
	display: flex;
	flex-direction: column;
	border-top: borders.$width solid colors.$gray-lightest;

	@include breakpoint.up(md) {
		border-top: 0;
	}
}

.list {
	position: unset;
	padding: 0;
	border: 0;
	background-color: transparent;

	@include breakpoint.up(md) {
		position: relative;
	}
}

.listOwn:not(:only-of-type) {
	border-bottom: borders.$width solid colors.$gray-lighter;
}

.list:hover,
.item:hover {
	background-color: colors.$gray-lighter;
}

.list:first-child,
.item:first-child {
	@include breakpoint.up(md) {
		border-top-left-radius: units.px2rem(6px);
		border-top-right-radius: units.px2rem(6px);
	}
}

.list:last-child,
.inner > .item:last-child,
.listOnly:last-child > .item:last-child {
	@include breakpoint.up(md) {
		border-bottom-right-radius: units.px2rem(6px);
		border-bottom-left-radius: units.px2rem(6px);
	}
}

.inner {
	position: absolute;
	top: 0;
	display: none;
	border-top: borders.$width solid colors.$gray-lightest;
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(10px) units.px2rem(10px) 0 color.adjust(colors.$black, $alpha: -0.9);

	@include breakpoint.up(md) {
		right: units.px2rem(-190px);
		width: units.px2rem(190px);
		border-top: 0;
		border-radius: units.px2rem(6px);
	}
}

.innerOpen {
	inset:
		(map.get(typography.$font-size-values, 2) * map.get(typography.$line-height-values, 1) + 2 * spacing.generate(3))
		0 0 0;
	z-index: 300;
	display: block;
	width: 100%;
	height: 100%;
}

.list:hover > .inner {
	@include breakpoint.up(md) {
		display: block;
	}
}

.name {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: spacing.generate(2) spacing.generate(5) spacing.generate(2) spacing.generate(2);
	overflow: hidden;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 4);
	line-height: map.get(typography.$line-height-values, 0);
	text-align: left;
	text-decoration: none;
	text-overflow: ellipsis;
	text-transform: none;
	color: colors.$wine;
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.nameInner {
	display: flex;
	gap: spacing.generate(1);
	align-items: center;
	min-height: units.px2rem(25px);
}

.icon {
	position: relative;
	right: spacing.generate(-3);
}

.buttonWrap {
	padding: spacing.generate(1);
}

.buttonWrap:not(:only-child) {
	border-top: units.px2rem(1px) solid colors.$gray-lighter;
}

.subNavClose {
	position: relative;
	width: 100%;
	padding: spacing.generate(3) spacing.generate(6);
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 2);
	line-height: map.get(typography.$line-height-values, 1);
	text-align: left;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	@include breakpoint.up(md) {
		display: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: spacing.generate(3);
		width: units.px2rem(7px);
		height: units.px2rem(7px);
		border-width: units.px2rem(1.5px) 0 0 units.px2rem(1.5px);
		border-style: solid;
		border-color: colors.$wine;
		transform: translateY(-50%) rotate(315deg);
		transform-origin: center;
	}
}

.disabled {
	opacity: 0.7;
}

.link {
	text-decoration: none;
	color: colors.$wine;
}

.linkWrap {
	display: flex;
	flex-direction: column;
}

.linkText {
	font-size: map.get(typography.$font-size-values, -1);
}
