@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/chart';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.chartWrap {
	@include chart.wrap();

	margin-inline: auto;
}

.chartOverlay {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: units.px2rem(150px);
	height: units.px2rem(150px);
	padding-top: spacing.generate(4);
	padding-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
	line-height: 1.05;
	border-radius: 50%;
	transform: translate(-50%, -50%);

	@include breakpoint.up(lg) {
		width: units.px2rem(215px);
		height: units.px2rem(215px);
		padding-top: spacing.generate(8);
	}
}

.chartOverlayNumber {
	margin-block: spacing.generate(1);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 5);
}

.chartOverlayPeriod {
	font-weight: bold;
}

.tooltip {
	@include transitions.generate('visibility, opacity');

	position: absolute;
	bottom: 100%;
	left: 50%;
	z-index: 10;
	width: units.px2rem(300px);
	padding: spacing.generate(2);
	color: colors.$white;
	border-radius: units.px2rem(10px);
	background: rgb(0 0 0 / 90%);
	visibility: hidden;
	opacity: 0;
	transform: translateX(-50%);
}

.chart {
	@include chart.generate();
}

.chart svg {
	overflow: visible;
}

.chart:hover ~ .tooltip,
.chartOverlay:hover ~ .tooltip {
	visibility: visible;
	opacity: 1;
}

.disclaimer {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, regular);
	font-size: map.get(typography.$font-size-values, 4);

	@include breakpoint.up(xl) {
		margin-inline: spacing.generate(1);
	}
}
