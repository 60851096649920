@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	text-align: center;

	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(6);
		text-align: left;
	}
}

.title {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 13);
	text-align: center;
	color: colors.$orange;

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 16);
	}
}

.wrap {
	display: flex;
	flex-direction: column;
}

.product {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	padding-block: spacing.generate(6);
	border-bottom: borders.$width solid colors.$gray-lighter;

	@include breakpoint.up(md) {
		flex-direction: row;
		gap: spacing.generate(6);
		justify-content: space-between;
		padding-block: spacing.generate(14);
	}
}

.product:nth-child(even) {
	@include breakpoint.up(md) {
		flex-direction: row-reverse;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: units.px2rem(530px);

	@include breakpoint.up(md) {
		align-items: flex-start;
	}
}

.subtitle {
	margin-bottom: spacing.generate(3);
	font-weight: map.get(typography.$font-weight-values, bold);
	font-size: map.get(typography.$font-size-values, 11);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 14);
	}
}

.text {
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 2);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 4);
	}
}

.text:not(:last-of-type) {
	@include breakpoint.up(md) {
		margin-bottom: spacing.generate(8);
	}
}

.buttonWrap {
	display: flex;
	flex-direction: column;
	gap: spacing.generate(4);
	align-items: center;

	@include breakpoint.up(md) {
		flex-direction: row;
	}
}

.illustration {
	width: 100%;
	max-width: units.px2rem(370px);
	height: units.px2rem(165px);
	padding-inline: spacing.generate(7);
	margin-block: spacing.generate(4) spacing.generate(8);
	border-radius: units.px2rem(20px);
	background-color: #{rgba(29, 137, 172, 0.1)};

	@include breakpoint.up(md) {
		max-width: units.px2rem(640px);
		height: units.px2rem(190px);
		margin-block: spacing.generate(8);
	}

	@include breakpoint.up(lg) {
		padding-inline: spacing.generate(10);
	}

	@include breakpoint.up(xl) {
		height: units.px2rem(220px);
	}
}

.illustration > svg {
	position: relative;
	top: units.px2rem(-20px);
	display: block;
	width: auto;
	height: calc(100% + #{units.px2rem(40px)});
	margin-inline: auto;

	@include breakpoint.up(md) {
		top: units.px2rem(-40px);
		height: calc(100% + #{units.px2rem(80px)});
	}
}

.product:nth-child(even) > .illustration {
	background-color: #{rgba(72, 185, 88, 0.1)};
}
