@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/borders';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/button';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.arrow {
	position: absolute;
	top: 50%;
	z-index: 1;
	display: block;
	font-size: 0;
	box-shadow: none;
	transform: translateY(-50%);
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		display: block;
		width: units.px2rem(13px);
		height: units.px2rem(13px);
		border-width: 0 map.get(borders.$width-values, large) map.get(borders.$width-values, large) 0;
		border-style: solid;
	}

	&:disabled {
		@include button.disabled();
	}
}

.next,
.prev {
	@include transitions.generate(box-shadow);

	width: units.px2rem(50px);
	height: units.px2rem(50px);
	border: borders.$width solid colors.$gray-lighter;
	border-radius: 50%;
	background: colors.$white;

	&::after {
		left: 50%;
		border-color: colors.$red;
	}

	@include breakpoint.up(sm) {
		left: 50%;
		transform: translateX(-50%);
	}
}

.prev {
	right: calc(100% + #{units.px2rem(40px)});

	&::after {
		transform: translate(calc(-50% + #{units.px2rem(2px)}), -50%) rotate(-225deg);
	}

	@include breakpoint.up(sm) {
		top: auto;
		right: auto;
		bottom: calc(100% + #{units.px2rem(30px)});
		transform: translateX(-50%);

		&::after {
			transform: translate(-50%, calc(-50% + #{units.px2rem(2px)})) rotate(-135deg);
		}
	}
}

.next {
	left: calc(100% + #{units.px2rem(40px)});

	&::after {
		transform: translate(calc(-50% - #{units.px2rem(2px)}), -50%) rotate(-45deg);
	}

	@include breakpoint.up(sm) {
		top: calc(100% + #{units.px2rem(100px)});
		left: 50%;
		transform: translateX(-50%);

		&::after {
			transform: translate(-50%, calc(-50% - #{units.px2rem(2px)})) rotate(45deg);
		}
	}
}

.nextHorizontal,
.prevHorizontal {
	width: units.px2rem(40px);
	height: units.px2rem(40px);
	border: 0;
	background-color: transparent;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: units.px2rem(77px);
	}

	&::after {
		border-color: colors.$wine;
	}
}

.prevHorizontal {
	left: 0;

	&::before {
		left: 0;
		background: linear-gradient(90deg, colors.$white 35%, #{rgba(colors.$white, 0)} 100%);
	}

	&::after {
		left: units.px2rem(12px);
		transform: translateY(-50%) rotate(-225deg);
	}
}

.nextHorizontal {
	right: 0;

	&::before {
		right: 0;
		background: linear-gradient(-90deg, colors.$white 35%, #{rgba(colors.$white, 0)} 100%);
	}

	&::after {
		right: units.px2rem(12px);
		transform: translateY(-50%) rotate(-45deg);
	}
}

.next:hover,
.next:focus,
.prev:hover,
.prev:focus {
	box-shadow: 0 0 units.px2rem(20px) 0 colors.$gray-light;
}

/* stylelint-disable selector-class-pattern */
.sliderWrap :global .slick-list.slick-list,
.sliderWrap :global .slick-slide.slick-slide {
	height: units.px2rem(150px);
}
/* stylelint-enable */
