@use '../../../scss/settings/colors';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: spacing.generate(1);
}

.input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: inherit;
}

.label {
	position: relative;
	z-index: 2;
	display: block;
	padding-top: units.px2rem(1px);
	padding-left: units.px2rem(18px) + units.px2rem(7px);
	font-weight: 600;
	line-height: units.px2rem(18px);
	color: colors.$wine;
	user-select: none;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: units.px2rem(18px);
		height: units.px2rem(18px);
		border: units.px2rem(1px) solid colors.$gray;
		border-radius: 50%;
		background-color: colors.$gray-lighter;
	}

	&::after {
		content: '';
		position: absolute;
		top: units.px2rem(4px);
		left: units.px2rem(4px);
		display: none;
		width: spacing.generate(2);
		height: spacing.generate(2);
		border-radius: 50%;
		background-color: colors.$orange;
	}
}

.labelHidden {
	padding-left: units.px2rem(18px);
	font-size: 0;
}

.input:checked + .label::after {
	display: block;
}

.input:disabled + .label {
	opacity: 0.65;
	cursor: not-allowed;
}
