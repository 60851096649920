@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: spacing.generate(6);

	@include breakpoint.up(lg) {
		flex-direction: row;
	}
}

.title {
	margin-bottom: spacing.generate(2);
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, 8);

	@include breakpoint.up(lg) {
		padding-right: spacing.generate(3);
		margin-bottom: 0;
	}
}

.select {
	width: 100%;
	max-width: units.px2rem(350px);

	@include breakpoint.up(lg) {
		max-width: units.px2rem(180px);
	}
}
