@use 'sass:color';
@use '../../../scss/settings/colors';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/scrollbar';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/units';

.title {
	margin-bottom: spacing.generate(6);
	font-weight: 500;
	font-size: units.px2rem(35px);
	line-height: 1.4;
	text-align: center;

	@include breakpoint.up(lg) {
		text-align: left;
	}
}

.button {
	margin-bottom: spacing.generate(4);
	text-align: center;

	@include breakpoint.up(lg) {
		margin-bottom: 0;
		text-align: right;
	}
}

.noResult {
	margin-top: spacing.generate(4);
	margin-bottom: spacing.generate(4);
	font-size: units.px2rem(18px);
	text-align: center;
}

.tableWrap {
	@include scrollbar.generate();

	width: 100%;
	min-height: 0.01%;
	padding-bottom: units.px2rem(28px);
	margin-bottom: spacing.generate(3);
	overflow: auto hidden;
	border: 0;

	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}

	@include breakpoint.up(md) {
		overflow: visible;
	}
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: spacing.generate(4);
}

.table th {
	padding: units.px2rem(8px);
	font-weight: 600;
	line-height: 1.4;
	text-align: left;
	vertical-align: bottom;
	border-bottom: units.px2rem(2px) solid colors.$wine;
}

.tableIcon {
	position: relative;
	top: units.px2rem(2px);
	display: none;
	margin-left: units.px2rem(6px);
	font-size: 0;

	@include breakpoint.up(sm) {
		display: inline;
	}
}

.table td {
	padding: spacing.generate(1) spacing.generate(2);
	font-weight: 500;
	font-size: units.px2rem(18px);
	line-height: units.px2rem(24px);
	text-align: left;
	vertical-align: top;
	border-top: units.px2rem(1px) solid colors.$gray-light;

	&:first-child {
		white-space: nowrap;
	}
}

.table .highlight,
.tableToggle {
	cursor: pointer;
}

.table .highlight td {
	font-weight: 700;
}

.hideMobile {
	display: none;

	@include breakpoint.up(sm) {
		display: table-cell;
	}
}

.hideDesktop {
	@include breakpoint.up(sm) {
		display: none;
	}
}

.right.right {
	text-align: right;
}

.collapseTrigger {
	display: inline-block;
	min-width: units.px2rem(80px);
	padding: 0;
	font-weight: bold;
	font-size: units.px2rem(15px);
	line-height: 1.4;
	font-family: inherit;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	vertical-align: middle;
	color: colors.$wine;
	border: 2px solid colors.$wine;
	border-radius: units.px2rem(34px);
	background: colors.$gray-extra-light;
	outline: 0;
	user-select: none;
	cursor: pointer;
	touch-action: manipulation;

	&:hover,
	&:focus {
		opacity: 0.9;
	}

	&:active {
		box-shadow: inset 0 units.px2rem(3px) units.px2rem(5px) color.adjust(colors.$black, $alpha: -0.87);
		transform: scale(0.95);
	}
}

.loadMore {
	padding: 0;
	font-weight: 500;
	font-size: 18px;
	font-family: inherit;
	text-decoration: underline;
	text-decoration-color: currentcolor;
	color: colors.$red;
	border: 0;
	background: transparent;
	cursor: pointer;

	&:hover,
	&:focus {
		color: colors.$red-dark;
	}
}

.plus {
	color: colors.$orange;
}

.minus {
	color: colors.$purple;
}
