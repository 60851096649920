@use 'sass:math';

@function _ranger-size($profile-count, $end-size, $size, $ends: 0) {
	@return calc(((100% - #{$end-size * 2}) * #{$size} / #{$profile-count - 1}) + #{$end-size * $ends});
}

@function _ranger-width($size) {
	@if $size == 1 {
		@return math.div(1, 3);
	}

	@return $size - 1;
}

@mixin ranger-bar($profile-count, $end-size) {
	left: 0;
	width: 100%;

	@for $size from 1 to $profile-count {
		&.rangerRecommendBar#{$size} {
			width: _ranger-size($profile-count, $end-size, _ranger-width($size), $ends: 2);
		}

		&.rangerRecommendBar#{$size}:not(.rangerRecommendBarOffset#{$profile-count - $size}) {
			border-top-right-radius: initial;
			border-bottom-right-radius: initial;
		}
	}

	@for $offset from 1 to $profile-count {
		&.rangerRecommendBarOffset#{$offset} {
			left: _ranger-size($profile-count, $end-size, $offset);
			border-top-left-radius: initial;
			border-bottom-left-radius: initial;
		}

		&.rangerRecommendBar1.rangerRecommendBarOffset#{$offset} {
			@if $offset == $profile-count - 1 {
				left: _ranger-size($profile-count, $end-size, $offset - _ranger-width(1));
			} @else {
				left: _ranger-size($profile-count, $end-size, $offset - (_ranger-width(1) * 0.5));
			}
		}
	}
}
