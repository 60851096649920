@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/units';

.root {
	width: 100%;
	height: units.px2rem(320px);

	@include breakpoint.up(md) {
		height: units.px2rem(562px);
	}
}
