@use 'sass:map';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/typography';
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/units';

.text {
	font-weight: map.get(typography.$font-weight-values, semibold);
	font-size: map.get(typography.$font-size-values, 0);
	line-height: map.get(typography.$line-height-values, 2);

	&:not(:last-child) {
		margin-bottom: spacing.generate(6);
	}

	@include breakpoint.up(lg) {
		font-weight: map.get(typography.$font-weight-values, medium);
	}
}

.textSpacy {
	line-height: map.get(typography.$line-height-values, 1);
}

.text > svg {
	position: relative;
	top: units.px2rem(1px);
	color: colors.$red;
	transform: scale(1);

	&:hover {
		animation: pulse 1s infinite;
	}
}

@keyframes pulse {
	10% {
		transform: scale(1.2);
	}
}
