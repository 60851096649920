@use 'sass:map';
@use '../tools/spacing';
@use '../tools/units';
@use 'colors';

$font-weight-values: (
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
);

$line-height-values: (
	-2: 0.8,
	-1: 1,
	0: 1.2,
	1: 1.4,
	2: 1.5,
);

$font-size-base: 87.5%;
$font-weight-base: map.get($font-weight-values, regular);
$font-family-primary: rajdhani, sans-serif;
$line-height-base: map.get($line-height-values, 0);

$font-size-values: (
	-3: units.px2rem(11px),
	-2: units.px2rem(12px),
	-1: units.px2rem(13px),
	0: units.px2rem(14px),
	1: units.px2rem(15px),
	2: units.px2rem(16px),
	3: units.px2rem(17px),
	4: units.px2rem(18px),
	5: units.px2rem(20px),
	6: units.px2rem(22px),
	7: units.px2rem(24px),
	8: units.px2rem(26px),
	9: units.px2rem(30px),
	10: units.px2rem(32px),
	11: units.px2rem(36px),
	12: units.px2rem(40px),
	13: units.px2rem(45px),
	14: units.px2rem(50px),
	15: units.px2rem(60px),
	16: units.px2rem(75px),
	17: units.px2rem(90px),
	18: units.px2rem(100px),
);

$styles: (
	body: (
		default: (
			color: colors.$wine,
			font-family: $font-family-primary,
			font-weight: map.get($font-weight-values, regular),
			font-size: map.get($font-size-values, 0),
			line-height: map.get($line-height-values, -1),
		),
	),
	paragraph: (
		default: (
			font-size: map.get($font-size-values, 4),
			line-height: map.get($line-height-values, 1),
		),
	),
	heading1: (
		default: (
			font-weight: map.get($font-weight-values, bold),
			font-size: map.get($font-size-values, 18),
			line-height: map.get($line-height-values, 1),
		),
	),
	heading2: (
		default: (
			font-weight: map.get($font-weight-values, regular),
			font-size: map.get($font-size-values, 12),
			line-height: map.get($line-height-values, -1),
		),
		breakpoints: (
			lg: (
				font-size: map.get($font-size-values, 18),
			)
		)
	),
	heading3: (
		default: (
			font-weight: map.get($font-weight-values, regular),
			font-size: map.get($font-size-values, 13),
			line-height: map.get($line-height-values, 1),
		),
	),
	heading4: (
		default: (
			font-weight: map.get($font-weight-values, medium),
			font-size: map.get($font-size-values, 8),
			line-height: map.get($line-height-values, 1),
		),
	),
	title: (
		default: (
			margin-bottom: spacing.generate(2),
			font-weight: map.get($font-weight-values, regular),
			font-size: map.get($font-size-values, 9),
			line-height: map.get($line-height-values, 0),
		),
		breakpoints: (
			sm: (
				font-size: map.get($font-size-values, 12),
			),
			lg: (
				font-size: map.get($font-size-values, 15),
			)
		)
	),
	description: (
		default: (
			font-weight: map.get($font-weight-values, regular),
			font-size: map.get($font-size-values, 4),
		),
		breakpoints: (
			sm: (
				font-size: map.get($font-size-values, 5),
			),
			lg: (
				font-size: map.get($font-size-values, 7),
			)
		)
	),
	subtitle: (
		default: (
			margin-top: spacing.generate(3),
			margin-bottom: spacing.generate(3),
			font-weight: map.get($font-weight-values, bold),
			font-size: map.get($font-size-values, 4),
			line-height: map.get($line-height-values, 0),
			text-transform: uppercase,
		),
		breakpoints: (
			sm: (
				font-size: map.get($font-size-values, 8),
			),
			lg: (
				margin-top: spacing.generate(6),
				margin-bottom: spacing.generate(6),
				font-size: map.get($font-size-values, 11),
			)
		)
	),
	text: (
		default: (
			font-size: map.get($font-size-values, 2),
		),
		breakpoints: (
			sm: (
				font-size: map.get($font-size-values, 4),
			),
			lg: (
				font-size: map.get($font-size-values, 5),
			)
		)
	),
);
