@use 'sass:map';
@use '../../../scss/settings/borders';
@use '../../../scss/settings/colors';
@use '../../../scss/settings/header';
@use '../../../scss/settings/typography' as typography-settings;
@use '../../../scss/tools/breakpoint';
@use '../../../scss/tools/spacing';
@use '../../../scss/tools/transitions';
@use '../../../scss/tools/typography';
@use '../../../scss/tools/units';

.root {
	position: fixed;
	bottom: spacing.generate(3);
	left: spacing.generate(3);
	z-index: 2147483647;
	display: flex;
	flex-direction: column;
	width: units.px2rem(540px);
	max-width: calc(100% - #{spacing.generate(6)});
	max-height: calc(100vh - #{header.$height-mobile} - #{spacing.generate(3)});
	overflow: hidden;
	border: borders.$width solid colors.$blue-dark;
	border-radius: units.px2rem(6px);
	background-color: colors.$white;
	box-shadow: 0 units.px2rem(10px) units.px2rem(50px) rgb(21 27 34 / 25%);

	@include breakpoint.up(md) {
		max-height: calc(100vh - #{header.$height} - #{spacing.generate(3)});
	}
}

.header {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: spacing.generate(4);
	border: borders.$width solid colors.$white;
	border-radius: units.px2rem(5px) units.px2rem(5px) 0 0;
	background-color: colors.$blue-dark;
}

.headerInvesting {
	min-height: units.px2rem(65px);
}

.back {
	position: relative;
	display: block;
	width: units.px2rem(10px);
	height: units.px2rem(30px);
	padding: 0;
	margin-right: spacing.generate(2);
	font-size: 0;
	border: 0;
	background: transparent;
	box-shadow: none;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: units.px2rem(13px);
		height: units.px2rem(13px);
		border-width: 0 map.get(borders.$width-values, large) map.get(borders.$width-values, large) 0;
		border-style: solid;
		border-color: colors.$white;
		transform: translate(-50%, -50%) rotate(135deg);
	}
}

.logo {
	color: colors.$white;
}

.logo > svg {
	width: units.px2rem(97px);
	height: units.px2rem(25px);
}

.close {
	position: absolute;
	top: spacing.generate(5);
	right: spacing.generate(5);
	height: units.px2rem(20px);
	padding: 0;
	font-weight: 500;
	font-size: 3em;
	line-height: units.px2rem(20px);
	font-family: inherit;
	color: colors.$white;
	border: 0;
	background: 0 0;
	cursor: pointer;
}

.body {
	padding: spacing.generate(4);
	overflow: auto;
	overscroll-behavior-y: contain;
}

.text {
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 0);
	line-height: map.get(typography-settings.$line-height-values, 1);

	&:not(:last-child) {
		margin-bottom: spacing.generate(3);
	}
}

.categoryText {
	padding-left: spacing.generate(6);
	margin-top: spacing.generate(1);
	margin-bottom: spacing.generate(4);
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, -2);
	line-height: map.get(typography-settings.$line-height-values, 1);
}

.footer {
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: spacing.generate(2) spacing.generate(3);
	background-color: colors.$gray-lighter;
}

.control {
	padding: spacing.generate(2) spacing.generate(1);
}

.controlLink {
	flex-basis: 100%;
	padding: spacing.generate(2) spacing.generate(1);
	margin-right: auto;
	font-weight: map.get(typography-settings.$font-weight-values, semibold);
	font-size: map.get(typography-settings.$font-size-values, 2);
	text-align: left;
	text-decoration: underline;
	white-space: nowrap;
	color: colors.$red;

	&:hover,
	&:focus {
		text-decoration: none;
		color: colors.$wine-light;
	}

	@include breakpoint.up(sm) {
		flex-basis: auto;
	}
}

.link {
	@include typography.link();

	padding: 0;
	font-weight: map.get(typography-settings.$font-weight-values, medium);
	font-size: map.get(typography-settings.$font-size-values, 0);
	line-height: map.get(typography-settings.$line-height-values, 1);
	border: 0;
	background: transparent;
	outline: 0;
	cursor: pointer;
}

.hide {
	display: none;
}
